import { Row, Col, Divider } from "antd";
import styles from "./view.module.scss";
import AppButton from "components/shared/AppButton/AppButton";
import { primaryColor } from "colors-config";
import ConsentForm from "../ConsentForm/ConsentForm";


const ProfileView = ({ setEditAddress, clinicManagement }) => {

  return (
    <div className={styles["management-view-container"]}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles["heading"]}>Clinic Management</h2>
        </Col>
        <Col>
          <AppButton
            type="primary"
            shape="round"
            style={{ padding: '0 40px', height: '35px' }}
            onClick={() => setEditAddress(true)}
          >
            Edit
          </AppButton>
        </Col>
      </Row>
      <Row>
        <Col lg={8}>
          <label className={styles["form-info-label"]}>Clinic</label>
        </Col>
        <Col lg={8}>
          <label className={styles["form-info-label"]}>Address</label>
        </Col>
      </Row>
      {clinicManagement?.map((obj, i) => {
        return (
          <div key={i}>
            <Row>
              <Col lg={8} md={12} sm={12} >
                <p className={styles["form-info-value"]}>{obj.label || "--"}</p>
              </Col>
              <Col lg={8} md={12} sm={12} >
                <p className={styles["form-info-value"]} style={{ display: "flex", gap: "2px", flexWrap: "wrap" }}>
                  <span>  {obj.zipCode || " "}</span>
                  <span> {obj.address || " "}</span>
                  <span>{obj.city || " "}</span>
                  <span>{obj.country || " "}</span>
                </p>
              </Col>
              <Col lg={8}>
                {obj.default ? (
                  <p
                    className={styles["form-info-tag"]}
                    style={{ background: primaryColor }}
                  >
                    Primary Clinic
                  </p>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Divider />
          </div>
        );
      })}
      <ConsentForm
      mode={"view"}
      />

    </div>
  );
};

export default ProfileView;
