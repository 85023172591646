import { useState, useEffect } from "react";
import { Form, Modal, Button, Typography, Spin, message } from "antd";
import AppointmentForm from "../AppointmentForm/AppointmentForm";
import { getAllPaymentMethods } from "components/shared/modals/PaymentSettings/services/payment.api";
import { useDispatch, useSelector } from "react-redux";
import { clinicIdSelector,  getClinicAppointmentTypesSelector, getClinicMeetingDurationListSelector } from "services/redux/selectors/clinicSelectors";
import { mapAppointmentData, mapAppointmentInitialData } from "utilities/settings";
import { v4 as uuidv4 } from "uuid";
import { PaymentTypes } from "components/shared/modals/PaymentSettings/constants";
import { clinicPayments } from "services/redux/selectors/clinicSelectors";
import { primaryColor } from "colors-config";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;

const { Title } = Typography;

const ProductModal = ({
  showAppointmentModal,
  setShowAppointmentModal,
  editAppointment,
  setAppointmentEditObj,
  clinicCurrenciesList,
  viewAppointmentsList,
  setViewAppointmentsList
}) => {
  const [paymentList, setPaymentList] = useState([]);
  const clinicAppointmentTypesList = useSelector(getClinicAppointmentTypesSelector);
  const clinicAppointmentDurationsList = useSelector(getClinicMeetingDurationListSelector);
  const clinicId = useSelector(clinicIdSelector);
  const [addAppointmentForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [appointmentNameList, setAppointmentNameList] = useState([]);
  const payments = useSelector(clinicPayments);
  const dispatch = useDispatch()

  const fetchPayments = async () => {
    const resPayments = await getAllPaymentMethods(clinicId);
    setPaymentList(
      resPayments.data.data.paymentTypes.filter((el) => el.isActive)
    );
  };

  useEffect(() => {
    fetchPayments();
    let formData;
    if (editAppointment && clinicAppointmentDurationsList) {
       formData = mapAppointmentData(editAppointment);
      addAppointmentForm.setFieldsValue(formData);
      const selectedTypeObj = clinicAppointmentTypesList.find(
        (type) => type.clinic_appointment_type_id === formData.appointmentTypeId
      );
      if (selectedTypeObj) {
        setAppointmentNameList(selectedTypeObj.appointmentNamesList);
      }
    } else {
      const activePayments = payments.filter((payment) => payment.default === 1)
       const formData = mapAppointmentInitialData(activePayments);
       addAppointmentForm.setFieldsValue(formData);
    }
  }, [editAppointment, addAppointmentForm,clinicAppointmentTypesList,clinicAppointmentDurationsList  ]);


  const handleSubmit = () => {
    addAppointmentForm.submit();
  };

  const handleAddResourceCancel = () => {
    if (addAppointmentForm.isFieldsTouched()) {
      showConfirm();
    } else {
      setShowAppointmentModal(false);
    }
  };



  const getDurationText = (durationId) => {
  const duration = clinicAppointmentDurationsList.find((obj) => obj.clinic_meeting_duration_id == durationId)
  if (duration) {
    return duration.clinic_meeting_duration
  }
  };



  
  const onProductSubmit = async (values) => {
    if (!values.free) {
      if (!values.price || values.price <= 0) {
        return message.error("Price should be greater than zero or mark free instead.");
      }
    }
    const { appointmentType, appointment, free, price, currency, duration, paymentIds, description } = values;
  
    const selectedAppointmentType = clinicAppointmentTypesList.find(
      (type) => type.clinic_appointment_type_id === appointmentType
    );
  
    const selectedAppointmentName = selectedAppointmentType?.appointmentNamesList.find(
      (name) => name.clinic_appointment_name === appointment
    );
  
    const appointmentObj = {
      appointment_type: selectedAppointmentType?.clinic_appointment_type_name || "", 
      appointment_name: appointment, 
      slot_duration: getDurationText(duration),
      description: description || "", 
      price: price || 0, 
      appointment_type_id: appointmentType || null,
      appointment_name_id: selectedAppointmentName?.clinic_appointment_type_name_id || -1, 
      slot_duration_id: Number(duration) || null,
      slot_currency_id: Number(currency) || null, 
      paymentIds: JSON.stringify(paymentIds) || [],
      free: free || false, 
      appointment_id: values.appointment_id ? values.appointment_id : uuidv4()
    };
    const appointments = [...viewAppointmentsList]
    let finalData;
    if (editAppointment) {
      finalData = appointments.map(((appointment) =>
        appointment.appointment_id === editAppointment.appointment_id
          ? { ...appointmentObj }
          : appointment
      ))
    } else {
      finalData = [...appointments, appointmentObj]
    }

     setViewAppointmentsList(finalData)
      // setAppointmentEditObj(appointmentObj)
     setShowAppointmentModal(false);  
 
  };


  const showConfirm = () => {
    confirm({
      title: "Close appointment form",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          borderRadius: "30px",
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
          borderRadius: "30px",
        },
      },
      onOk() {
        setShowAppointmentModal(false);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  

  return (
    <div>
      <Modal
        className="addResourceModal"
        title={
          <div className="titleSpan">
            <Title level={5} style={{ margin: "0px" }}>
              {editAppointment
                ? "Edit Appointment Type"
                : "Add Appointment Type"}
            </Title>
          </div>
        }
        width={850}
        open={showAppointmentModal}
        onCancel={handleAddResourceCancel}
        footer={[
          <Button
            key="cancel"
            onClick={handleAddResourceCancel}
            shape="round"
            style={{ width: "7rem" }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            shape="round"
            onClick={handleSubmit}
            loading={loading}
            style={{ width: "7rem" }}
          >
            {editAppointment ? "Update" : "Save"}
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <AppointmentForm
            onProductSubmit={onProductSubmit}
            addAppointmentForm={addAppointmentForm}
            paymentList={paymentList}
            clinicCurrenciesList={clinicCurrenciesList}
            clinicAppointmentTypesList={clinicAppointmentTypesList}
            clinicAppointmentDurationsList={clinicAppointmentDurationsList}
            appointmentNameList={appointmentNameList}
            setAppointmentNameList={setAppointmentNameList}
            
          />
        </Spin>
      </Modal>
    </div>
  );
};

export default ProductModal;
