import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Input, Modal, notification, Row, Col, Select, Spin, DatePicker, Divider } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import {
  getCountryCodes,
} from "../../../services/redux/selectors/clinicSelectors";
import { companyType, prefix, country, primaryColor } from "colors-config";
import { FORM_LABELS } from "helper/constants/globalConstant";
import { getClinicLabels } from "api/clinic";
import { loadPatientList, updateClinicFirstTimeStatusUpdate } from "services/redux/actions/clinicActions";
import { countriesSelector, genderListSelector } from "services/redux/selectors/commonSelectors";
import { createPatientAPI, editPatientAPI } from "api/patient";
import mixpanel from 'mixpanel-browser';
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;

const AddOrEditPatient = ({ title, content, onConfirmation, IsEdit, showModal, setShow }) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadValue, setLoadValue] = useState({
    country: companyType === 5 || companyType === 6 ? "United Kingdom" : "",
  });

  const [isSpin, setIsSpin] = useState(false);
  const [clinicLabels, setClinicLabels] = useState([])
  const [confimLoader, setConfirmLoader] = useState(false);
  const [form] = Form.useForm();
  const clinic = useSelector((state) => state.clinic);

  const countryCodes = useSelector(getCountryCodes);
  const patientCountriesList = useSelector(countriesSelector);
  const genderList = useSelector(genderListSelector);

  const [genderValue, setGenderValue] = useState("");

 
 


  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  useEffect(() => {
    form.resetFields();
  }, [countryCodes]);

  useEffect(() => {
    const fetchClinicLabels = async () => {
      try {
        const data = await getClinicLabels(clinic?.clinicId);
        const clinics = data?.data?.body?.response
        setClinicLabels(clinics);
        if (clinics?.length === 1) {
          form.setFieldValue("clinicName", clinics[0].id)
        }
      } catch (error) {
        console.error("Error fetching clinic labels:", error);
      }
    };
  
    if (clinic?.clinicId) {
      fetchClinicLabels();
    }
  }, [clinic]);
  


  useEffect(() => {
    if (patientCountriesList?.length) {
      form.setFieldValue("prefix", prefix);
      form.setFieldValue("country", country);
    }
  }, [patientCountriesList]);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        dropdownClassName="country-dropdown"
        className="country-select"
        size="large"
        dropdownStyle={{ width: "300px" }}
        style={{ width: 110 }}
        defaultValue={prefix}
        labelRender={(props) => {
          return props.label;
        }}
        options={patientCountriesList?.map((code, index) => {
          return {
            ...code,
            key: index,
            value: code.countryCallingCodes,
            title: `${code.emoji} ${code.countryCallingCodes}`,
            label: code.countryCallingCodes,
          };
        })}
        optionRender={(option) => {
          const { data } = option;
          return (
            <div style={{ display: "grid", gridTemplateColumns: "1fr 2fr 10fr" }}>
              <span className="emoji-font">{data.emoji}</span>
              <span>{data.countryCallingCodes}</span>
              <span>| {data.name}</span>
            </div>
          );
        }}
        showSearch
        filterOption={(input, option) => {
          const lowerCasedInput = input.toLowerCase();
          return (
            option.value.toLowerCase().includes(lowerCasedInput) ||
            option.name.toLowerCase().includes(lowerCasedInput)
          );
        }}
      />
    </Form.Item>
  );

  useEffect(() => {
    if (IsEdit) {
      setLoadValue({
        firstName: content?.patient_first_name,
        Email: content?.patient_email,
        lastName: content?.patient_last_name,
        phone: content?.patient_phone_number,
        prefix: content?.patient_country_code_name,
        Address: content?.patient_address,
        city: content?.patient_city,
        county: content?.patient_county,
        Zipcode: content?.patient_postal_code,
        country: content?.patient_country,
        dob: content?.patient_date_of_birth ? dayjs(content?.patient_date_of_birth) : null,
        clinicName: content?.addressId,
        gender: content?.gender,
        genderSelfDescribe: content?.genderSelfDescribe,
      });
      setGenderValue(content?.gender)
    }

    return () => form.resetFields();
  }, [content, IsEdit]);


  function checkUndefined(val) {
    return val ? val : "";
  }
  const handleOk = async () => {
    setConfirmLoader(true);
    form
      .validateFields()
      .then(async (value) => {
        const payload = {
          patientId: content?.patient_id,
          firstName: value?.firstName,
          lastName: value?.lastName,
          email: value?.Email,
          isUnderAge: false,
          phoneNumber: value?.phone,
          clinicId: clinic?.clinicId,
          countryCode:value?.phone?  value.prefix:null,
          Address: checkUndefined(value?.Address),
          county: checkUndefined(value?.county),
          Zipcode: checkUndefined(value?.Zipcode),
          city: checkUndefined(value?.city),
          country: checkUndefined(value?.country),
          dob: value?.dob ? dayjs(value.dob).format("YYYY-MM-DD") : null,
          addressId: value.clinicName,
          gender:checkUndefined(value?.gender),
          genderSelfDescribe:checkUndefined(value?.genderSelfDescribe)
        };
        if (clinic?.companyType) {
          payload.companyType = clinic.companyType;
        }
        if (IsEdit) {
          const res = await editPatientAPI(payload);

          if (res?.data?.body?.msg) {
            notification.success({ message: res?.data?.body?.msg });
            // form.resetFields();
            setIsModalVisible(false);
            setShow(false);

            onConfirmation();
          }
          else{
            notification.error({ message: res?.data?.err?.msg || "Some error occured" });
          }
        } else {
          try {
            delete payload.patientId;
            const res = await createPatientAPI(payload);
            if (res?.data?.err?.msg === "The user email already exists.") {
              notification.error({
                message: "The user email already exists.",
              });
              return false;
            }
            if (res?.data?.body?.msg) {
              if (location.pathname === "/clinic/patients") {
                dispatch(loadPatientList(true))
              }
              notification.success({
                message: res?.data?.body?.msg,
                description: <a>View Profile</a>,
                className: "patient-created",
                onClick: () => {
                  history.push("/clinic/patient/" + res?.data?.body?.patientId);
                  window.location.reload();
                },
              });
              mixpanel.people.set({
                created_first_patient: true
              });
              //redirect to prescription tab
              if (res?.data?.body?.patientId) {
                history.push("/clinic/patient/" + res?.data?.body?.patientId+"/prescription?patientCreation=true");
                window.location.reload();
              }
             
              handleCancel();
              if(clinic?.firstTimeOnboardStep ==="ADD_PATIENT"){
                history.push("/clinic/patient/" + res?.data?.body?.patientId+"/prescription");
                setTimeout(() => {
                  window.Intercom("startTour", process.env.REACT_APP_INTERCOM_TOUR_ADD_PRESCRIPTION);
                }, 3000);
                dispatch(updateClinicFirstTimeStatusUpdate("ADD_PRESCRIPTION"));
                return;
                // window.location.reload();
              }
            }


            
          } catch (err) {
            const errorMessage = err?.response?.data?.err?.msg ? err?.response?.data?.err?.msg : err.message;
            notification.error({
              message: errorMessage,
            });
            return false;
          } finally {
            setConfirmLoader(false);
          }
        }
        setConfirmLoader(false);
      })
      .catch((error) => {
        setConfirmLoader(false);
        console.error(error);
      });
  };

  const handleCancel = () => {
    if (form.isFieldsTouched()) {
      showConfirm();
    } else {
      setIsModalVisible(false);
      setShow(false);
    }
  };
  const disabledDate = (current) => {
    // Disable dates from today and onwards
    return current && current >= dayjs().endOf("day");
  };


  const onGenderChange = (value)=>{
    setGenderValue(value);
  }


  const showConfirm = () => {
    confirm({
      title: "Close patient form",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          borderRadius: "30px",
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
          borderRadius: "30px",
        },
      },
      onOk() {
        setIsModalVisible(false);
        setShow(false);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  return (
    <>
      <Modal
        title={title}
        width={700}
        visible={isModalVisible}
        onOk={handleOk}
        confirmLoading={confimLoader}
        onCancel={handleCancel}
        okButtonProps={{
          style: { width: "7rem", borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
      >
        {isSpin ? (
          <Spin spinning={isSpin} />
        ) : (
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={loadValue}
          >
            <Row gutter={[32, 8]}>
              <Col lg={12} md={24} sm={24}>
                <Form.Item
                  name="clinicName"
                  rules={[
                    {
                      required: true,
                      message: "Please select a clinic to proceed",
                    },
                  ]}
                  label="Clinic Name"
                >
                  <Select
                    showSearch
                    size="large"
                    placeholder="Enter Clinic Name"
                    options={clinicLabels.map((clinic, index) => {
                      return {
                        key: index,
                        value: clinic.id,
                        label: clinic.label,
                      };
                    })}
                    filterOption={(input, option) => {
                      const lowerCasedInput = input.toLowerCase();
                      return option.label
                        .toLowerCase()
                        .includes(lowerCasedInput);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ marginTop: 0 }} />
            <Row gutter={[32, 8]}>
              <Col lg={12} md={24} sm={24}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "First Name is required",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter first name" />
                </Form.Item>
              </Col>
              <Col lg={12} md={24} sm={24}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Last Name is required",
                    },
                  ]}
                >
                  <Input size="large" placeholder="Enter last name" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 8]}>
              <Col lg={12} md={24} sm={24}>
                <Form.Item
                  name="Email"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      message: "Invalid email address",
                    },
                    // {
                    //   required: true,
                    //   message: "Please input your E-mail!",
                    // },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Email address"
                    disabled={IsEdit}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} md={24} sm={24}>
                <Form.Item
                  name="phone"
                  label="Phone Number"
                  rules={[
                    () => ({
                      validator(_, value) {
                        const convertedValue = Number(value);
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (!/^\d+$/.test(value)) {
                          return Promise.reject(
                            "Phone number must contain only numeric characters."
                          );
                        }
                        if (!isNaN(convertedValue)) {
                          if (value.length < 6) {
                            return Promise.reject(
                              "Invalid phone number. Please enter at least 6 digits."
                            );
                          }
                          if (value.length > 15) {
                            return Promise.reject(
                              "Please enter a valid phone number with no more than 15 digits."
                            );
                          }
                          return Promise.resolve();
                        }

                        return Promise.reject("Input must be a valid number.");
                      },
                    }),
                  ]}
                >
                  <Input
                    size="large"
                    addonBefore={prefixSelector}
                    style={{
                      width: "100%",
                    }}
                    placeholder="Phone Number"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 8]} className="divider">
              <Col lg={12} md={24} sm={24}>
              <Form.Item
                    className="labelClass"
                    name="dob"
                    label="Date of Birth"
                  >
                    <DatePicker
                      className="dob-input"
                      disabledDate={disabledDate}
                      style={{width:"100%"}}
                    />
                  </Form.Item>
              </Col>
              <Col lg={12} md={24} sm={24}>
              <Form.Item
                    className="labelClass"
                    name="gender"
                    label="Gender"
                  >
                    <Select
                      showSearch
                      size="large"
                      placeholder="Select Gender"
                      onChange={onGenderChange}
                      options={genderList.map((gender, index) => {
                        return {
                          key: index,
                          value: gender.value,
                          label: gender.label,
                        };
                      })}
                      filterOption={(input, option) => {
                        const lowerCasedInput = input.toLowerCase();
                        return option.label
                          .toLowerCase()
                          .includes(lowerCasedInput);
                      }}
                    />
                  </Form.Item>
              </Col>
            </Row>
            <Row gutter={[32, 8]}>
              <Col lg={24} md={24} sm={24}>
              <Form.Item name="Address" label="Address">
                    <Input size="large" />
                  </Form.Item>
              </Col>
  
            </Row>
            <Row gutter={[32, 8]}>
              <Col lg={12} md={24} sm={24}>
              <Form.Item className="labelClass" name="city" label="City">
                    <Input size="large"  />
                  </Form.Item>
              </Col>
              <Col lg={12} md={24} sm={24}>
              <Form.Item
                    className="labelClass"
                    name="county"
                    label="County/Province/State"
                  >
                    <Input size="large"  />
                  </Form.Item>
              </Col>
            </Row>

            <Row gutter={[32, 8]}>
              <Col lg={12} md={24} sm={24}>
              <Form.Item
                    className="labelClass"
                    name="country"
                    label="Country"
                  >
                    <Select
                      showSearch
                      size="large"
                      placeholder="Enter Country"
                      defaultValue={country}
                      options={patientCountriesList.map((country, i) => {
                        return {
                          ...country,
                          value: country.name,
                          title: country.name,
                          label: (
                            <>
                              <span className="emoji-font">
                                {country.emoji}
                              </span>
                              {` ${country.name}`}
                            </>
                          ),
                          key: i,
                        };
                      })}
                      labelRender={props => {
                        return props.title;
                      }}
                      filterOption={(input, option) => {
                        const lowerCasedInput = input.toLowerCase();
                        return option.name
                          .toLowerCase()
                          .includes(lowerCasedInput);
                      }}
                    />
                  </Form.Item>
              </Col>
              <Col lg={12} md={24} sm={24}>
              <Form.Item
                    className="labelClass"
                    name="Zipcode"
                    label={FORM_LABELS.POST_CODE}
                  >
                    <Input size="large"  />
                  </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {genderValue === "PREFER_SELF_DESCRIBE" ? (
                  <div>
                    <Form.Item
                      name="genderSelfDescribe"
                      label="Prefer to Self Describe"
                      rules={[
                        {
                          required: true,
                          message: "Please provide a self-description",
                        },
                        {
                          max: 50,
                          message: "Description must be 50 characters or fewer",
                        },
                        {
                          pattern: /^[^0-9]*$/,
                          message: "Description cannot contain numbers",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </div>
                ) : null}

              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default AddOrEditPatient;
