import { useState, useEffect, useRef } from "react";
import { Row, Col, Divider, Button, Spin, message } from "antd";
import classNames from "classnames";
// import ScanUpload from "./components/ScanUpload/ScanUpload";
import CustomScanUpload from "./components/ScanUpload/CustomScanUpload";
import ScanModel from "./components/ScanModel/ScanModel";
import ScanHistory from "./components/ScanHistory/ScanHistory";
import styles from "./scans.module.scss";
import { primaryColor } from "colors-config";
import FolderIcon from "components/shared/Icon/FolderIcon";
import { ReactComponent as AddIcon } from "assets/images/teeths/addIcon.svg";
import { getPrescriptionScanFiles } from "api/prescription";
import { deletePrescriptionScanFile } from "api/prescription";
import { addConfirmScanFilesAPI } from "api/scans";
import dayjs from 'dayjs';
const ClinicScans = ({ patientId, clinicId, scansRef }) => {
  const [fileLoader, setFileLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scansInfo, setScansInfo] = useState({
    uploadedBy: "",
    uploadedDate: "",
    scanType: "",
  });
  //   const scanTypes = ["Upper Arch", "Lower Arch", "Bitscan", "Bitscan2"];
  const treatmentType = "Prescription";
  //   const formatKey = scan => scan.replace(/\s+/g, "").toLowerCase();

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const initializeUploadedFiles = data => {
    // console.log("data11111", data);
    // Define the initial structure of uploadedFiles
    const initialFiles = {
      UpperArch: null,
      LowerArch: null,
      BiteScan: null,
      BiteScan2: null,
    };

    // Iterate through data and populate the respective keys in initialFiles
    data?.forEach(file => {
      //   const formattedKey = formatKey(file.patient_image_view);
      //   console.log("formattedKey", formattedKey);
      if (initialFiles.hasOwnProperty(file.patient_image_view)) {
        initialFiles[file.patient_image_view] = file;
      }
    });

    return initialFiles;
  };
  const initializeScansInfo = data => {
    if (data.length === 0)
      return { uploadedBy: "", uploadedDate: "", scanType: "" };

    const latestUpload = data[data.length - 1]; // Access the last item in the array

    let uploadDate = latestUpload?.upload_date.split("T")[0] || "";
    uploadDate = dayjs(uploadDate).format("DD-MMM-YYYY")
    
    return {
      uploadedBy: latestUpload?.createdFrom || "",
      uploadedDate: uploadDate,
      scanType: latestUpload?.model || "",
    };
  };

  useEffect(() => {
    const fetchPrescriptionData = async () => {
      if (patientId) {
        try {
          setLoading(true);
        //   console.log("herererer");
          const { data } = await getPrescriptionScanFiles(patientId);
        //   console.log("datatat", data);
          const finalRes = initializeUploadedFiles(data.data);
          const scanInfo = initializeScansInfo(data.data);
        //   console.log("finalResfinalRes", finalRes);
        //   console.log("scanInfoscanInfoscanInfo", scanInfo);
          setUploadedFiles(finalRes);
          setScansInfo(scanInfo);
          scansRef.current = finalRes
        } catch (error) {
          console.error("Error fetching prescription data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPrescriptionData();
  }, [patientId]);

  const handleFileUpload = (file, type) => {
    // console.log("file.patient_image_view)", file.patient_image_view);
    // const formattedKey = formatKey(type);
    // setUploadedFiles({
    //   ...uploadedFiles,
    //   [file.patient_image_view]: { file, treatment: treatmentType },
    // });
  };

  const handleFileRemove = type => {
    const formattedKey = type;
    setUploadedFiles({ ...uploadedFiles, [formattedKey]: null });
  };

  const handleFileSubmission = files => {
    // console.log("Uploaded files:", files);
    // You can process or store the files as needed here
  };

  const deleteScanFile = (file, existingFiles) => {
    // Start with a copy of the existing files
    const updatedFiles = { ...existingFiles };

    // Get the key from the file's `patient_image_view`
    const keyToDelete = file.patient_image_view;

    // If the key exists in the existing files, set it to null to indicate deletion
    if (updatedFiles.hasOwnProperty(keyToDelete)) {
      updatedFiles[keyToDelete] = null;
    }

    return updatedFiles;
  };

  const handleRemove = async scan => {
    try {
      setLoading(true);
      const payload = {
        patientId: patientId,
        imageView: scan.patient_image_view,
      };
      await deletePrescriptionScanFile(payload, clinicId);
      const updatedFiles = deleteScanFile(scan, uploadedFiles);
      setUploadedFiles(updatedFiles);
      scansRef.current = updatedFiles

      message.success("Scan deleted successfully.");
    } catch (error) {
      console.error("Error deleting data:", error);
    } finally {
      setLoading(false);
    }
  };


  const lockRef = useRef(false); // Lock to prevent simultaneous updates

  const uploadConfirm = async (addNewData) => {
    if (lockRef.current) {
      // If another instance is running, wait until the lock is released
      while (lockRef.current) {
        await new Promise((resolve) => setTimeout(resolve, 50)); // Check every 50ms
      }
    }
  
    lockRef.current = true; // Acquire lock
    try {
      const { data } = await addConfirmScanFilesAPI(clinicId, addNewData);
  
      // Use functional update to ensure state integrity
      setUploadedFiles((prevUploadedFiles) => {
        const finalRes = updateUploadedFiles(data, prevUploadedFiles);
        if (scansRef) {
          scansRef.current = finalRes;
        }
        return finalRes; // Return updated state
      });
  
      setScansInfo({
        uploadedBy: "clinic",
        uploadedDate: dayjs().format('DD-MMM-YYYY'),
        scanType: "prescription",
      });
    } finally {
      lockRef.current = false; // Release lock
    }
  };
  
 

  // const uploadConfirm = async (addNewData) => {
  //   const { data } = await addConfirmScanFilesAPI(clinicId, addNewData);
  //   const finalRes = updateUploadedFiles(data, uploadedFiles);
  //   setUploadedFiles(finalRes);
  //   if (scansRef) {
  //     scansRef.current = finalRes
  //   }
  //   const scanInfo = {
  //     uploadedBy: "clinic",
  //     uploadedDate: dayjs().format('YYYY-MM-DD'),
  //     scanType: "prescription",
  //   }
  //   setScansInfo(scanInfo);
  // }
  const updateUploadedFiles = (newFile, existingFiles) => {
    const updatedFiles = { ...existingFiles };
    const formattedKey = newFile.patient_image_view;
    if (updatedFiles.hasOwnProperty(formattedKey)) {
      updatedFiles[formattedKey] = {
        ...newFile,
        uploadedFrom: newFile.createdFrom,
        uploadDate: newFile.createdAt,
      };
    }

    return updatedFiles;
  };

  return (
    <div className={classNames(styles["scans-col-container"])}>
      <Spin spinning={loading}>
        <div className={classNames(styles["scans-col-header"])}>
          <div className={classNames(styles["scans-col-headings"])}>
            <h1>
              Scan Files
              {scansInfo.scanType && (
                <span
                  style={{ color: primaryColor }}
                >{` (${scansInfo.scanType})`}</span>
              )}
            </h1>
            <h2>
              {scansInfo.uploadedDate && (
                <span>{` Updated: (${scansInfo.uploadedDate})`}</span>
              )}
            </h2>
          </div>
          <Button
            icon={<AddIcon style={{ fill: primaryColor }} />}
            shape="round"
            onClick={() => setIsModalOpen(true)}
            className={classNames(styles["new-btn"])}
          >
            New Scans
          </Button>
        </div>

        <Divider style={{ margin: "10px 0px" }} />
        <div style={{ padding: "0px 20px" }}>
            <Row gutter={16}>
              {Object.entries(uploadedFiles).map(([scanKey, fileData]) => (
                <Col key={scanKey} lg={6} sm={12}  md={12}>
                  <CustomScanUpload
                    scanType={scanKey} // scanKey would be "upperarch", "lowerarch", etc.
                    scanLabel={scanKey}
                    treatmentName={treatmentType}
                    uploadedFile={fileData} // fileData is the data for that specific scan type or null
                    onFileUpload={handleFileUpload}
                    // onFileRemove={handleFileRemove}
                    prescriptionUpload={true}
                    setFileLoader={setFileLoader}
                    clinicId={clinicId}
                    patientId={patientId}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    onFileRemove={handleRemove}
                    scansRef={scansRef}
                    setScansInfo={setScansInfo}
                    uploadConfirm={uploadConfirm}
                  />
                </Col>
              ))}
            </Row>
       
        </div>
        <Divider style={{ margin: "20px 0px 10px 0px" }} />
        <div className={classNames(styles["history-btn"])}>
          <span onClick={() => setShowHistoryModal(true)}>
            <FolderIcon /> See History
          </span>
        </div>
        {isModalOpen && (
          <ScanModel
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onSubmit={handleFileSubmission}
            patientId={patientId}
            clinicId={clinicId}
            setFiles={setUploadedFiles}
            scansRef={scansRef}
            setScansInfo={setScansInfo}
          />
        )}

        {showHistoryModal && (
          <ScanHistory
            patientId={patientId}
            showHistoryModal={showHistoryModal}
            setShowHistoryModal={setShowHistoryModal}
            clinicId={clinicId}
          />
        )}
      </Spin>
    </div>
  );
};

export default ClinicScans;
