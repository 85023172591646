import { useState } from "react";
import { Row, Col, Modal, Spin, Button, Alert, message } from "antd";
import ScanUpload from "../ScanUpload/CustomScanUpload";
import { ReactComponent as BulbIcon } from "assets/images/BulbIcon.svg";
import { determineMimeType } from "utilities/files";
import { addNewScanFilesAPI } from "api/scans";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { primaryColor } from "colors-config";
import dayjs from 'dayjs';
const { confirm } = Modal;

const UploadModal = ({
  setIsModalOpen,
  isModalOpen,
  patientId,
  clinicId,
  setFiles,
  scansRef,
  setScansInfo
}) => {
  const [formLoader, setFormLoader] = useState(false);
  const [fileLoader, setFileLoader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({
    UpperArch: null,
    LowerArch: null,
    BiteScan: null,
    BiteScan2: null,
  });
  const scanTypes = [
    {
      type: "UpperArch",
      label: "Upper Arch",
    },
    {
      type: "LowerArch",
      label: "Lower Arch",
    },
    {
      type: "BiteScan",
      label: "Bitescan",
    },
    {
      type: "BiteScan2",
      label: "Bitescan2",
    },
  ];
  const treatmentType = "prescription";
  const handleFileUpload = (file, type) => {
    const formattedKey = type;
    setUploadedFiles(prevFiles => ({
      ...prevFiles,
      [formattedKey]: file,
    }))
  };

 

  const handleFileRemove = file => {
    const formattedKey = file.imageView;
    setUploadedFiles({ ...uploadedFiles, [formattedKey]: null });
  };

  const initializeUploadedFiles = data => {
    const initialFiles = {
      UpperArch: null,
      LowerArch: null,
      BiteScan: null,
      BiteScan2: null,
    };

    // Iterate through data and populate the respective keys in initialFiles
    data?.forEach(file => {
      const formattedKey = file.patient_image_view;
      if (initialFiles.hasOwnProperty(formattedKey)) {
        initialFiles[formattedKey] = file;
      }
    });

    return initialFiles;
  };

  const validateUploadedFiles = uploadedFiles => {
    const hasFiles = Object.values(uploadedFiles).some(file => file !== null);
    if (!hasFiles) {
      return message.error("Please upload at least one scan");
    }
    return true;
  };

  const handleSubmit = async () => { 
    const isScans = validateUploadedFiles(uploadedFiles);
    if (!isScans) {
      return message.error("Please upload at least one scan");
    }
    try {
      setFormLoader(true);
      const filesList = Object.values(uploadedFiles)
        .filter(file => file !== null)
        .map(file => ({
          fileName: file.fileName,
          contentType: file.type || determineMimeType(file.fileName, file),
          imageView: file.imageView,
          type: "file",
        }));

      if (filesList.length) {
        const addNewData = {
          notify: true,
          patientId,
          model: treatmentType,
          createdFrom: "clinic",
          addFrom: treatmentType,
          caseId: 1,
          filesList,
        };

        const { data } = await addNewScanFilesAPI(clinicId, addNewData);
        const finalRes = initializeUploadedFiles(data);
        const scanInfo = {
          uploadedBy: "clinic",
          uploadedDate: dayjs().format('DD-MMM-YYYY'),
          scanType: treatmentType,
        }
        setFiles(finalRes);
        setScansInfo(scanInfo);
        scansRef.current = finalRes
        setIsModalOpen(false)
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setFormLoader(false);
    }
  };

  const closeModal = () => {
    const hasFiles = Object.values(uploadedFiles).some(file => file !== null);
    if (hasFiles) {
      return showConfirm();
    } else setIsModalOpen(false);
  };


  const showConfirm = () => {
    confirm({
      title: "Close Upload Scans",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          borderRadius: "30px",
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
          borderRadius: "30px",
        },
      },
      onOk() {
        setIsModalOpen(false)
    },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  return (
    <>
      <Modal
        title="Upload Scans"
        visible={isModalOpen}
        onCancel={closeModal}
        onOk={handleSubmit}
        okText="Submit"
        width={750}
        // open={showAppointmentModal}
        footer={[
          <Button
            key="cancel"
            onClick={closeModal}
            shape="round"
            style={{ width: "7rem" }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            shape="round"
            onClick={handleSubmit}
            loading={formLoader}
            style={{ width: "7rem" }}
          >
            Save
          </Button>,
        ]}
      >
          <Row gutter={16}>
            {scanTypes.map(scan => (
              <Col lg={6} md={12} sm={24} key={scan}>
                <ScanUpload
                  scanType={scan.type}
                  scanLabel={scan.label}
                  treatmentName={treatmentType}
                  uploadedFile={uploadedFiles[scan.type]?.file}
                  onFileUpload={handleFileUpload}
                  onFileRemove={handleFileRemove}
                  patientId={patientId}
                  clinicId={clinicId}
                  setFileLoader={setFileLoader}
                />
              </Col>
            ))}
          </Row>
    
        <Alert
          className="scan-view-info"
          message="Scan files will be updated for prescription with timestamp"
          type="info"
          showIcon
          icon={<BulbIcon />}
        />
      </Modal>
    </>
  );
};

export default UploadModal;
