import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Divider,
  Form,
  Input,
  Select,
  Popconfirm,
  Button,
  Radio,
  message,
  Spin
} from "antd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getPatientCountryCodesList, getClinicManagementSelector, getClinicProfileSelector } from "services/redux/selectors/clinicSelectors";
import styles from "./management.module.scss";
import "../../assets/styles/components/onboarding/Profile.scss";
import { loadClinic, updateClinicManagementAction } from "services/redux/actions/clinicActions";
import { mapClinicManagementData } from "utilities/settings";
import AppButton from "components/shared/AppButton/AppButton";
import { completeProfileStepThree, getClinicProfileService } from "api/patient";
import { CLINIC_LOADED, CLINIC_SETTINGS, COMPLETE_ONBOARDING_STEP_TREE } from "services/redux/actions/types";
import mixpanel from 'mixpanel-browser';
import { getClinicDetails, updateClinicOnboardingProgressStatus } from "api/clinic";
import { ONBOARDING_PROGRESS } from "constants";


const { Option } = Select;

const ClinicStep = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const clinicManagement = useSelector(getClinicManagementSelector);
  const clinic = useSelector(getClinicProfileSelector);


  const countryCodes = useSelector(getPatientCountryCodesList);
  const [managementForm] = Form.useForm();

  useEffect(() => {
    if (clinicManagement) {
      const formData = mapClinicManagementData(clinicManagement);
      const finalObj = {
        clinicAddress: formData,
      };
      managementForm.setFieldsValue(finalObj);
      mixpanel.track('Step 3 Started', { step: "Clinic Setup ", clinic_id:clinic?.clinic_id});
      setPageLoading(false);
    }
  }, [clinicManagement]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const { clinicAddress } = values;

      const payload = clinicAddress.map(
        ({ clinicId, createdAt, updatedAt, ...rest }) => ({
          ...rest,
          id: rest.id ? rest.id : `(New)${uuidv4()}`,
          zipCode: rest.zipCode ? rest.zipCode : '',
          default: rest.default ? 1 : 0,
        })
      );

      const clinicId = clinic?.clinic_id;
      const { data } = await completeProfileStepThree(payload, clinicId)
      const res = await getClinicDetails(clinic?.clinic_email);
      mixpanel.people.set({
        clinicDetails: data.data
      });
      mixpanel.track('Onboarding Completed', { step: "onboarding Completed",clinic_id:clinicId });
      await updateClinicOnboardingProgressStatus({ email: clinic?.clinic_email, status: ONBOARDING_PROGRESS.ONBOARDING_COMPLETED})
      dispatch({
        type: COMPLETE_ONBOARDING_STEP_TREE,
        payload: data.data
      })

      dispatch({
        type: CLINIC_LOADED,
        payload: res?.data?.body
      })
      message.success("Clinic Details Updated");
      history.push('/onboarding/success');
    } catch (error) {
      console.log("error", error);
      message.error('Error updating clinic details')
    } finally {
      setLoading(false);
      // setEditAddress(false);
    }
  };

  const onCancel = () => {
    // setEditAddress(false);
  };
  const onOk = () => {
    managementForm.submit();
  };

  const handleCountryNameChange = (value, name) => { };

  const removeHandleChange = (value, name) => {
    const values = managementForm.getFieldsValue().clinicAddress || [];
    let updatedValues = values;
    if (values.length > 0 && !values.some(it => it.default)) {
      updatedValues = updatedValues.map((el, i) => {
        if (i === 0) {
          return {
            ...el,
            default: true
          }
        }
        return el
      })
      managementForm.setFieldsValue({ clinicAddress: updatedValues });
    }
  }

  const handleRadioChange = (name) => {
    const values = managementForm.getFieldsValue().clinicAddress || [];
    let updatedValues;
    if (values.length === 1) {
      updatedValues = values.map((item, i) => ({
        ...item,
        default: true,
      }));
    } else {
      updatedValues = values.map((item, i) => ({
        ...item,
        default: i === name,
      }));
    }
    managementForm.setFieldsValue({ clinicAddress: updatedValues });
  };

  return (
    <div className={styles["management-form-container"]}>
      <Spin spinning={pageLoading}>
        <div style={{ padding: "10px 150px" }} className="clinic-step-form-container">
          <Form form={managementForm} layout="vertical" onFinish={onFinish} className="clinician-details-form padding-0">
            <h2 className="clinician-details-title clinician-details-title-for-clinic-step">
              Confirm Clinic Details
            </h2>
            <Form.List name="clinicAddress">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => {
                    const IsPrimary = managementForm.getFieldValue([
                      "clinicAddress",
                      name,
                      "default",
                    ]);

                    return (
                      <div key={key}>
                        <Row gutter={[32, 32]} align="middle">
                          <Col lg={6}>
                            <Form.Item
                              className={styles["required-input"]}
                              {...restField}
                              name={[name, "label"]}
                              fieldKey={[fieldKey, "label"]}
                              label=""
                              rules={[
                                {
                                  required: true,
                                  message: "Clinic name is required",
                                },
                              ]}
                            >
                              <Input placeholder="Clinic Name" size="large" />
                            </Form.Item>
                          </Col>
                          <Col lg={10}>
                            <Form.Item
                              {...restField}
                              name={[name, "address"]}
                              fieldKey={[fieldKey, "address"]}
                              label=""
                              className={styles["required-input"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your Address",
                                  validator(_, value) {
                                    if (value && value.trim().length !== 0) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(
                                      "Address should be valid"
                                    );
                                  },
                                },
                              ]}
                            >
                              <Input size={"large"} placeholder="Address" />
                            </Form.Item>
                          </Col>
                          <Col lg={1}>
                            {fields.length > 1 && (
                              <Popconfirm
                                title="Delete"
                                description="Are you sure you want to delete this Address?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={(e) => {
                                  remove(name);
                                  removeHandleChange(name);
                                }}
                                onCancel={() => { }}
                              >
                                <DeleteOutlined
                                  style={{
                                    marginTop: -10,
                                    fontSize: "20px",
                                    float: "right",
                                    color: "#77849E",
                                  }}
                                />
                              </Popconfirm>
                            )}
                          </Col>
                          <Col lg={7}>
                            <Form.Item
                              {...restField}
                              name={[name, "default"]}
                              fieldKey={[fieldKey, "default"]}
                              label=""
                              valuePropName="checked"
                            >
                              <div className={styles["primary-radio"]}>
                                {IsPrimary && (
                                  <span className={styles["radio-label"]}>
                                    Primary Clinic
                                  </span>
                                )}
                                <Radio
                                  onChange={() => handleRadioChange(name)}
                                  checked={IsPrimary}
                                  style={{marginLeft:"35px"}}
                                />
                              </div>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={[32, 32]} align="middle">
                          <Col lg={5} offset={6}>
                            <Form.Item
                              className={styles["required-input"]}
                              {...restField}
                              name={[name, "city"]}
                              fieldKey={[fieldKey, "city"]}
                              label=""
                              rules={[
                                { required: true, message: "City is required" },
                                {
                                  max: 30,
                                  message:
                                    "City name cannot be more than 30 characters",
                                },
                              ]}
                            >
                              <Input size={"large"} placeholder="City" />
                            </Form.Item>
                          </Col>
                          <Col lg={5}>
                            <Form.Item
                              className={styles["required-input"]}
                              {...restField}
                              name={[name, "county"]}
                              fieldKey={[fieldKey, "county"]}
                              label=""
                              rules={[
                                {
                                  required: true,
                                  max: 30,
                                  validator(_, value) {
                                    const regex =
                                      /^([a-zA-Z0-9 \u0080-\u024F]+(?:. |-| |'))*[a-zA-Z0-9 \u0080-\u024F]*$/;
                                    if (value && value.length >= 30) {
                                      return Promise.reject(
                                        "County/State name cannot be more than 30 characters"
                                      );
                                    }
                                    if (!value) {
                                      return Promise.reject(
                                        "County/State name is required"
                                      );
                                    }
                                    if (regex.test(value)) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject(
                                        "Invalid county name"
                                      );
                                    }
                                  },
                                },
                              ]}
                            >
                              <Input
                                size={"large"}
                                placeholder="County/State"
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[32, 32]} align="middle">
                          <Col lg={5} offset={6} md={24} sm={24}>
                            <Form.Item
                              {...restField}
                              name={[name, "zipCode"]}
                              fieldKey={[fieldKey, "zipCode"]}
                              label=""
                            >
                              <Input
                                size={"large"}
                                placeholder="Post/Zip/EIR Code"
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={5} md={24} sm={24}>
                            <Form.Item
                              className={styles["required-input"]}
                              {...restField}
                              name={[name, "country"]}
                              fieldKey={[fieldKey, "country"]}
                              label=""
                              rules={[
                                {
                                  required: true,
                                  message: "Country is required",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                defaultActiveFirstOption={false}
                                size="large"
                                placeholder="Country"
                                style={{ width: "100%", textAlign: "left" }}
                                options={countryCodes?.map((country, i) => ({
                                  value: country.name,
                                  label: (
                                    <>
                                      <span className="emoji-font">
                                        {country.emoji}
                                      </span>
                                      {` ${country.name}`}
                                    </>
                                  ),
                                  key: i,
                                }))}
                                onChange={(value) =>
                                  handleCountryNameChange(value, name, fieldKey)
                                }
                                filterOption={(input, option) =>
                                  option.value
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                      </div>
                    );
                  })}
                  <Row>
                    <Col offset={9}>
                      <Button
                        shape="round"
                        style={{ width: "180px" }}
                        className="addOn-btn"
                        onClick={() => {
                          add();
                        }}
                      >
                        Add Another Address
                      </Button>
                      <AppButton loading={loading} shape="round" style={{ marginLeft: '5px', padding: '0 40px', height: '35px' }} className="guideBtn" type="primary" onClick={() => managementForm.submit()} >
                        Save
                      </AppButton>
                    </Col>
                  </Row>

                </>
              )}
            </Form.List>

          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default ClinicStep;
