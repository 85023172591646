import { useState, useEffect, useContext } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Modal,
  Form,
  Input,
  Select,
  Radio,
  Spin,
  message,
  Row,
  Col,
  InputNumber,
  Button,
  Popconfirm,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import {
  clinicAddress,
  clinicDefaultAddress,
} from "services/redux/selectors/clinicSelectors";
import styles from "../../../assets/styles/modals/customSelect.module.scss";
import { ReactComponent as AddIcon } from "assets/images/teeths/addIcon.svg";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  getClinicLabDetailsAPI,
  getClinicLabs,
  updatePatientApprovalAPI,
} from "api/clinic";
import { getLabProductList } from "api/lab";
import { FORM_CONSTANTS } from "components/private/newPrescriptionForm/constants";
import { getOrderMessage } from "utilities/patientDetail";
import { companyType, primaryColor } from "colors-config";
import { createConfirmConfig } from "components/shared/ConfirmationModal/config";
import CustomEditIcon from "components/shared/Icon/EditIcon";
import ConfirmationModal from "components/shared/modals/confirmationModal";
import {
  calculateFormProductsTotal,
  calculateProductsTotal,
  initialProductsFormData,
  labProductsFormMapper,
} from "utilities/treatment";
import "./order.scss";
import {
  APPLIANCES_ORDER_KEY,
  PRODUCT_TYPE_PER_ALIGNER,
  TREATMENT_ORDER_KEY,
} from "utilities/constants";
import AppToolTip from "../AppToolTip/AppToolTip";
import TreatmentProductToolTip from "../TreatmentProductsTable/TreatmentProductToolTip";
import AppSettingsContext from "Context/useAppContext";
import Phases from "routes/private/PatientDetail/Phases/Phases";

import ScanUpload from "components/private/ClinicScans/components/ScanUpload/CustomScanUpload";
import { determineMimeType } from "utilities/files";
import { addNewScanFilesAPI } from "api/scans";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { savePhaseOrder } from "api/treatment";
dayjs.extend(utc);
const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

const LabOrderModal = ({
  title,
  onConfirmation,
  showModal,
  setShow,
  clinicProducts,
  partneredLabs,
  patientId,
  clinic,
  treatmentProgressDet,
  patientAddressRef,
  orderRetainerClick,
  orderRefinementClick,
  wholeTreatmentPlan,
  prescriptionForm,
  fromPatientJourneyButton,
  setFromPatientJourneyButton,
  setSendOrderWithoutPatientApproval,
  setShowAddPatientAddress,
  patientInfoState,
  modal,
  form,
  confirmPatientAddressModal,
  sendOrderWithoutPatientApproval,
  dropdownLabTreatmentId,
  paymentStatus,
  setIsModalUpdateOrderVisible,
  setDropdownLabTreatmentId,
  status,
  orderType,
  setOrderType,
  isPhaseOrder,
  getTreatmentPlans,
  getOrders,
}) => {
  const clinicMultipleAddresses = useSelector(clinicAddress);

  const defaultAddress = useSelector(clinicDefaultAddress);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [showPhasing, setShowPhasing] = useState(true);
  const [alignerNumbers, setAlignerNumbers] = useState({
    upper: 0,
    lower: 0,
  });
  const [selectedLab, setSelectedLab] = useState(null);
  const [partneredLabsByProduct, setPartneredLabsByProduct] = useState([]);
  const [isLabInputSpinning, setIsLabInputSpinning] = useState(true);
  const [addressId, setAddressId] = useState();
  const [sendTo, setSendTo] = useState("clinic");
  const [productTypeList, setProductTypeList] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [labProducts, setLabProducts] = useState([]);
  const [selectedLabProduct, setSelectedLabProduct] = useState([]);
  const [labProductLoading, setLabProductLoading] = useState(false);
  const [treatmentVersionList, setTreatmentVersionList] = useState([]);
  const [selectedLabProductName, setSelectedLabProductName] = useState();
  const [confirmLabApproveLoading, setConfirmLabApproveLoading] =
    useState(false);
  const [showAgreeModal, setShowAgreeModal] = useState(false);
  const [totalSum, setTotalSum] = useState("0.00");
  const [fileLoader, setFileLoader] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({
    upperarch: null,
    lowerarch: null,
    bitescan: null,
    bitescan2: null,
  });
  const scanTypes = [
    {
      type: "UpperArch",
      label: "Upper Arch",
    },
    {
      type: "LowerArch",
      label: "Lower Arch",
    },
    {
      type: "BiteScan",
      label: "Bitescan",
    },
    {
      type: "BiteScan2",
      label: "Bitescan2",
    },
  ];
  const treatmentType = "appliance";
  const formatKey = scan => scan.replace(/\s+/g, "").toLowerCase();

  const { clinicConfig } = useContext(AppSettingsContext);

  useEffect(() => {
    setAddressId(defaultAddress?.id || 0);
  }, [defaultAddress, selectedLabProductName]);

  useEffect(() => {
    if (selectedLab) {
      getAllLabProducts(selectedLab);
    }
  }, [selectedLab]);

  useEffect(() => {
    if (wholeTreatmentPlan.length) {
      const generateVersionList = wholeTreatmentPlan.map(i => ({
        ...i,
        label: i.revision_plan_version
          ? `Refinement Plan Version ${i.revision_plan_version}.0`
          : `Treatment Plan Version ${i.treatment_plan_version}.0`,
        value: i.treatment_plan_version,
      }));

      setTreatmentVersionList(generateVersionList);
    }
  }, [wholeTreatmentPlan]);

  const getApprovedTreatment = treatmentVersionList => {
    if (fromPatientJourneyButton) {
      const approvedTreatment = treatmentVersionList.find(
        obj => obj.lab_treatment_id == dropdownLabTreatmentId
      );
      return approvedTreatment;
    } else {
      const approvedTreatment = treatmentVersionList.find(obj => obj.approved);
      if (!approvedTreatment) {
        return treatmentVersionList[0];
      }
      return approvedTreatment;
    }
  };

  useEffect(() => {
    if (treatmentVersionList.length) {
      const getDefaultVersion = getApprovedTreatment(treatmentVersionList);
      if (getDefaultVersion) {
        setDropdownLabTreatmentId(getDefaultVersion.lab_treatment_id);
      }
      form.setFieldValue("version", getDefaultVersion.lab_treatment_id);
      form.setFieldValue("labProductId", getDefaultVersion?.labProductId);
      const sum = calculateProductsTotal(getDefaultVersion?.labProductList);
      form.setFieldValue("version", getDefaultVersion.lab_treatment_id);
      // form.setFieldValue("labProductId", getDefaultVersion?.labProductId);
      form.setFieldValue(
        "treatmentPlanVersion",
        getDefaultVersion.treatment_plan_version
      );
      // console.log("getDefaultVersion", getDefaultVersion);
      setAlignerNumbers({
        lower: getDefaultVersion.num_of_lower_aligners,
        upper: getDefaultVersion.num_of_upper_aligners,
      });
      // products: labProductsFormMapper(formData?.labProductList)

      const phasing = isPhaseOrder
        ? true
        : getDefaultVersion?.phasingOption === "yes"
        ? true
        : false;
      const filteredPhasing = getDefaultVersion?.phasingList?.filter(phase => !phase?.patient_order_id)
      const phasesList = filteredPhasing?.map((phase, index) => {
          return {
            ...phase,
            batch: index === 0 ? true : false,
            deliveryDate: phase?.deliveryDate
              ? dayjs.utc(phase.deliveryDate)
              : null,
          };
        }) || [];

      // console.log("phasesList", phasesList);
      form.setFieldValue("phasing", prescriptionForm.getFieldValue("phasing"));
      form.setFieldValue("phasing", phasing);
      form.setFieldValue(
        // phasing: phasing,
        "phases",
        phasesList.filter(phase => !phase?.patient_order_id)
      );
      if (
        getDefaultVersion?.labProductList?.length &&
        orderType === TREATMENT_ORDER_KEY
      ) {
        form.setFieldValue(
          "products",
          labProductsFormMapper(getDefaultVersion?.labProductList)
        );
      } else {
        form.setFieldsValue({
          products: initialProductsFormData(),
        });
      }
      if (orderType === TREATMENT_ORDER_KEY) {
        setTotalSum(sum);
      }
    } else {
      form.setFieldsValue({
        products: initialProductsFormData(),
      });
    }
  }, [treatmentVersionList, form]);

  const getAllLabProducts = async labId => {
    try {
      setLabProductLoading(true);
      const labProductsRes = await getLabProductList(labId);
      if (labProductsRes?.data?.labProducts?.length) {
        const labProductList = labProductsRes.data.labProducts;
        setLabProducts(labProductList);
        const selectedLabProductId = form.getFieldValue("labProductId");
        const findProduct = labProductList.find(
          obj => obj.id == selectedLabProductId
        );
        if (!findProduct) {
          form.setFieldValue("labProductId", null);
        }
      }
      // form.setFieldValue("phasing", prescriptionForm.getFieldValue("phasing"));
    } catch (error) {
      // message.error("Some Error occured!");
      console.error(error);
    } finally {
      setLabProductLoading(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      setIsModalVisible(true);
    }
  }, [showModal]);

  // useEffect(() => {
  //   if (clinicProducts?.length) {
  //     const uniqueProductTypeList = getAllProductTypes(clinicProducts);
  //     setProductTypeList(uniqueProductTypeList);
  //     if (uniqueProductTypeList?.length) {
  //       let defaultSelect = "Treatment";
  //       if (orderRetainerClick) defaultSelect = "Retainers";
  //       if (orderRefinementClick) defaultSelect = "Refinement";
  //       let defaultProduct = uniqueProductTypeList.find((obj) => obj.product_type == defaultSelect);
  //       if (!defaultProduct) {
  //         defaultProduct = uniqueProductTypeList[0];
  //       }
  //       const product_type_id = defaultProduct.product_type_id.toString();
  //       setSelectedProductType(product_type_id);
  //       _getPartneredLabsByProduct(product_type_id);

  //       // form.resetFields();
  //     }
  //   }
  // }, [clinicProducts]);

  // useEffect(() => {
  //   if (selectedProductType) {
  //     const getAllProductNameList = clinicProducts.filter(
  //       (obj) => obj.product_type_id == selectedProductType
  //     );
  //     // setProductNameList(getAllProductNameList);
  //     let defaultProductName = getAllProductNameList[0].product_name_id.toString();
  //     if (treatmentProgressDet?.treatment_name) {
  //       const getSelectedProductName = getAllProductNameList.find(
  //         (obj) => obj.product_name == treatmentProgressDet?.treatment_name
  //       );
  //       if (getSelectedProductName) {
  //         defaultProductName = getSelectedProductName?.product_name_id.toString();
  //       }
  //     }

  //     setSelectedProductName(defaultProductName);
  //   }
  // }, [selectedProductType]);

  useEffect(() => {
    _getPartneredLabsByProduct();
  }, [clinic]);

  useEffect(() => {
    if (partneredLabsByProduct.length) {
      const labId = partneredLabsByProduct[0].lab_id;
      setSelectedLab(labId);

      form.setFieldValue("lab", labId);
    }
  }, [partneredLabsByProduct, form]);

  const _getPartneredLabsByProduct = async () => {
    setIsLabInputSpinning(true);
    try {
      const { data } = await getClinicLabDetailsAPI(clinic.clinicId);
      if (data?.body?.data?.length) {
        setPartneredLabsByProduct(data?.body?.data);
      }

      // const partneredLabsRes = await getClinicLabs({
      //   clinicId: clinic.clinicId,
      //   productTypeId: productId,
      // });
      // setPartneredLabsByProduct(partneredLabsRes.body.partnerLabDetails);
      setIsLabInputSpinning(false);
    } catch (error) {
      console.log(error);
      setIsLabInputSpinning(false);
    } finally {
      setIsLabInputSpinning(false);
    }
  };

  // const getAllProductTypes = (products) => {
  //   const uniqueElements = Object.values(
  //     products.reduce((acc, obj) => {
  //       acc[obj.product_type] = obj;
  //       return acc;
  //     }, {})
  //   );
  //   return uniqueElements;
  // };

  const labOrderSubmit = () => {
    if (
      orderType === TREATMENT_ORDER_KEY &&
      paymentStatus === "-" &&
      clinicConfig?.receiveAppPayment
    ) {
      setIsModalUpdateOrderVisible(true);
      return message.info("Order cannot be placed without payment");
    }
    const versionNo = form.getFieldValue("version");
    const treatmentPlanDetails = treatmentVersionList.find(
      obj => obj.lab_treatment_id == versionNo
    );

    if (
      treatmentPlanDetails &&
      ((treatmentPlanDetails.sendToPatient == 1 &&
        treatmentPlanDetails.patientApproval == 0) ||
        sendOrderWithoutPatientApproval)
    ) {
      confirmPatientConsentModal(treatmentPlanDetails);
    } else {
      continueOrder();
    }
  };

  const handleOk = () => {
    if (companyType === 7) {
      setShowAgreeModal(true);
    } else {
      labOrderSubmit();
    }
  };

  const continueOrder = () => {
    if (sendTo == "patient") {
      if (
        patientInfoState &&
        (!patientInfoState.patient_address ||
          !patientInfoState.patient_address ||
          !patientInfoState.patient_city ||
          !patientInfoState.patient_country ||
          !patientInfoState.patient_county ||
          !patientInfoState.patient_postal_code)
      ) {
        setShowAddPatientAddress(true);
      } else {
        confirmPatientAddressModal();
      }
    } else {
      form.submit();
    }
  };

  const shouldFormRender = () => {
    return (
      // productTypeList?.length > 0 &&
      // selectedProductName &&
      // selectedProductType &&
      partneredLabs.length > 0
    );
  };

  const onTreatmentVersionChange = value => {
    // if(value==='other'){
    //   setOtherDisable(true);
    //   form.setFieldsValue({
    //     products: initialProductsFormData(),
    //   })
    //   setTotalSum(0)
    //   return;
    // }

    // setOtherDisable(false);
    setDropdownLabTreatmentId(value);
    const getSelectedTreatment = treatmentVersionList.find(
      obj => obj.lab_treatment_id == value
    );
    if (getSelectedTreatment) {
      const formValues = {
        products: labProductsFormMapper(getSelectedTreatment?.labProductList),
      };
      // console.log(getSelectedTreatment);
      const sum = calculateProductsTotal(getSelectedTreatment?.labProductList);
      form.setFieldsValue(formValues);
      setTotalSum(sum);
    } else {
      form.setFieldsValue({
        products: initialProductsFormData(),
      });
    }
  };

  function checkLabProductId(products) {
    if (!products.length) {
      return true;
    }
    return products.find(product => !product.labProductId);
  }

  const updatePhasingOrder = async (val, treatmentPlanId) => {
    const isBatchChecked = val?.phases?.some(ph => ph.batch);

    if (!isBatchChecked) {
      return message.error("Please select at least one phase for order");
    }

    try {
      setConfirmLoading(true);

      const phaseDataInput = {
        patient_order_id: isPhaseOrder,
        phases: val.phasing
          ? val.phases.map(phase => {
              return {
                ...phase,
                deliveryDate: dayjs(phase.deliveryDate).format("YYYY-MM-DD"),
                orderNote: val.orderNote || "",
                shipping_address: val.sendTo,
                clinicAddressId: val.clinicAddress || 0,
                createdFrom: "clinic",
              };
            })
          : [],
        lab_treatment_id: treatmentPlanId,
        phaseOrder: true,
      };

      await savePhaseOrder(phaseDataInput);

      message.success("Phasing Order Placed");
      getTreatmentPlans();
      getOrders();
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
      setShow(false);
    }
  };

  const onFinish = async val => {
    const hasNullLabProductId = checkLabProductId(val.products);
    if (val?.products?.length > 1) {
      if (hasNullLabProductId) {
        return message.error("Please select a product or delete empty one");
      }
    }

    if (val.phasing) {
      if (!val?.phases?.length) {
        return message.error("Phases are missing");
      }

      if (val?.phases?.length) {
        const testPhses = val.phases.some(phase => phase.batch);

        if (!testPhses) {
          return message.error(
            "Please select at least one phase to place the order"
          );
        }
      }
    }

    try {
      const versionNo = form.getFieldValue("version");

      const treatmentPlanDetails = treatmentVersionList.find(
        obj => obj.lab_treatment_id == versionNo
      );
      val.refinement = false;
      if (treatmentPlanDetails) {
        val.sendToPatient = treatmentPlanDetails.sendToPatient;
      }
      if (treatmentPlanDetails && treatmentPlanDetails.revision_plan_version) {
        val.refinement = true;
      }
      if (orderType === APPLIANCES_ORDER_KEY) {
        val.others = true;
        val.lab = selectedLab;
      }

      val.productType = val.refinement ? 6 : 1;
      val.patientId = patientId;
      // return
      if (isPhaseOrder) {
        return updatePhasingOrder(val, treatmentPlanDetails.lab_treatment_id);
      }
      // setConfirmLoading(true);
      const reponse = await onConfirmation(
        val,
        treatmentPlanDetails?.lab_treatment_id
      );

      const filesList = Object.values(uploadedFiles)
        .filter(file => file !== null)
        .map(file => ({
          fileName: file.fileName,
          contentType: file.type || determineMimeType(file.fileName, file),
          imageView: file.imageView,
          type: "file",
        }));

      if (filesList.length && reponse?.body?.orderId) {
        const addNewData = {
          notify: false,
          patientId,
          model: treatmentType,
          createdFrom: "clinic",
          addFrom: "order",
          caseId: 1,
          orderId: reponse.body.orderId,
          filesList,
        };

        await addNewScanFilesAPI(clinic.clinicId, addNewData);
      }

      setIsModalVisible(false);
      setShow(false);
    } catch (err) {
      console.log("err", err);
    } finally {
      setConfirmLoading(false);
    }
  };

  const onSendToChange = e => {
    setSendTo(e.target.value);
  };

  const confirmPatientConsentModal = treatmentPlanDetails => {
    const confirmPatientConsent = createConfirmConfig({
      title: "Patient Consent",
      width: 520,
      icon: null,
      centered: true,
      okButtonProps: {
        loading: confirmLabApproveLoading,
      },
      content: (
        <div style={{ margin: "20px 0" }}>
          <div>
            <p>Have you taken your patient's approval to place this order?</p>
          </div>
        </div>
      ),
      onOk: async () => {
        try {
          setConfirmLabApproveLoading(true);

          if (!sendOrderWithoutPatientApproval) {
            await updatePatientApprovalAPI(
              treatmentPlanDetails.lab_treatment_id
            );
          }
        } catch (error) {
          console.log("error occured", error);
        } finally {
          setConfirmLabApproveLoading(false);
          continueOrder();
        }
      },
      onCancel: () => {},
      okText: "Confirm & Order",
    });
    modal.confirm(confirmPatientConsent);
  };

  const handleEdit = () => {
    setShowAddPatientAddress(true);
  };

  const handleProductNameChange = (value, name, fieldKey) => {
    const values = form.getFieldsValue().products || [];
    const isDuplicate = values.find(
      (item, index) => item?.labProductId === value && index !== name
    );
    if (isDuplicate) {
      message.warning(
        "This product has already been added. Please select a different product."
      );
      form.setFields([
        {
          name: [`products`, name, "labProductId"],
          value: null,
        },
      ]);
      return;
    }
    const product = labProducts.find(item => item.id === value);
    const quantity = product.type === "Package" ? 1 : 1;
    const total = product.price * quantity;

    form.setFields([
      {
        name: [`products`, name, "arches"],
        value: product.arches,
      },
      {
        name: [`products`, name, "newPrice"],
        value: product.price,
      },
      {
        name: [`products`, name, "quantity"],
        value: quantity,
      },
      {
        name: [`products`, name, "free"],
        value: product.free,
      },
      {
        name: [`products`, name, "total"],
        value: total,
      },
      {
        name: [`products`, name, "quantityType"],
        value: product.quantityType,
      },
      {
        name: [`products`, name, "currency"],
        value: product.currency,
      },
    ]);
    setSelectedLabProductName(value);
    calculateTotal();
  };

  const onQuantityChange = (value, name) => {
    const pricePerUnit = form.getFieldValue([`products`, name, `newPrice`]);
    const totalPrice = pricePerUnit * value;
    form.setFields([
      {
        name: [`products`, name, "quantity"],
        value: value,
      },
      {
        name: [`products`, name, "total"],
        value: totalPrice,
      },
    ]);
    calculateTotal();
  };

  const calculateTotal = () => {
    const values = form.getFieldsValue().products || [];
    const finalSum = calculateFormProductsTotal(values);
    setTotalSum(finalSum);
  };

  const handleFileUpload = (file, type) => {
    const formattedKey = formatKey(type);
    setUploadedFiles(prevFiles => ({
      ...prevFiles,
      [formattedKey]: file,
    }));
  };

  const handleFileRemove = type => {
    const formattedKey = formatKey(type);
    setUploadedFiles({ ...uploadedFiles, [formattedKey]: null });
  };

  const onClose = () => {
    setIsModalVisible(false);
    setShow(false);
    setFromPatientJourneyButton(false);
    setSendOrderWithoutPatientApproval(false);
    setOrderType(TREATMENT_ORDER_KEY);
  };

  const submit = () =>{
    if (orderType === APPLIANCES_ORDER_KEY) {
      const hasFiles = Object.values(uploadedFiles).some(file => file !== null);
      console.log("hasFiles", hasFiles)
      if (hasFiles) {
        return showConfirm();
      } else {
        onClose();
      }
    } else {
      onClose();
    }
  }
  const handleCancel = () => {
    if (form.isFieldsTouched()) {
      showCloseConfirm()
    } else {
    submit()
    }
  };

  const showConfirm = () => {
    confirm({
      title: "Close Upload Scans",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes or scans will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          borderRadius: "30px",
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
          borderRadius: "30px",
        },
      },
      onOk() {
        onClose();
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const showCloseConfirm = () => {
    confirm({
      title: "Close order form",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          borderRadius: "30px",
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
          borderRadius: "30px",
        },
      },
      onOk() {
      submit()
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  return (
    <>
      <Modal
        okText={shouldFormRender() ? "Place Orders" : "Ok"}
        width={1000}
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okButtonProps={{
          style: { borderRadius: "30px" },
        }}
        cancelButtonProps={{ style: { borderRadius: "30px" } }}
      >
        {shouldFormRender() && (
          <Spin spinning={isLabInputSpinning}>
            {" "}
            <Form
              form={form}
              style={{
                width: "100%",
              }}
              name="complex-form"
              layout={"vertical"}
              onFinish={onFinish}
              initialValues={{
                clinicAddress: defaultAddress?.id,
                patientId: patientId,
                // productType: selectedProductType,
                // productName: selectedProductName,
                sendTo: "clinic",
                lab: selectedLab,
                patient_id: patientInfoState?.patient_id,
                patient_name:
                  patientInfoState?.patient_first_name +
                  " " +
                  patientInfoState.patient_last_name,
              }}
              className="labOrderModal-container"
            >
              <div>
                <Row gutter={[16, 16]}>
                  <Col lg={12} md={24} sm={24}>
                    <Form.Item
                      label="Patient ID"
                      name="patient_id"
                      className="inline-form-left"
                    >
                      <Input size="large" disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24} sm={24} style={{ position: "relative" }}>
                    <Form.Item
                      label="Patient Name"
                      name="patient_name"
                      className="inline-form-left"
                    >
                      <Input size="large" disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>
                {orderType === TREATMENT_ORDER_KEY && (
                  <Row gutter={[16, 16]}>
                    <Col lg={12} md={24} sm={24}>
                      <Form.Item label="Lab Name" name="lab">
                        <Select
                          size="large"
                          disabled
                          onChange={labId => {
                            setSelectedLab(labId);
                          }}
                          style={{ width: "100%" }}
                          dropdownRender={menu => <div>{menu}</div>}
                        >
                          {partneredLabsByProduct.map(lab => (
                            <Option key={lab.lab_id} value={lab.lab_id}>
                              {lab.lab_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={12} md={24} sm={24} style={{ position: "relative" }}>
                      {status.patient_journey_status_id !== 9 ? (
                        <Form.Item
                          label="Treatment Plan Version"
                          name="version"
                          className="inline-form-right"
                        >
                          <Select
                            size="large"
                            style={{ width: "100%" }}
                            onChange={onTreatmentVersionChange}
                          >
                            {treatmentVersionList.map((item, index) => {
                              return (
                                <Option
                                  key={index}
                                  value={item.lab_treatment_id}
                                >
                                  {item.label}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      ) : null}
                    </Col>
                  </Row>
                )}
                <div className="dynamic-products">
                  <Form.List name="products">
                    {(fields, { add, remove }) => (
                      <div style={{ padding: "10px 15px" }}>
                        {fields.map(({ key, name, fieldKey, ...restField }) => {
                          const productType = form.getFieldValue([
                            "products",
                            name,
                            "quantityType",
                          ]);
                          const pCurrency = form.getFieldValue([
                            "products",
                            name,
                            "currency",
                          ]);
                          const pPrice = form.getFieldValue([
                            "products",
                            name,
                            "newPrice",
                          ]);
                          const pNetPrice = form.getFieldValue([
                            "products",
                            name,
                            "netPrice",
                          ]);
                          const pDiscountPrice = form.getFieldValue([
                            "products",
                            name,
                            "discountAmount",
                          ]);
                          return (
                            <Row gutter={[16, 16]} key={key}>
                              <Col lg={7} md={12} sm={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "labProductId"]}
                                  fieldKey={[fieldKey, "labProductId"]}
                                  label="Product/Service Name"
                                >
                                  <Select
                                    size="large"
                                    placeholder="Select Product"
                                    allowClear
                                    disabled={orderType === TREATMENT_ORDER_KEY}
                                    // onChange={handleProductNameChange}
                                    onChange={value =>
                                      handleProductNameChange(
                                        value,
                                        name,
                                        fieldKey
                                      )
                                    }
                                    showSearch
                                    optionRender={option => {
                                      return (
                                        <>
                                          <span className="treatment-arches-dp">
                                            <span className="dp-label">
                                              {option.data.productName}
                                            </span>
                                            <span className="db-arches">
                                              {" "}
                                              {option.data.arches}
                                            </span>
                                          </span>
                                        </>
                                      );
                                    }}
                                    options={labProducts?.map(item => {
                                      return {
                                        ...item,
                                        label: item.productName,
                                        value: item.id,
                                      };
                                    })}
                                    filterOption={(input, option) => {
                                      return (
                                        option?.label
                                          ?.toLowerCase()
                                          .includes(input?.toLowerCase()) ||
                                        option?.arches
                                          ?.toLowerCase()
                                          .includes(input?.toLowerCase())
                                      );
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={7} md={12 } sm={12}>
                                <Form.Item
                                  {...restField}
                                  name={[name, "arches"]}
                                  fieldKey={[fieldKey, "arches"]}
                                  label="Arches Treated"
                                >
                                  <Select
                                    size="large"
                                    disabled
                                    placeholder="Arches Treated"
                                  >
                                    <Option value="Upper and Lower">
                                      Upper and Lower
                                    </Option>
                                    <Option value="Upper">Upper</Option>
                                    <Option value="Lower">Lower</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col lg={9} md={24} sm={24}>
                                <Row>
                                  {productType === PRODUCT_TYPE_PER_ALIGNER && (
                                    <Col lg={6} md={12} sm={12}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "quantity"]}
                                        fieldKey={[fieldKey, "quantity"]}
                                        label="Quantity"
                                      >
                                        <InputNumber
                                          size="large"
                                          disabled={
                                            orderType === TREATMENT_ORDER_KEY
                                          }
                                          style={{ width: "100%" }}
                                          placeholder="Quantity"
                                          max={1000}
                                          min={1}
                                          onChange={value =>
                                            onQuantityChange(value, name)
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                  )}
                                  <Col
                                    lg={
                                      productType === PRODUCT_TYPE_PER_ALIGNER
                                        ? 11
                                        : 17
                                    }
                                  >
                                    <div>
                                      <div className="cost-container">
                                        <label style={{ paddingBottom: "8px" }}>
                                          {productType ===
                                          PRODUCT_TYPE_PER_ALIGNER
                                            ? "Net Price per unit"
                                            : "Net Price"}
                                        </label>
                                        {pDiscountPrice && (
                                          <AppToolTip
                                            title={
                                              <TreatmentProductToolTip
                                                currency={pCurrency}
                                                price={pPrice}
                                                netPrice={pNetPrice}
                                                discountPrice={pDiscountPrice}
                                              />
                                            }
                                          />
                                        )}
                                      </div>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "newPrice"]}
                                        fieldKey={[fieldKey, "newPrice"]}
                                        label={""}
                                        style={{ position: "relative" }}
                                      >
                                        <InputNumber
                                          size="large"
                                          placeholder="Enter price"
                                          disabled
                                          step={0.01}
                                          precision={2}
                                        />
                                      </Form.Item>
                                    </div>
                                  </Col>
                                  <Col lg={7} style={{ position: "relative" }} md={12} sm={12}>
                                    <div
                                      style={{
                                        position: "absolute",
                                        right: "-40px",
                                        top: "-5px",
                                      }}
                                    >
                                      <Form.Item
                                        {...restField}
                                        name={[name, "free"]}
                                        fieldKey={[fieldKey, "free"]}
                                        label=""
                                        valuePropName="checked"
                                      >
                                        <Checkbox size="large" disabled>
                                          Free
                                        </Checkbox>
                                      </Form.Item>
                                    </div>

                                    <Form.Item
                                      {...restField}
                                      name={[name, "currency"]}
                                      fieldKey={[fieldKey, "currency"]}
                                      label="Currency"
                                    >
                                      <Input
                                        size="large"
                                        placeholder="Currency"
                                        disabled
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={1}>
                                {orderType !== TREATMENT_ORDER_KEY &&
                                  fields.length > 1 && (
                                    <Popconfirm
                                      title="Delete"
                                      description="Are you sure you want to delete this Product/Service?"
                                      okText="Yes"
                                      cancelText="No"
                                      onConfirm={e => {
                                        remove(name);
                                        calculateTotal();
                                      }}
                                      onCancel={() => {}}
                                    >
                                      <DeleteOutlined
                                        style={{
                                          marginTop: 40,
                                          color: "black",
                                          fontSize: "20px",
                                          float: "right",
                                        }}
                                      />
                                    </Popconfirm>
                                  )}
                              </Col>
                            </Row>
                          );
                        })}
                        {orderType !== TREATMENT_ORDER_KEY ? (
                          <Button
                            disabled={fields.length === 3}
                            icon={
                              <AddIcon
                                style={{
                                  fill:
                                    fields.length === 3 ? "grey" : primaryColor,
                                }}
                              />
                            }
                            className="addOn-btn"
                            onClick={() => add()}
                          >
                            Add Another
                          </Button>
                        ) : null}
                      </div>
                    )}
                  </Form.List>
                  <div className="border-line" />
                  <div className="totals">
                    <Row>
                      <Col offset={17} lg={7}>
                        <p className="total-label">Total Amount</p>
                      </Col>
                      <Col offset={17} lg={7}>
                        <h2 style={{ color: primaryColor }}>{totalSum}</h2>
                      </Col>
                    </Row>
                  </div>

                  {orderType !== APPLIANCES_ORDER_KEY && (
                    <>
                      {" "}
                      <div className="border-line" />
                      <div style={{ width: "100%", padding: "15px" }}>
                        <Form.Item
                          name="phasing"
                          // style={{ textAlign: "left", marginTop: "2rem" }}
                          label="Phasing"
                          disabled={true}
                        >
                          <Radio.Group
                            onChange={e => {
                              // console.log(value)
                              setShowPhasing(e.target.value);
                            }}
                          >
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group>
                        </Form.Item>

                        {showPhasing && (
                          <Phases
                            lowerLength={alignerNumbers.lower}
                            upperLength={alignerNumbers.upper}
                            form={form}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>

                <Form.Item
                  name="orderNote"
                  style={{ textAlign: "left" }}
                  label="Order note"
                >
                  <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
                </Form.Item>

                <Form.Item
                  name="sendTo"
                  style={{ textAlign: "left", marginTop: "2rem" }}
                  label="Shipping Address"
                >
                  <Radio.Group onChange={onSendToChange}>
                    <Radio value="clinic">Same as clinician address</Radio>
                    <Radio style={{ marginLeft: "3rem" }} value="patient">
                      Same as patient address
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                {sendTo === "patient" ? (
                  <div style={{ display: "flex", gap: "6px" }}>
                    <div>Patient Address:</div>
                    {patientAddressRef.current?.trim() ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          textTransform: "capitalize",
                        }}
                        onClick={handleEdit}
                      >
                        <span>{patientAddressRef.current?.trim()}</span>
                        <CustomEditIcon />
                      </div>
                    ) : (
                      "N/A"
                    )}
                  </div>
                ) : (
                  <div className="custom-select">
                    <Form.Item
                      rules={[
                        {
                          required: true,
                          message: "Please choose a location",
                        },
                      ]}
                      label="Choose Location"
                      name="clinicAddress"
                    >
                      <Select
                        onChange={value => {
                          setAddressId(value);
                        }}
                        size="large"
                      >
                        {clinicMultipleAddresses?.map(el => {
                          return (
                            <Option value={el.id}>
                              <div className={styles["option-grid"]}>
                                <span className={styles["option-label"]}>
                                  {el.label}
                                </span>
                                <span></span>
                                <span className={styles["option-description"]}>
                                  {` ${el.address ? el.address : ""} ${
                                    el.county ? el.county : ""
                                  } ${el.country ? el.country : ""}${
                                    el.zipCode ? ", " : ""
                                  }${el.zipCode ? el.zipCode : ""} `}
                                </span>
                                <span className={styles["option-checkmark"]}>
                                  {addressId === el.id ? (
                                    <CheckOutlined />
                                  ) : null}
                                </span>
                              </div>
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                )}
                {orderType === APPLIANCES_ORDER_KEY ? (
                  <div style={{ padding: "0px 0px", width: "80%" }}>
                    <label>Scan Files (Optional)</label>
                    <Row gutter={16}>
                      {scanTypes.map(scan => (
                        <Col span={6} key={scan.type}>
                          <ScanUpload
                            scanType={scan.type}
                            scanLabel={scan.label}
                            treatmentName={treatmentType}
                            uploadedFile={
                              uploadedFiles[formatKey(scan.type)]?.file
                            }
                            onFileUpload={handleFileUpload}
                            onFileRemove={handleFileRemove}
                            patientId={patientId}
                            clinicId={clinic.clinicId}
                            setFileLoader={setFileLoader}
                          />
                        </Col>
                      ))}
                    </Row>
                  </div>
                ) : null}
              </div>
            </Form>
          </Spin>
        )}
        {!shouldFormRender() && (
          <div>
            {/* {productTypeList?.length <= 0 && <p>No products available</p>} */}
            {partneredLabs.length <= 0 && <p> No partnered labs available!</p>}
          </div>
        )}
      </Modal>

      {showAgreeModal && (
        <ConfirmationModal
          title="Are you sure?"
          content={
            "Do you want to continue submitting this order to the lab? Please note that you are confirming to the lab cost associated to this order."
          }
          showModal={showAgreeModal}
          setShow={setShowAgreeModal}
          onConfirmation={labOrderSubmit}
          okText="Confirm"
        ></ConfirmationModal>
      )}
    </>
  );
};

LabOrderModal.propTypes = {
  isPhaseOrder: PropTypes.string.isRequired,
  getTreatmentPlans: PropTypes.func.isRequired, // api to get all tp data
  getOrders: PropTypes.func.isRequired, // api to get all orders data
};
LabOrderModal.defaultProps = {
  isPhaseOrder: null,
};

const mapStateToProps = state => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(LabOrderModal);
