import { useEffect, useState } from "react";
import { Row, Col, Divider, Form, Input, message, Checkbox } from "antd";
import styles from "./profileForm.module.scss";
import SupportUserIcon from "components/shared/Icon/SupportUser";
import SupportPhone from "components/shared/Icon/SupportPhone";

import FormFooter from "../../../shared/FormFooter/FormFooter";
import CountryCodeSelect from "components/shared/CountryCodeSelect/CountryCodeSelect";
import { useDispatch } from "react-redux";
import { updateClinicProfileAction } from "services/redux/actions/clinicActions";
import { mapClinicProfileData } from "utilities/settings";
import { updateClinicProfileAPI } from "api/settingsApi";
import mixpanel from 'mixpanel-browser';

const ProfileForm = ({ setEditProfile, clinicProfile, clinicId }) => {
  const [loading, setLoading] = useState(false);
  const [disableSupportEmail, setDisableSupportEmail] = useState(false);
  const [disableSupportPhone, setDisableSupportPhone] = useState(false);
  const [profileForm] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const formData = mapClinicProfileData(clinicProfile);
    profileForm.setFieldsValue(formData);
    setDisableSupportEmail(formData.supportSameEmail);
    setDisableSupportPhone(formData.supportSamePhone);
  }, [clinicProfile, profileForm]);

  const onFinish = async values => {
    // console.log("Values", values);
    const { clinic_name, supportSameEmail, supportSamePhone, supportEmail } =
      values;
    const payload = {
      clinicName: clinic_name,
      supportSameEmail: supportSameEmail,
      phone: values.clinic_contact.phoneNumber,
      countryCode: values.clinic_contact.countryCode,
      supportSamePhone: supportSamePhone,
      supportEmail: supportEmail,
      supportNumber: values.support_contact.phoneNumber,
      supportPrefix: values.support_contact.countryCode,
    };
    try {
      setLoading(true);
      const { data } = await updateClinicProfileAPI(payload, clinicId);

      mixpanel.people.set({
        $name:values.clinic_name,
        PhoneNo: values.clinic_contact.countryCode + " " + values.clinic_contact.phoneNumber,
        supportNumber:  values.support_contact.countryCode + " " + values.support_contact.phoneNumber,
        supportEmail: supportEmail
      });
      
      message.success("Clinic profile updated");
      dispatch(
        updateClinicProfileAction({
          ...values,
          supportPrefix: values.support_contact.countryCode,
          supportNumber: values.support_contact.phoneNumber,
          clinic_contact: values.clinic_contact.phoneNumber,
          country_code_name: values.clinic_contact.countryCode,
        })
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      setEditProfile(false);
    }
  };

  const onCancel = () => {
    setEditProfile(false);
  };
  const onOk = () => {
    profileForm.submit();
  };

  const onChangeSamePhone = e => {
    const { checked } = e.target;
    if (checked) {
      const clinicContact = profileForm.getFieldValue("clinic_contact");
      
      profileForm.setFieldValue("support_contact", clinicContact);
      setDisableSupportPhone(checked);
    } else {
      setDisableSupportPhone(checked);
    }
  };
  const onChangeSameEmail = e => {
    const { checked } = e.target;
    if (checked) {
      const email = profileForm.getFieldValue("clinic_email");
      profileForm.setFieldValue("supportEmail", email);
      setDisableSupportEmail(checked);
    } else {
      setDisableSupportEmail(checked);
    }
  };

  const onPhoneNoChange=(event)=>{
    const supportSamePhone = profileForm.getFieldValue("supportSamePhone");
    const clinicContact = profileForm.getFieldValue("clinic_contact");
    if(supportSamePhone){
      profileForm.setFieldValue("support_contact", {
        phoneNumber:event.target.value,
        countryCode : clinicContact.countryCode
      });
    }
  }

  const onCountryChange=(value)=>{
    
    const supportSamePhone = profileForm.getFieldValue("supportSamePhone");
    const clinicContact = profileForm.getFieldValue("clinic_contact");
    if(supportSamePhone){
      profileForm.setFieldValue("support_contact", {
        phoneNumber:clinicContact.phoneNumber,
        countryCode : value
      });
    }
  }

  return (
    <div>
      <div className={styles["profile-view-container"]}>
        <Row align="middle" justify="space-between">
          <Col>
            <h2 className={styles["heading"]}>Clinician Profile</h2>
          </Col>
        </Row>
        <Form
          className="registration-form"
          form={profileForm}
          autoComplete="off"
          layout={"vertical"}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Row align="top">
            <Col lg={18}>
              <Row gutter={[16, 16]} align="middle">
                <Col lg={14} md={24} sm={24} className={styles["info-col"]}>
                  <Form.Item
                    name="clinic_name"
                    label="Clinician Name"
                    className={styles["profile-form-label"]}
                    rules={[
                      {
                        required: true,
                        message: "Please input clinician name!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Clinician Name"
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Form.Item
                    name={["clinic_contact", "phoneNumber"]}
                    label="Phone number"
                    className={styles["profile-form-label"]}
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                      () => ({
                        validator(_, value) {
                          const convertedValue = Number(value);
                          if (!value) {
                            return Promise.resolve();
                          } else if (
                            !isNaN(convertedValue) &&
                            (value.length === 9 ||
                              value.length === 10 ||
                              value.length === 8)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Invalid phone number");
                        },
                      }),
                    ]}
                  >
                    <Input
                      size="large"
                      addonBefore={
                        <Form.Item
                          noStyle
                          name={["clinic_contact", "countryCode"]} // No extra Form.Item wrapper
                          className={styles["profile-form-label"]}
                        >
                          <CountryCodeSelect onChange={onCountryChange} />
                        </Form.Item>
                      }
                      autoComplete="off"
                      placeholder="Phone Number"
                      className={styles["phone-input"]}
                      onChange={onPhoneNoChange}
                    />
                  </Form.Item>
                  <Form.Item
                    name="clinic_email"
                    label="Email"
                    className={styles["profile-form-label"]}
                    rules={[
                      {
                        type: "email",
                        message: "Invalid email address",
                      },
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Email address"
                      autoComplete="off"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col lg={8}></Col>
              </Row>
            </Col>
            <Col lg={6}>
              <div className={styles["form-user-icon"]}>
                <SupportUserIcon style={{ width: "10rem" }} />
              </div>
            </Col>
          </Row>
          <Divider />
          {/* Support Form */}
          <Row align="top">
            <Col lg={18}>
              <Row gutter={[16, 16]} align="middle">
                <Col lg={14} md={24} sm={24}  className={styles["info-col"]}>
                  <Form.Item
                    name="supportEmail"
                    label="Help & Support Email"
                    className={styles["profile-form-label"]}
                    rules={[
                      {
                        required: true,
                        message: "Please input your support email!",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Help & Support Email"
                      autoComplete="off"
                      disabled={disableSupportEmail}
                    />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item
                    name="supportSameEmail"
                    valuePropName="checked"
                    style={{
                      marginTop: "3rem",
                      fontFamily: "Mulish",
                      fontWeight: "600",
                    }}
                  >
                    <Checkbox onChange={onChangeSameEmail}>
                      Same as Clinician's Email
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]} align="middle">
                <Col lg={14} className={styles["info-col"]}>
                  <Form.Item
                    name={["support_contact", "phoneNumber"]}
                    label="Phone number"
                    className={styles["profile-form-label"]}
                    
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone number!",
                      },
                      () => ({
                        validator(_, value) {
                          const convertedValue = Number(value);
                          if (!value) {
                            return Promise.resolve();
                          } else if (
                            !isNaN(convertedValue) &&
                            (value.length === 9 ||
                              value.length === 10 ||
                              value.length === 8)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject("Invalid phone number");
                        },
                      }),
                    ]}
                  >
                    <Input
                      disabled={disableSupportPhone}
                      size="large"
                      
                      addonBefore={
                        <Form.Item
                          noStyle
                          name={["support_contact", "countryCode"]} // No extra Form.Item wrapper
                        >
                          <CountryCodeSelect disabled={disableSupportPhone} />
                        </Form.Item>
                      }
                      autoComplete="off"
                      placeholder="Phone Number"
                      className={styles["phone-input"]}
                    />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item
                    name="supportSamePhone"
                    valuePropName="checked"
                    style={{
                      marginTop: "3rem",
                      fontFamily: "Mulish",
                      fontWeight: "600",
                    }}
                  >
                    <Checkbox onChange={onChangeSamePhone}>
                      Same as Clinician's Phone
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <div className={styles["form-user-phone"]}>
                <SupportPhone />
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <FormFooter onCancelClick={onCancel} onOkClick={onOk} loading={loading} />
    </div>
  );
};

export default ProfileForm;
