import { useHistory, useLocation } from "react-router-dom";
import { Progress, Button, Row, Col } from "antd";
import "../../../assets/styles/components/onboarding/StepLayout.scss";
import actor from "assets/images/ProfileUser.svg";
import helpLineSupport from "../../../assets/images/helpLineSupport.png";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import locationIcon from '../../../assets/images/location.png'
import { clinicSelector } from "services/redux/selectors/clinicSelectors";
import { useSelector } from "react-redux";
import { primaryColor, tagBackgroundColor } from "colors-config";


const Onboarding = ({ children }) => {
  const history = useHistory();
  const clinic = useSelector(clinicSelector);
  const location = useLocation();
  let heading = "1. Profile";
  let image = actor;

  const currentStep = () => {
    if (
      location.pathname === "/onboarding" ||
      location.pathname === "/onboarding/"
    ) {
      heading = "1. Profile";
      image = actor;
      return 1;
    }
    if (location.pathname.startsWith("/onboarding/help-support")) {
      heading = "2. Help & Support";
      image = helpLineSupport;
      return 2;
    }
    if (location.pathname.startsWith("/onboarding/clinic-setup")) {
      heading = "3. Clinic Setup";
      image = locationIcon;
      return 3;
    }
    if (location.pathname.startsWith("/onboarding/success")) {
      heading = "4. Success";
      return 4;
    }
    return 1;
  };

  const calculateProgress = () => {
    const step = currentStep();
    return (step / 4) * 100;
  };

  const getNextBackgroundColor = () => {
    const step = currentStep();
    if (step === 1) {
      switch (clinic?.onboardingStep) {
        case 'profile':
          return 'rgba(230, 230, 230, 1)'
        case 'help-support':
        case 'clinic-setup':
        case 'success':
          return primaryColor;
        default:
          return 'rgba(230, 230, 230, 1)'
      }
    } else if (step === 2) {
      switch (clinic?.onboardingStep) {
        case 'profile':
          return primaryColor;
        case 'help-support':
          return 'rgba(230, 230, 230, 1)'
        case 'clinic-setup':
        case 'success':
          return primaryColor;
        default:
          return 'rgba(230, 230, 230, 1)'
      }
    } else if (step === 3 && clinic?.onboardingStep === 'success') {
      return primaryColor;
    } else {
      return 'rgba(230, 230, 230, 1)';
    }
  }

  const getBackBackgroundColor = () => {
    const step = currentStep();
    if (step === 1) {
      return 'rgba(230, 230, 230, 1)';
    } else {
      return primaryColor;
    }
  }

  const handlePrevClick = () => {
    console.log('currentStep prev', currentStep());
    if (currentStep() === 2) {
     if (clinic?.onboardingStep === 'help-support' || clinic?.onboardingStep === 'clinic-setup' || clinic?.onboardingStep === 'success'){
        history.push("/onboarding");
      }
    }
    else if (currentStep() === 3) {
      if (clinic?.onboardingStep === 'clinic-setup' || clinic?.onboardingStep === 'success'){
        history.push("/onboarding/help-support");
      }
    }
  };

  const handleNextClick = () => {
    console.log('currentStep', currentStep());

    if (currentStep() === 1) {
      if (clinic?.onboardingStep === 'help-support' || clinic?.onboardingStep === 'clinic-setup' || clinic?.onboardingStep === 'success') {
        history.push("/onboarding/help-support");
      }
    }
    else if (currentStep() === 2) {
     if (clinic?.onboardingStep === 'clinic-setup' || clinic?.onboardingStep === 'success') {
        history.push("/onboarding/clinic-setup");
      }
    }
    else if (currentStep() === 3) {
      if (clinic?.onboardingStep === 'success') {
        history.push("/onboarding/success");
      }
    }
  };
  const step = currentStep();
  return (
    <div >
      <Row className="step-layout " style={{ display: "flex", flexDirection: "row" }} >
        <Col xs={24} sm={24} md={24} lg={24} xl={24} className="new-onboarding-container" >
          <Progress
            percent={calculateProgress()}
            showInfo={false}
            strokeColor={primaryColor}
            style={{ height: "5px" }}
            className="progress-bar"
          />
          {currentStep() !== 4 && (
            <Row justify="space-between" align="middle" style={{ paddingInline: "50px", height: "16%" }}>
              <Col span={12} style={{
                marginTop: "25px",
                alignSelf: "self-start"
              }}>
                <p style={{ margin: 0, color: "#77888B", fontSize: '24px', textAlign: 'left', fontWeight: '400', fontFamily: "Poppins" }}>{heading}</p>
              </Col>
              <Col span={2} align="end">
                <img src={image} alt="" className="right-corner-img" />
              </Col>
            </Row>
          )}
          <Row
          >
            <Col style={{ width: '100%', }}>
              {children}
            </Col>
          </Row>

        </Col>
        <Col style={{ position: "relative ", width: "100%" }}>
          {currentStep() !== 4 && (
            <Row justify="center" style={{ background: tagBackgroundColor, height: "60px", width: "100%" }}>
              <Col span={23} align="end" style={{ alignContent: "center" }}>
                <Button
                  type="default"
                  icon={<LeftOutlined />}
                  onClick={handlePrevClick}
                  disabled={getBackBackgroundColor() === 'rgba(230, 230, 230, 1)'}
                  style={{
                    marginRight: "10px",
                    borderRadius: "100%",
                    backgroundColor: getBackBackgroundColor(), // Previous button red on last screen, both on second and third
                    color: "white",
                    borderColor: "transparent"
                  }}
                />
                <Button
                  type="default"
                  icon={<RightOutlined />}
                  disabled={getNextBackgroundColor() === 'rgba(230, 230, 230, 1)'}
                  style={{
                    borderRadius: "100%",
                    backgroundColor: getNextBackgroundColor(), // Next button red on first, second, and third screens
                    color: "white",
                    borderColor: "transparent"
                  }}
                  onClick={handleNextClick}
                />
              </Col>
            </Row>

          )}
        </Col>
      </Row>
    </div>
  );
};

export default Onboarding;
