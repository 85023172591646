import { useState, useEffect } from "react";
import { Table, Tag, Space, Input, Row, message , Col, Tooltip, Button} from "antd";
import { downloadButton } from "../shared/downloadButton";
import { DownloadOutlined , EyeOutlined} from "@ant-design/icons";
import moment from "moment";
import "../../assets/styles/components/invoiceList.scss";
import FilterDataRangePicker from "../shared/filterDataRangePicker";
import { FileOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { downloadPatientInvoiceAPI } from "api/patient";
import { companyType } from "colors-config";
import LabInvoiceModal from "./LabInvoiceModal/components/LabInvoiceModal";
import { columnTitleElement } from "utilities/commonMethod";
import { calculateProductsTotal, getProductServiceName, getProductServiceNames, getProductServiceTotalQuantity } from "utilities/treatment";


const InvoiceListComponent = ({
  paginationLimit,
  invoiceList,
  paginationPage,
  onSearch,
  onPagination,
  handleDateFilter,
  currentMenuSelected,
  fromDate,
  endDate,
  totalLabOrders,
  totalPatientsOrders
  //   totalOrders,
}) => {
  const [tableData, setTableData] = useState([]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [showInvoiceModal,setShowInvoiceModal] = useState(false)
  const [orderInfo,setOrderInfo] = useState(null)

  useEffect(() => {
    if (invoiceList) {
      setTableData(createTableData());
    }
  }, [invoiceList]);

  const createTableData = () => {
    return invoiceList.map((dataObj, index) => {
      return {
        key: index,
        shippingFiles: dataObj?.shippingFiles || [],
        invoiceNo: dataObj.invoice_id ? dataObj.invoice_id : "Not Available",
        invoiceDate: dataObj.invoice_date
          ? moment(dataObj.invoice_date).format("DD/MM/YYYY")
          : "Not Available",
        patientId: dataObj.patient_id,
        patientName:
          dataObj.patient_first_name && dataObj.patient_last_name
            ? dataObj.patient_first_name + " " + dataObj.patient_last_name
            : "Not Available",
            productServiceName:  getProductServiceName(dataObj),
            productServiceNames:  getProductServiceNames(dataObj),
        paymentStatus: dataObj.payment_status
          ? dataObj.payment_status
          : "Not Available",
        amount: dataObj.free ? `${dataObj.currency_name} 0 (Free)` : dataObj.product_price
          ? dataObj.currency_name + " " + dataObj.product_price : "Not Available",
          totalPrice:  calculateProductsTotal(dataObj?.labProductList),
          quantity: getProductServiceTotalQuantity(dataObj),
         
      };
    });
  };

  const showModal = (event) => {
    console.log(event, "modal event");
  };


  const onDownloadAllClick = (shippingFiles) => {
    shippingFiles.forEach((file) => {
      downloadHandler(file);
    });
  };

  const downloadHandler = (file) => {
    const link = document.createElement("a");
    link.href = file.url;
    link.download = file.name;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let columns = [
    {
      title: columnTitleElement("INVOICE NO"),
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      width:170,
      render: (invoiceNo, DownloadOutlined) => {
        return (
          <div style={{ color: "#223032" }}>
            <FileOutlined style={{ marginRight: "5px", color: "#77888B" }} />
            {invoiceNo}
          </div>
        );
      },
    },
    {
      title: columnTitleElement("PATIENT NAME & ID"),
      dataIndex: "patientName",
      key: "patientName",
      render: (patientName, record) => {
        return (
          <div className="patient-name-tableData">
          <p>{patientName}</p>
          <p>{record.patientId}</p>
         </div>
        );
      },
    },
    {
      title: columnTitleElement("INVOICE DATE"),
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      filterDropdown: ({ confirm, clearFilters }) => (
        <FilterDataRangePicker
          clearFilters={clearFilters}
          confirm={confirm}
          handleDateFilter={handleDateFilter}
        />
      ),
    },

    {
      title: columnTitleElement("PRODUCT/SERVICE"),
      dataIndex: "productServiceName",
      key: "productServiceName",
    },
    {
      title: columnTitleElement("AMOUNT"),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: columnTitleElement("PAYMENT STATUS"),
      key: "paymentStatus",
      dataIndex: "paymentStatus",
      render: (paymentStatus) => (
        <>
          <Tag
            style={{
              borderRadius: "10px",
              paddingTop: "3px",
              paddingBottom: "3px",
              width: "130px",
              position: "relative",
            }}
            key={paymentStatus}
            onClick={showModal}
          >
            {paymentStatus}
            {/* <DownOutlined
              style={{
                position: "absolute",
                marginLeft: "60px",
                marginTop: "5px",
              }}
            /> */}
          </Tag>
        </>
      ),
    },
    {
      title: columnTitleElement("INVOICE"),
      dataIndex: "invoice",
      key: "invoice",
      render: () => downloadButton(),
    },
  ];

  if(companyType==5 || companyType== 1){
    columns = [
      {
        title: columnTitleElement("INVOICE NO"),
        dataIndex: "invoiceNo",
        key: "invoiceNo",
        width:170,
        render: (invoiceNo, DownloadOutlined) => {
          return (
            <div style={{ color: "#223032", display:"flex" }}>
              <FileOutlined style={{ marginRight: "5px", color: "#77888B" }} />
              {invoiceNo}
            </div>
          );
        },
      },
      {
        title: columnTitleElement("PATIENT NAME & ID"),
        dataIndex: "patientName",
        key: "patientName",
        render: (patientName, record) => {
          return (
            <div className="patient-name-tableData">
             <p>{patientName}</p>
             <p>{record.patientId}</p>
            </div>
          );
        },
      },
      {
        title: columnTitleElement("INVOICE DATE"),
        dataIndex: "invoiceDate",
        key: "invoiceDate",
        filtered: (fromDate && endDate) ? true : false,
        filterDropdown: ({ confirm, clearFilters }) => (
          <FilterDataRangePicker
            clearFilters={clearFilters}
            confirm={confirm}
            handleDateFilter={handleDateFilter}
          />
        ),
      },

      {
        title: columnTitleElement(currentMenuSelected === "labs"
          ? "LAB PRODUCT / SERVICE NAME"
          : "PRODUCT/SERVICE"),
        dataIndex: "productServiceName",
        key: "productServiceName",
        render: (productName, record) => {
          return (
            <Tooltip title={record.productServiceNames}>
            {productName}
          </Tooltip>
          );
        },
      },
      {
        title: columnTitleElement("QUANTITY"),
      
        dataIndex: "quantity",
        key: "quantity",
        className: currentMenuSelected === "labs" ? "" : "labCostHide",
      },
      {
        title: columnTitleElement("LAB TOTAL PRICE"),
        dataIndex: "totalPrice",
        key: "totalPrice",
        className: currentMenuSelected == "labs" ? "" : "labCostHide",
      },
      {
        title: columnTitleElement("PAYMENT STATUS"),
        key: "paymentStatus",
        dataIndex: "paymentStatus",
        render: (paymentStatus) => (
          <>
            <Tag
              style={{
                borderRadius: "10px",
                paddingTop: "3px",
                paddingBottom: "3px",
                width: "130px",
                position: "relative",
              }}
              key={paymentStatus}
              onClick={showModal}
            >
              {paymentStatus}
              {/* <DownOutlined
                style={{
                  position: "absolute",
                  marginLeft: "60px",
                  marginTop: "5px",
                }}
              /> */}
            </Tag>
          </>
        ),
      },
      {
        title: columnTitleElement("INVOICE"),
        dataIndex: "invoice",
        key: "invoice",
        render: (invoice, record) => {
          return (
            <>
              {currentMenuSelected === "labs" ? (
             <Row style={{ marginTop: "1rem" }}>
             <Col>
               {record?.shippingFiles?.length ? (
                 <div style={{ display: "flex", alignItems: "center" }}>
                   <Tooltip title="View">
                     <Button
                       onClick={() => {
                         setOrderInfo(record);
                         setShowInvoiceModal(true);
                       }}
                       className="invoice-viewBtn"
                       icon={
                         <EyeOutlined
                           style={{ fontSize: "17px", cursor: "pointer" }}
                         />
                       }
                     ></Button>
                   </Tooltip>
                   <Tooltip title="Download">
                     <Button
                       onClick={() => onDownloadAllClick(record?.shippingFiles)}
                       className="invoice-viewBtn"
                       icon={
                         <DownloadOutlined
                           style={{ fontSize: "17px", cursor: "pointer" }}
                         />
                       }
                     ></Button>
                   </Tooltip>
                 </div>
               ) : (
                 "--"
               )}
             </Col>
         
           </Row>
              ) : (
                <DownloadOutlined style={{ fontSize: "17px" }} onClick={() =>   downloadPdf("patient", record.invoiceNo)}/>
              )}
            </>
          );
        },
      },
    ];
  }
  

  const downloadPdf = async (type, id) => {
    setLoadingPdf(true);
    try {
      const res = await downloadPatientInvoiceAPI(type,id)
      const url = res.body.url;
      window.open(url);
    } catch (error) {
      console.error(error);
      message.error("Failed to download.Please try again!");
    } finally {
      setLoadingPdf(false);
    }
  };
  const paginationObj = {
    current: paginationPage,
    pageSize: paginationLimit,
    total:   currentMenuSelected === "patients"
    ? totalPatientsOrders
    : totalLabOrders,
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 20, 50],
  };

  return (
    <div style={{ width: "100%" }}>

      <Row justify="space-between" style={{ marginLeft: "1rem" }}></Row>
      <Table
       className="patient-list-table"
       style={{ width: "100%", overflow: "hidden", marginTop: "0.5rem" }}
        columns={columns}
        dataSource={tableData}
        pagination={paginationObj}
        loading={loadingPdf}
        onChange={onPagination}
        scroll={{ x: "max-content" }}
      />
         {
        showInvoiceModal && (
          <LabInvoiceModal
          showInvoiceModal={showInvoiceModal}
          setShowInvoiceModal={setShowInvoiceModal}
          orderInfo={orderInfo}
          setOrderInfo={setOrderInfo}
          />
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  lab: state.lab,
});

export default connect(mapStateToProps)(InvoiceListComponent);