import { useEffect, useState, useContext } from "react";
import WeeklyAvailabilityForm from "./WeeklyAvailability/WeeklyAvailabilityForm";
import { validateWorkHoursStart, validateWorDay } from "utilities/appointment";
// import { weeklyAvailabilityInitialMapper } from "utilities/patientDetail";
import dayjs from "dayjs";
import { Form, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import FormFooter from "../../shared/FormFooter/FormFooter";
import AppointmentsView from "./AppointmentsView/AppointmentsView";
import {
  getClinicAppointmentsSelector,
  clinicIdSelector,
  getClinicMeetingDurationListSelector,
  getClinicAppointmentTypesSelector
} from "services/redux/selectors/clinicSelectors";
import {
  mapClinicAppointmentsData,
  mapPreviousAppointmentData,
} from "utilities/settings";
import OffAppointment from "./OffAppointment/OffAppointment";
import { updateClinicAppointmentsAPI } from "api/settingsApi";
import { updateClinicAppointmentsAction, updateClinicCommonAppointmentTypeAction } from "services/redux/actions/clinicActions";
import AvailabilityView from "./AvailabilityView/AvailabilityView";
import AppSettingsContext from "Context/useAppContext";

const Appointments = () => {
  const dispatch = useDispatch();
  const clinicAppointmentTypesList = useSelector(getClinicAppointmentTypesSelector);
  const clinicAppointments = useSelector(getClinicAppointmentsSelector);
  const clinicDurationList = useSelector(getClinicMeetingDurationListSelector);
  const [clinicAvailability, setClinicAvailability] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewAppointmentsList, setViewAppointmentsList] = useState([]);
  const clinicId = useSelector(clinicIdSelector);
  const [fields, setFields] = useState([]);
  const [mode, setMode] = useState("view");
  const [editAvailability, setEditAvailability] = useState(false)

  const [availabilityForm] = Form.useForm();

  const { setClinicConfig } = useContext(AppSettingsContext);


  useEffect(() => {
    const formData = mapClinicAppointmentsData(clinicAppointments);
    // console.log("formDataformDataformData", formData)
    setFields(formData.weekTimings);
    setClinicAvailability(formData?.bookAppointmentApp);
    setViewAppointmentsList(clinicAppointments?.appointmentsList);

    availabilityForm.setFieldsValue(formData);
  }, [clinicAppointments, availabilityForm]);




  const availabilitySubmit = (values) => {
    const workDayCheck = validateWorDay(values);
    if (!workDayCheck && clinicAvailability) {
      return message.error(
        "To enable this section, please schedule appointments for at least one day."
      );
    }
    const isValid = validateWorkHoursStart(values);
    if (clinicAvailability && !isValid) {
      return message.error(
        "Work hours must be specified for each working day."
      );
    }
    if (clinicAvailability && !viewAppointmentsList.length) {
      return message.error(
        "To enable this section, please add one at least appointment type."
      );
    }
    const workingHours = values.weekTimings.map(
      ({ name, workDay, workHours, breakHours }) => {
        const extractedHours = workHours.reduce((acc, { start }) => {
          if (start !== null) {
            const formattedStart = start.map((date) =>
              dayjs(date).format("HH:mm")
            );
            acc.push(formattedStart);
          } else {
            acc.push([]);
          }
          return acc;
        }, []);
        const formattedBreakHours = breakHours
          ? breakHours.map((date) => dayjs(date).format("HH:mm"))
          : [];
        return {
          name,
          workDay,
          workHours: extractedHours,
          breakHours: formattedBreakHours,
        };
      }
    );
    let appointmentsData;
    const clinicAppointments = [...viewAppointmentsList];
    //  console.log("clinicPreviousAppointments", clinicPreviousAppointments)
    appointmentsData = clinicAppointments.map((appointment) =>
      mapPreviousAppointmentData(appointment)
    );
    // console.log("appointmentsData", appointmentsData);

    let finalValues = {
      timezone: values.timezone,
      availablityHours: workingHours,
      dayoffList: values.daysOffList.map((date) => date.format("YYYY-MM-DD")),
      bookAppointmentApp: clinicAvailability,
      appointmentList: appointmentsData,
    };
    // console.log("finalValuesfinalValues", finalValues);
    setClinicConfig(prev=> ({...prev, appointment: clinicAvailability}))
    onSubmit(finalValues);
  };
  const onOk = () => {
    availabilityForm.submit();
  };


  const dispatcherFunction = (data) =>{
    const clinicAppointmentNamesList = [...clinicAppointmentTypesList]
    data.forEach((item) => {
      const { appointment_type_id, appointment_name_id, appointment_name } = item;
      const clinicType = clinicAppointmentNamesList.find(
        (type) => type.clinic_appointment_type_id === appointment_type_id
      );
    
      if (clinicType) {
        // Check if the appointment_name_id already exists in appointmentNamesList
        const exists = clinicType.appointmentNamesList.some(
          (appointment) => appointment.clinic_appointment_type_name_id === appointment_name_id
        );
        // If it doesn't exist, add it
        if (!exists) {
          clinicType.appointmentNamesList.push({
            clinic_appointment_type_name_id: appointment_name_id,
            clinic_appointment_name: appointment_name,
          });
        }
      }
    });
     dispatch(updateClinicCommonAppointmentTypeAction(clinicAppointmentNamesList)) 
  }
  const onSubmit = async (payload) => {
    try {
      setLoading(true);
      const { data } = await updateClinicAppointmentsAPI(payload, clinicId);
      dispatcherFunction(data?.data?.appointmentsList)
      message.success("Clinic appointments updated");
      dispatch(updateClinicAppointmentsAction(data.data));
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      setMode("view")
      setEditAvailability(false)
    }
  };

const onAvailabilityChange = () =>{
  setClinicAvailability(true);
  setEditAvailability(true)
  setMode("edit")
  availabilityForm.setFieldValue("bookAppointmentApp", true);
}
  const onCancel = () => {
    setMode("view")
    setEditAvailability(false)
  };
  // console.log("clinicAvailability", clinicAvailability)
  return (
    <div>
      {editAvailability ? (
        <>
          <WeeklyAvailabilityForm
            onFinish={availabilitySubmit}
            availabilityForm={availabilityForm}
            userWeeklyAvailability={clinicAppointments?.availabilityList}
            fields={fields}
            clinicAvailability={clinicAvailability}
            setFields={setFields}
            setClinicAvailability={setClinicAvailability}
            mode={mode}
            setMode={setMode}
          />

          <AppointmentsView
            viewAppointmentsList={viewAppointmentsList}
            setViewAppointmentsList={setViewAppointmentsList}
            clinicDurationList={clinicDurationList}
            clinicAppointments={clinicAppointments}
            mode={mode}
            clinicAvailability={clinicAvailability}
          />
          {
            mode !== "view" ? <FormFooter
            onCancelClick={onCancel}
            onOkClick={onOk}
            loading={loading}
          /> :  null
          }
      
        </>
      ) : (
        clinicAvailability ?
        <AvailabilityView clinicAppointments={clinicAppointments}  clinicDurationList={clinicDurationList} setEditAvailability={setEditAvailability} setMode={setMode}/>  :
        <OffAppointment onAvailabilityChange={onAvailabilityChange} /> 
      )}

      
    </div>
  );
};

export default Appointments;
