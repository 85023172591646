import { Row, Col, Divider } from "antd";
import { getPatientCountryCodesList } from "services/redux/selectors/clinicSelectors";
import { useSelector } from "react-redux";
import styles from "./view.module.scss";
import AppButton from "components/shared/AppButton/AppButton";
import SupportUserIcon from "components/shared/Icon/SupportUser";
import SupportPhone from "components/shared/Icon/SupportPhone";
import ClinicInfoDisplay from "components/shared/ClinicInfoDisplay/ClinicInfoDisplay";
import FormLabel from "components/shared/FormLabel/FormLabel";
import { getClinicFlag } from "utilities/settings";

const ProfileView = ({ setEditProfile, clinicProfile }) => {
  const countryCodes = useSelector(getPatientCountryCodesList);

  return (
    <div className={styles["profile-view-container"]}>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles["heading"]}>Clinician Profile</h2>
        </Col>
        <Col>
          <AppButton
            type="primary"
            shape="round"
            style={{ padding: "0 40px", height: "35px" }}
            onClick={() => setEditProfile(true)}
          >
            Edit
          </AppButton>
        </Col>
      </Row>
      <Row align="middle">
        <Col lg={18} md={18} sm={24} className={styles["info-col"]}>
          <div className={styles["form-info"]}>
            <FormLabel label="Clinician Name" />
            <ClinicInfoDisplay value={clinicProfile?.clinic_name} />
          </div>
          <div className={styles["form-info"]}>
            <FormLabel label="Phone Number" />

            <ClinicInfoDisplay
              value={
                <>
                  {getClinicFlag(
                    clinicProfile?.country_code_name,
                    countryCodes
                  )}
                  {` ${clinicProfile?.country_code_name || ""}-${
                    clinicProfile?.clinic_contact || ""
                  }`}
                </>
              }
            />
          </div>
          <div className={styles["form-info"]}>
            <FormLabel label="Email" />
            <ClinicInfoDisplay value={clinicProfile?.clinic_email} />
          </div>
        </Col>
        <Col lg={6} md={6} sm={24} >
          <div className={styles["form-user-icon"]}>
            <SupportUserIcon />
          </div>
        </Col>
      </Row>
      <Divider />
      <Row align="top">
        <Col lg={18} md={18} sm={24} >
          <div className={styles["form-info"]}>
            <FormLabel label="Help & Support Phone Number" />
            <ClinicInfoDisplay
              value={
                <>
                  {getClinicFlag(
                    clinicProfile?.supportPrefix,
                    countryCodes
                  )}
                  {`  ${clinicProfile?.supportPrefix || ""}-${
                    clinicProfile?.supportNumber || ""
                  }`}
                </>
              }
            />
          </div>
          <div className={styles["form-info"]}>
            <FormLabel label="Help & Support Email" />
            <ClinicInfoDisplay value={clinicProfile?.supportEmail} />
          </div>
        </Col>
        <Col lg={6} md={6} sm={24} >
          <div className={styles["form-user-phone"]}>
            <SupportPhone />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ProfileView;
