import {
  Form,
  Row,
  Col,
  Switch,
  Alert,
  Button,
  Popconfirm,
  Input,
  Divider,
} from "antd";
import { ReactComponent as BulbIcon } from "assets/images/BulbIcon.svg";
import {
  paymentStripeMessage,
  paymentThirdPartyMessage,
} from "messages/messages";
import styles from "./form.module.scss";
import { DeleteOutlined } from "@ant-design/icons";
import { ReactComponent as PayPhone } from "assets/images/PayPhone.svg";
import StripeButton from "components/shared/StripeButton/StripeButton";
import { v4 as uuidv4 } from "uuid";
import { PaymentTypes } from "components/shared/modals/PaymentSettings/constants";
import { useState } from "react";
import { tagBackgroundColor } from "colors-config";

const PaymentsForm = ({ onFinish, paymentsForm, clinicId, receiveAppPayment, stripeEnabled }) => {
  const [receiveAppPaymentCheck, setReceiveAppPayment]=useState(receiveAppPayment)
  return (
    <div className={styles["payment-form-container"]}>
        <Row align="middle" justify="space-between">
        <Col>
          <h2 className={styles["heading"]}>Online Payments</h2>
        </Col>
      </Row>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        form={paymentsForm}
        layout="vertical"
      >
        <Row
          align="middle"
          justify="end"
          style={{ margin: "30px 0px" }}
        >
          <Col>
            <div className={styles["book-appointment"]} style={{background: tagBackgroundColor}}>
              <label>Receive Payments through Patient App</label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Item name="receiveAppPayment" valuePropName="checked">
                  <Switch
                    style={{
                      marginLeft: "10px",
                    }}
                    onClick={(value)=>setReceiveAppPayment(value)}
                  />
                </Form.Item>
                <span
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  {receiveAppPaymentCheck ? "Yes" : "No"}
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <div style={{margin:"20px 0px"}}>
        <Row align="middle" gutter={[16,16]}>
          <Col>
            <Alert
              className={styles["treatment-view-info"]}
              message={paymentStripeMessage}
              type="info"
              showIcon
              icon={<BulbIcon />}
            />
          </Col>
          <Col >
            <StripeButton
              stripeConnected={stripeEnabled}
              clinicId={clinicId}
            />
          </Col>
        </Row>
        </div>
      

        <Row align="bottom">
          <Col lg={20}>
            <Form.List name="clinicPayments">
              {(fields, { add, remove }) => (
                <>
                  <div style={{ padding: "10px 15px" }}>
                    {fields.map(({ key, name, fieldKey, ...restField }) => {
                      const paymentType = paymentsForm.getFieldValue([
                        "clinicPayments",
                        name,
                        "type",
                      ]);
                      return (
                        <div key={key} style={{marginTop:"8px"}}>
                          <Row
                            gutter={[32, 32]}
                            align="middle"
                            style={{
                              opacity:
                                paymentType === PaymentTypes.STRIPE ? 0.5 : 1,
                            }}
                          >
                            <Col lg={4}>
                              <label>Payment Type:</label>
                            </Col>
                            <Col lg={12}>
                              <Form.Item
                                className={styles["required-input"]}
                                {...restField}
                                name={[name, "buttonText"]}
                                fieldKey={[fieldKey, "buttonText"]}
                                label=""
                                rules={[
                                  {
                                    required: true,
                                    message: "Button Text is required",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Payment Button Text"
                                  size="large"
                                  disabled={paymentType === PaymentTypes.STRIPE}
                                />
                              </Form.Item>
                            </Col>
                            <Col lg={6} offset={2}>
                              {paymentType === PaymentTypes.STRIPE ? null : (
                                <>
                                  {fields.length > 1 && (
                                    <Popconfirm
                                      title="Delete"
                                      description="Are you sure you want to delete this payment method?"
                                      okText="Yes"
                                      cancelText="No"
                                      onConfirm={e => {
                                        remove(name);
                                      }}
                                      onCancel={() => {}}
                                    >
                                      <DeleteOutlined
                                        style={{
                                          marginTop: -10,
                                          fontSize: "20px",
                                          float: "left",
                                          color: "#77849E",
                                        }}
                                      />
                                    </Popconfirm>
                                  )}
                                </>
                              )}
                            </Col>
                          </Row>
                          <Row
                            gutter={[32, 32]}
                            align="middle"
                            style={{
                              opacity:
                                paymentType === PaymentTypes.STRIPE ? 0.5 : 1,
                            }}
                          >
                            <Col lg={4}>
                              <label></label>
                            </Col>
                            <Col lg={12}>
                              {paymentType !== PaymentTypes.STRIPE && (
                                <Form.Item
                                  style={{ margin: "10px 0px" }}
                                  className={styles["required-input"]}
                                  {...restField}
                                  name={[name, "url"]}
                                  fieldKey={[fieldKey, "url"]}
                                  label=""
                                  rules={
                                    paymentType !== PaymentTypes.STRIPE
                                      ? [
                                          {
                                            required: true,
                                            message: "A URL is required",
                                            whitespace: true,
                                          },
                                          {
                                            type: "url",
                                            message:
                                              "This field must be a valid url (start with https://) and end with a domain.",
                                          },
                                        ]
                                      : null
                                  }
                                >
                                  <Input
                                    placeholder="Payment URL Link"
                                    size="large"
                                    disabled={
                                      paymentType === PaymentTypes.STRIPE
                                    }
                                  />
                                </Form.Item>
                              )}
                            </Col>
                          </Row>
                          <Row
                            gutter={[32, 32]}
                            align="middle"
                            style={{
                              opacity:
                                paymentType === PaymentTypes.STRIPE
                                  ? stripeEnabled
                                    ? 1
                                    : 0.5
                                  : 1,
                            }}
                          >
                            <Col lg={4}>
                              <label>Status:</label>
                            </Col>
                            <Col lg={16}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Form.Item
                                  // className={styles["required-input"]}
                                  {...restField}
                                  name={[name, "isActive"]}
                                  fieldKey={[fieldKey, "isActive"]}
                                  label=""
                                  valuePropName="checked"
                                >
                                  <Switch
                                    style={{
                                      marginLeft: "10px",
                                    }}
                                    disabled={
                                      paymentType === PaymentTypes.STRIPE
                                      ? stripeEnabled
                                        ? false
                                        : true
                                      : false
                                    }
                                  />
                                </Form.Item>
                                <span style={{ marginLeft: "10px" }}>
                                  Active
                                </span>
                              </div>
                            </Col>
                          </Row>
                          <Row
                            gutter={[32, 32]}
                            align="middle"
                            style={{
                              opacity:
                                paymentType === PaymentTypes.STRIPE
                                  ? stripeEnabled
                                    ? 1
                                    : 0.5
                                  : 1,
                            }}
                          >
                            <Col lg={4}>
                              <label></label>
                            </Col>
                            <Col lg={16}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Form.Item
                                  // className={styles["required-input"]}
                                  {...restField}
                                  name={[name, "default"]}
                                  fieldKey={[fieldKey, "default"]}
                                  label=""
                                  valuePropName="checked"
                                >
                                  <Switch
                                    style={{
                                      marginLeft: "10px",
                                    }}
                                    disabled={
                                      paymentType === PaymentTypes.STRIPE
                                      ? stripeEnabled
                                        ? false
                                        : true
                                      : false
                                    }
                                  />
                                </Form.Item>
                                <span style={{ marginLeft: "10px" }}>
                                  Default For All Products & Services
                                </span>
                              </div>
                            </Col>
                          </Row>
                          {
                             paymentType === PaymentTypes.STRIPE ? <><Divider /></>  : null
                            }
                          {name === 0 ? (
                            <div style={{ margin: "20px 0px" }}>
                              <Alert
                                className={styles["treatment-view-info"]}
                                message={paymentThirdPartyMessage}
                                type="info"
                                showIcon
                                icon={<BulbIcon />}
                              />
                            </div>
                          ) : null}
                  
                        </div>
                      );
                    })}
                    <Row>
                      <Col lg={{ span: 6, offset: 14}} md={{ span: 6, offset: 0 }}>
                        <Button
                          shape="round"
                           style={{ marginTop: "10px" }}
                          className="addOn-btn"
                          onClick={() => {
                            add({
                              isActive: true,
                              id: `(New)${uuidv4()}`
                            });
                          }}
                        >
                          Add another 3rd Party Payment
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </Form.List>
          </Col>
          <Col lg={4}>
            <PayPhone />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default PaymentsForm;
