import {
  Form,
  Row,
  Col,
  Switch,
  TimePicker,
  Button,
  Input,
  Tooltip,
  Select,
  DatePicker,
} from "antd";
import dayjs from "dayjs";
// import AppointmentsView from "../AppointmentsView/AppointmentsView";
import { ReactComponent as PlusOutlinedGray } from "assets/images/plusOutlinedGray.svg";
import { ReactComponent as RollBack } from "assets/images/RollBack.svg";
import { ReactComponent as Clock } from "assets/images/Clock.svg";
import { DeleteOutlined } from "@ant-design/icons";
// import { availabilityInfoMessage } from "messages/messages";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
// import { InfoIcon } from "components/shared/InfoIcon/index";

import timezoneList from "./timezonelist.json";

import { primaryColor, tagBackgroundColor } from "colors-config";
import "./availability.scss";
import { disablePastDates } from "utilities/commonMethod";

const { RangePicker } = TimePicker;
const { Option } = Select;
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const WeeklyAvailability = ({
  onFinish,
  availabilityForm,
  mode,
  userWeeklyAvailability,
  timezone,
  resetClinicAvailabilityForm,
  clinicAvailability,
  prevUserAvailabilityForm,
  fields,setFields,
  setClinicAvailability,
  setMode
}) => {
  const handleAddWorkHours = (index) => {
    const updatedFields = fields?.map((day, dayIndex) => {
      if (dayIndex === index) {
        return {
          ...day,
          workHours: [...day.workHours, { start: null }],
        };
      }
      return day;
    });
    setFields(updatedFields);
    availabilityForm.setFieldsValue({ weekTimings: updatedFields });
  };

  const handleRemoveWorkHours = (dayIndex, workHourIndex, currentFields) => {
    const updatedFields = currentFields.map((day, dIndex) => {
      if (dayIndex === dIndex) {
        let dayCopy = { ...day };

        dayCopy = {
          ...dayCopy,
          workHours: dayCopy.workHours.filter(
            (work, wIndex) => wIndex !== workHourIndex
          ),
        };

        return dayCopy;
      }
      return day;
    });
    setFields(updatedFields);
    availabilityForm.setFieldsValue({ weekTimings: updatedFields });
  };

  const handleChangeWorkHour = (dayIndex, workHourIndex, value, field) => {
    const updatedFields = [...fields];
    updatedFields[dayIndex].workHours[workHourIndex][field] = value;
    setFields(updatedFields);
    availabilityForm.setFieldsValue({ weekTimings: updatedFields });
  };

  const handleWorkDayChange = (dayIndex, checked) => {
    const updatedFields = [...fields];
    updatedFields[dayIndex].workDay = checked;
    setFields(updatedFields);
  };
  const onBreakHoursRepeatClick = (dayIndex, value) => {
    const timing = value ? value.map((date) => dayjs(date, "HH:mm")) : [];

    const updatedFields = fields.map((day) => ({
      ...day,
      breakHours: timing,
    }));
    setFields(updatedFields);
    availabilityForm.setFieldsValue({ weekTimings: updatedFields });
  };

  const onWorkHoursRepeatClick = (dayIndex) => {
    const updatedFields = [...fields];

    const timing = updatedFields[dayIndex].workHours;

    const updatedFieldsFinal = fields.map((day) => ({
      ...day,
      workHours: timing,
    }));

    setFields(updatedFieldsFinal);
    availabilityForm.setFieldsValue({ weekTimings: updatedFieldsFinal });
  };

  const handleChangeBreakHour = (dayIndex, value) => {
    // console.log("value",value);
    const updatedFields = [...fields];
    updatedFields[dayIndex].breakHours = value;
    setFields([...updatedFields]);
    availabilityForm.setFieldsValue({ weekTimings: updatedFields });
  };

  function checkExceedingBreakHours(arr, breakHours) {
    const [breakStart, breakEnd] = breakHours;
    const isValid = arr.some((workHour) => {
      const [startTime, endTime] = workHour.start;
      return (
        breakStart.isSameOrAfter(startTime) && breakEnd.isSameOrBefore(endTime)
      );
    });
    return isValid;
  }

  const validateWorkHours = (workHours, breakHours, allWorkHours, _) => {
    if (!clinicAvailability) {
      return Promise.resolve();
    }
    if (!workHours || !breakHours) return Promise.resolve();
    const [workStart, workEnd] = workHours;
    const [breakStart, breakEnd] = breakHours;
    if (!breakStart && !breakEnd) {
      return Promise.resolve();
    }
    if (!_.workDay) {
      return Promise.resolve();
    }
    const checkExceeding = checkExceedingBreakHours(allWorkHours,breakHours)
    const isValid =
      (!breakStart && !breakEnd) || // No break hours selected
      checkExceeding;
      // workStart >= breakEnd ||
      // workEnd <= breakStart; // No overlap
    return isValid
      ? Promise.resolve()
      : Promise.reject("Break hours are not within work hours");
  };

  // Custom validation function for checking matching hours
  const validateMatchingHours = (workHours, breakHours) => {
    if (!clinicAvailability) {
      return Promise.resolve();
    }
    if (!workHours || !breakHours) {
      return Promise.resolve();
    }
    if (!workHours.length || !breakHours.length) return Promise.resolve();
    const [workStart, workEnd] = workHours;
    const [breakStart, breakEnd] = breakHours;
    const isValid = workStart !== breakStart || workEnd !== breakEnd;
    return isValid
      ? Promise.resolve()
      : Promise.reject("Work hours cannot match break hours");
  };

  function checkOverlap(arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        if (arr[i].start && arr[j].start) {
          const range1 = arr[i].start;
          const range2 = arr[j].start;

          const start1 = dayjs(range1[0], "HH:mm");
          const end1 = dayjs(range1[1], "HH:mm");
          const start2 = dayjs(range2[0], "HH:mm");
          const end2 = dayjs(range2[1], "HH:mm");

          // Check for overlap
          if (
            (start1.isBefore(end2) && start1.isSameOrAfter(start2)) ||
            (end1.isAfter(start2) && end1.isSameOrBefore(end2)) ||
            (start1.isSameOrBefore(start2) && end1.isSameOrAfter(end2))
          ) {
            return true; // Overlap found
          }
        }
      }
    }
    return false; // No overlap found
  }

  const validateOverlapWorkHours = (value, allWorkHours) => {
    if (!clinicAvailability) {
      return Promise.resolve();
    }
    if (!value || !allWorkHours) return Promise.resolve();
    // const [newValueStart, newValueEnd] = value;
    // Convert value to Day.js objects
    // const valueStart = dayjs(newValueStart, "HH:mm");
    // const valueEnd = dayjs(newValueEnd, "HH:mm");
    // for (const workHour of allWorkHours) {
    //   if (!workHour.start) continue;
    //   const [existingStart, existingEnd] = workHour.start.map((time) =>
    //     dayjs(time, "HH:mm")
    //   );
    //   if (
    //     (valueStart.isAfter(existingStart) &&
    //       valueStart.isBefore(existingEnd)) ||
    //     (valueEnd.isAfter(existingStart) && valueEnd.isBefore(existingEnd)) ||
    //     (valueStart.isBefore(existingStart) && valueEnd.isAfter(existingEnd)||
    //     (valueStart.isSame(existingStart) && valueEnd.isSame(existingEnd))
    //   )

    //   ) {
    //     return Promise.reject("Work hours cannot overlap or be the same as each other");
    //   }
    // }

    if (checkOverlap(allWorkHours)) {
      return Promise.reject(
        "Work hours cannot overlap or be the same as each other"
      );
    }

    return Promise.resolve();
  };


  const onChangeConfirm = (checked) => {
   
     setClinicAvailability(checked);
    // setClinicDetails({
    //   ...clinicDetails,
    //   clinicAvailability: checked,
    // });
  };

  // console.log("clinicAvailability", clinicAvailability)

  return (
    <div className="availability-container">
      <Row align="middle" justify="space-between">
     <Col>
     <h1>Appointments</h1>
     </Col>
      </Row>
      
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        // initialValues={{ weekTimings: fields }}
        form={availabilityForm}
        layout="vertical"
      >
        <Row
          align="middle"
          justify="space-between"
          style={{ margin: "30px 0px" }}
          gutter={[4,16]}
        >
          <Col md={6}>
            <Form.Item
              name="timezone"
              rules={[
                {
                  required: true,
                  message: "Please select timeZone",
                },
              ]}
              label="Time Zone"
            >
              <Select showSearch placeholder="Select Time Zone" size="large"
              disabled={mode ==="view"}
              >
                {timezoneList?.map((obj, index) => {
                  return (
                    <Option key={index} value={obj.tzCode}>
                      {obj.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col  offset={8} lg={{ span: 10, offset: 8 }} md={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }}>
            <div className="book-appointment" style={{background: tagBackgroundColor}}>
              <label>Book Appointments through this portal?</label>
              <div style={{ display: "flex", alignItems:"center" }}>
                <Form.Item
                  label=""
                  name="bookAppointmentApp"
                  valuePropName="checked"
                >
                  <Switch  
                    disabled={mode ==="view"}
                   checked={clinicAvailability}
                  onChange={onChangeConfirm}/>
                </Form.Item>
                <span style={{ marginLeft: "10px" }}>Yes</span>
              </div>
            </div>
          </Col>
        </Row>
        <div className="avail-form-container">
          <Row align="middle" justify="space-between" gutter={[32, 32]}>
            <Col lg={4}>
              <p className="form-title first-title">Availability</p>
            </Col>
            <Col lg={10}>
              <p className="form-title">Working Hours</p>
            </Col>
            <Col lg={10}>
              <p className="form-title">Break Hours</p>
            </Col>
          </Row>
          {fields?.map((day, dayIndex) => (
            <Row
              key={dayIndex}
              gutter={[32, 32]}
              align="top"
              style={{ marginTop: "15px" }}
            >
              <Col lg={4}>
                <div className="avail-col-one">
                  <Form.Item
                    name={["weekTimings", dayIndex, "name"]}
                    initialValue={day.name}
                    rules={[
                      { required: true, message: "Please input a name!" },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    name={["weekTimings", dayIndex, "workDay"]}
                    valuePropName="checked"
                  >
                    <Switch
                      disabled={!clinicAvailability}
                      onChange={(checked) =>
                        handleWorkDayChange(dayIndex, checked)
                      }
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col lg={10}>
                <div className="timings-row-container" style={{flexDirection:"column"}}>
                  {day?.workHours?.map((workHour, workHourIndex) => (
                    <div key={workHourIndex} className="timings-row" style={{display:"flex"}}>
                      <Form.Item
                        name={[
                          "weekTimings",
                          dayIndex,
                          "workHours",
                          workHourIndex,
                          "start",
                        ]}
                        rules={[
                          {
                            validator: (_, value) =>
                              validateOverlapWorkHours(value, day.workHours),
                          },
                        
                          {
                            validator: (_, value) =>
                              validateWorkHours(
                                value,
                                day.breakHours,
                                day.workHours,
                                day
                              ),
                          },
                          {
                            validator: (_, value) =>
                              validateMatchingHours(value, day.breakHours),
                          },
                        ]}
                      >
                        <RangePicker
                          format="HH:mm"
                          size="large"
                          placeholder={["Opening Time", "Closing Time"]}
                          onCalendarChange={(dates, dateStrings, info) => {
                            handleChangeWorkHour(
                              dayIndex,
                              workHourIndex,
                              dates,
                              "start"
                            );
                          }}
                          // onChange={(value) =>
                          //   handleChangeWorkHour(
                          //     dayIndex,
                          //     workHourIndex,
                          //     value,
                          //     "start"
                          //   )
                          // }
                          disabled={
                            mode === "view" ||
                            !day.workDay ||
                            !clinicAvailability
                          }
                          needConfirm={false}
                          suffixIcon={<Clock fill={primaryColor} />}
                          style={{width:"85%"}}
                        />
                      </Form.Item>
                      {day.workHours.length > 1 && (
                        <Button
                          className="avail-add-btn"
                          icon={<DeleteOutlined />}
                          style={{ margin: "5px 5px 0px 5px" }}
                          onClick={() =>
                            handleRemoveWorkHours(
                              dayIndex,
                              workHourIndex,
                              fields
                            )
                          }
                          disabled={
                            mode === "view" ||
                            !day.workDay ||
                            !clinicAvailability
                          }
                        ></Button>
                      )}
                    </div>
                  ))}
                  <div
                    className="buttons-row"
                    style={{position:"absolute", right:"0px"}}
                    //  style={{gap :  mode === "view" ? "20px" : day.workDay ? "5px" :"20px",
                    //  right: mode === "view" ? "-15px" : day.workDay ? "-21px" :"-20px"}}
                  >
                    <Tooltip title={"Copy this time schedule to all days"}>
                      <Button
                        className="avail-add-btn"
                        icon={<RollBack />}
                        // style={{ margin: "0 5px" }}
                        disabled={
                         !day.workDay || !clinicAvailability
                        }
                        onClick={() => onWorkHoursRepeatClick(dayIndex)}
                      ></Button>
                    </Tooltip>
                    <Tooltip
                      title={
                        "Add an extra time slot for your clinic's open hours if there's a break in-between."
                      }
                    >
                      <Button
                           disabled={
                            mode === "view" ||
                            !day.workDay ||
                            !clinicAvailability
                          }
                        className="avail-add-btn"
                        onClick={() => handleAddWorkHours(dayIndex)}
                        // style={{ marginRight: 8 }}
                  
                        icon={<PlusOutlinedGray />}
                      ></Button>
                    </Tooltip>
                  </div>
                </div>
              </Col>
              <Col lg={10}>
                <div className="breakHours-row">
                  <Form.Item
                    name={["weekTimings", dayIndex, "breakHours"]}
                    initialValue={day.breakHours}
                  >
                    <RangePicker
                      format="HH:mm"
                      size="large"
                      placeholder={["Start Time", "End Time"]}
                      disabled={
                        mode === "view" ||
                        !day.workDay ||
                        !clinicAvailability
                      }
                      suffixIcon={<Clock fill={primaryColor} />}
                      onCalendarChange={(dates, dateStrings, info) => {
                        handleChangeBreakHour(dayIndex, dates);
                      }}

                      // onChange={(value) =>
                      //   handleChangeBreakHour(dayIndex, value)
                      // }
                      style={{width:"100%"}}
                      needConfirm={false}
                    />
                  </Form.Item>
                  <div className="break-row">
                    <Tooltip title={"Copy this time schedule to all days"}>
                      <Button
                        className="avail-add-btn"
                        icon={<RollBack />}
                        // style={{ margin: "0 5px" }}
                        disabled={
                          mode === "view" ||
                          !day.workDay ||
                          !clinicAvailability
                        }
                        onClick={() =>
                          onBreakHoursRepeatClick(dayIndex, day.breakHours)
                        }
                      ></Button>
                    </Tooltip>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
        </div>
        <p className="form-title">Days Off</p>
        <Form.Item name="daysOffList" label="">
          <DatePicker
             disabled={
              mode === "view" ||
              !clinicAvailability
            }
            style={{ width: "100p%" }}
            multiple
            disabledDate={disablePastDates}
            maxTagCount="responsive"
            size="large"
          />
        </Form.Item>
      </Form>
      {/* <AppointmentsView appointmentsList={appointmentsList}/> */}
    </div>
  );
};

export default WeeklyAvailability;
