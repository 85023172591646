import { useState , useRef} from "react";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  InputNumber,
  Checkbox,
  Divider,
  Button,
  message,
} from "antd";
import styles from "./productForm.module.scss";
import { getClinicCurrencySelector } from "services/redux/selectors/clinicSelectors";
import { useSelector } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import { primaryColor } from "colors-config";
const { TextArea } = Input;
const { Option } = Select;

const AddResourceModal = ({
  onProductSubmit,
  addProductForm,
  paymentList,
  clinicProductsList,
  setProductList,
  productList
}) => {
  const inputRef = useRef(null); // Create a ref for the input
  const clinicCurrenciesList = useSelector(getClinicCurrencySelector) || [];
  const [productName, setProductName] = useState("");
 

  const addItem = () => {
    let pName = productName.trim();
    if (pName === "") {
      return message.error("Please enter a product name");
    }
    const previousProducts = [...productList];
    const filteredProduct = previousProducts.filter(
      (obj) => obj.product_name.toLowerCase() === pName.toLowerCase()
    );

    if (filteredProduct.length) {
      return message.error("Product name already exists");
    }
    setProductList([
      ...productList,
      { product_name_id: new Date().getTime(), product_name: pName },
    ]);

    setProductName("");
    inputRef.current?.focus();
  };

  const handleProductTypeChange = (value) => {
    const selectedProductType = clinicProductsList.find(
      (item) => item.product_type === value
    );
    setProductList(selectedProductType?.productNamesList || []);
    addProductForm.setFieldsValue({
      productName: null
    });

  };

  const handleDiscountAmountChange = (value) => {
    if (value < 0 || value === null || value === undefined) {
      return message.error("Discount cannot be a negative number or empty.");
    }

    const price = addProductForm.getFieldValue("productPrice");
    if (!price) {
      return message.error(
        "Please provide the product price before applying a discount."
      );
    }

    const percentage = price ? (value / price) * 100 : 0;

    addProductForm.setFieldsValue({
      discountPercentage: percentage.toFixed(2),
      total: (price - value).toFixed(2),
    });
  };

  const handleDiscountPercentageChange = (value) => {
    if (value < 0 || value === null || value === undefined) {
      return message.error(
        "Discount percentage cannot be a negative number or empty."
      );
    }

    const price = addProductForm.getFieldValue("productPrice");
    if (!price) {
      return message.error(
        "Please provide the product price before applying a discount."
      );
    }

    const discount = price ? (value / 100) * price : 0;

    addProductForm.setFieldsValue({
      discount: discount.toFixed(2),
      total: (price - discount).toFixed(2),
    });
  };

  const handleFreeChange = (checked) => {
    if (checked) {
      addProductForm.setFieldsValue({
        discount: 0,
        total: 0,
        productPrice: 0,
        discountPercentage: 0,
      });
    }
  };

  const handlePriceChange = (value) => {
    if (value < 0 || value === null || value === undefined) {
      return message.error("Price cannot be negative or empty.");
    }
    if (value === 0) {
      return
    }

    const discountAmount = addProductForm.getFieldValue("discount");
    const discountPercentage =
      addProductForm.getFieldValue("discountPercentage");

    let newTotal = value;

    if (discountAmount) {
      newTotal = value - discountAmount;
      const newPercentage = (discountAmount / value) * 100;
      addProductForm.setFieldsValue({
        discountPercentage: newPercentage.toFixed(2),
      });
    } else if (discountPercentage) {
      const newDiscount = (discountPercentage / 100) * value;
      newTotal = value - newDiscount;
      addProductForm.setFieldsValue({
        discount: newDiscount.toFixed(2),
      });
    }

    addProductForm.setFieldsValue({
      total: newTotal.toFixed(2),
    });
  };

  return (
    <div>
      <Form
        form={addProductForm}
        layout="vertical"
        name="addResourceForm"
        onFinish={onProductSubmit}
      >
        <Row gutter={[16, 16]}>
          <Col lg={8} md={24} sm={24}>
            <p className={styles["form-label"]}>Type
              <span  className={styles["required-input"]}/>
            </p>
            <Form.Item
              name="productType"
              label=""
              rules={[
                {
                  required: true,
                  message: "Please add product type",
                },
              ]}
            >
              <Select
                size="large"
                style={{ width: "100%" }}
                placeholder="Type"
                onChange={handleProductTypeChange}
              >
                {clinicProductsList?.map((item) => (
                  <Option key={item.product_type_id} value={item.product_type}>
                    {item.product_type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24} sm={24}>
            <Row gutter={[16, 16]}>
              <Col lg={12} md={24} sm={24} style={{ position: "relative" }}>
                <p className={styles["form-label"]}>Price
                <span  className={styles["required-input"]}/>
                </p>

                <div
                  style={{
                    position: "absolute",
                    left: "55px",
                    top: "-5px",
                  }}
                >
                  <Form.Item name="free" valuePropName="checked">
                    <Checkbox
                      onChange={(e) => handleFreeChange(e.target.checked)}
                    >
                      {" "}
                      <label
                        className={styles["form-label"]}
                        // style={{ marginLeft: "10px" }}
                      >
                        Free
                      </label>
                    </Checkbox>
                  </Form.Item>
                </div>
                <Row>
                  <Col lg={12} md={20} sm={20}>
                    <Form.Item
                      name="productPrice"
                      label=""
                      placeholder="Price"
                      rules={[
                        {
                          required: true,
                          message: "Please add product price",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Price"
                        min={0}
                        step={0.1}
                        style={{ width: "100%" }}
                        size="large"
                        onChange={handlePriceChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={4} sm={4}>
                    <Form.Item
                      name="productCurrencyId"
                      label=""
                      placeholder="Currency"
                      rules={[
                        {
                          required: true,
                          message: "Currency name is required",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 100 }}
                      >
                        {clinicCurrenciesList?.map((currency) => {
                          return (
                            <Option key={currency.currency_id}>
                              {currency.currency_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} md={24} sm={24}>
                <p className={styles["form-label"]}>Discount</p>
                <Row>
                  <Col lg={12} md={12} sm={12}>
                    <Form.Item
                      name="discountPercentage"
                      label=""
                      style={{ margin: "0px" }}
                    >
                      <InputNumber
                        placeholder="%"
                        min={0}
                        max={100}
                        step={0.1}
                        style={{ width: "100%" }}
                        size="large"
                        onChange={handleDiscountPercentageChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={12} sm={12}>
                    <Form.Item
                      name="discount"
                      label=""
                      style={{ marginBottom: "0px" }}
                    >
                      <InputNumber
                        placeholder="Amount"
                        step={0.01}
                        precision={2}
                        style={{ width: "100%" }}
                        size="large"
                        onChange={handleDiscountAmountChange}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col lg={4} md={24} sm={24}>
            <p className={styles["form-label"]}>Total</p>
            <Form.Item name="total" label="">
              <Input placeholder="Total" size="large" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={8} md={24} sm={24}>
            <p className={styles["form-label"]}>Name
            <span  className={styles["required-input"]}/>
            </p>
            <Form.Item
              name="productName"
              label=""
              rules={[
                {
                  required: true,
                  message: "Product name is required",
                },
              ]}
              style={{ textAlign: "left" }}
            >
              <Select
                size="large"
                placeholder="Select Product"
                className="lab-product-selectbox"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <div
                      style={{
                        padding: "0 8px 4px",
                        display: "flex",
                        alignItems: "center",
                        gap: "7px",
                      }}
                    >
                      <Input
                       ref={inputRef} // Attach the ref to the Input component
                        size="large"
                        placeholder="Enter Name"
                        value={productName}
                        onChange={(e) => {
                          if (e.target.value.length <= 50) {
                            setProductName(e.target.value);
                          }
                        }}
                      />
                      <Button
                        type="text"
                        icon={<PlusOutlined />}
                        onClick={addItem}
                        style={{ color: primaryColor }}
                      >
                        Add
                      </Button>
                    </div>
                  </>
                )}
              >
                {productList?.map((item) => (
                  <Option key={item.product_name_id} value={item.product_name}>
                    {item.product_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={16} md={24} sm={24}>
            <p className={styles["form-label"]}>Payment Type</p>
            <Form.Item
              rules={[{ required: true, message: "Payment type is required" }]}
              style={{
                width: "100%",
                textAlign: "left",
              }}
              label=""
              name="paymentIds"
            >
              <Select size="large" style={{ width: "100%" }} mode="multiple">
                {paymentList?.map((el) => {
                  return <Option value={el.id}>{el.buttonText}</Option>;
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col lg={24} md={24} sm={24}>
            <Form.Item
              label=""
              name="productDescription"
            >
              <TextArea rows={3} placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddResourceModal;
