import { useState, useEffect, useRef, useMemo, useContext } from "react";
import {
  Radio,
  Row,
  Col,
  Spin,
  message,
  Select,
  Menu,
  Dropdown,
  Form,
  Modal,
  Input,
  Breadcrumb,
} from "antd";
import axios from "axios";
import { MoreOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import { connect, useDispatch, useSelector } from "react-redux";
import { PRESCRIPTION_FORM_STATUS } from "../../../constants";
import { dentistClinicApiTasks } from "models/dentistClinic";
import ProgressImageComponent from "components/private/patient-journey/progessImagesComponent";
import ProgressImageComponentNew from "components/private/patient-journey/progessImagesComponentNew";
import PatientJourneyMenubarComponent from "components/private/patient-journey/patientJourneyMenubarComponent";
import PatientQuestionnaireComponent from "components/private/patient-journey/patientQuestionnaireComponent";
import PatientDetailTableComponent from "components/private/patient-journey/patientDetailTableComponent";
import PatientOrdersMenubarComponent from "components/private/patient-journey/patientOrdersMenubarComponent";
import PatientDetailsComponent from "components/private/onboarding-profile/patientDetailsComponent";
import ViewScanFilesComponent from "components/private/patient-journey/viewScanFilesComponent";
import PatientTreatmentDetailsComponent from "components/private/patient-journey/patientTreatmentDetailsComponent";
import PatientPrescriptionFormComponent from "components/private/patient-journey/patientPrescriptionFormComponent";
import PatientTreatmentProgressComponent from "components/private/patient-journey/patientTreatmentProgressComponent";
import LabOrderModal from "components/shared/modals/labOrderModal";
import PatientOrderModal from "components/shared/modals/patientOrderModal";
import ConfirmationModal from "components/shared/modals/confirmationModal";
import SendAppLinkModal from "components/shared/modals/sendAppLinkModal";
import DeleteConfirmationModal from "components/shared/modals/deleteConfirmationModal";
import FloatingButtons from "components/private/patient-journey/floatingButtons";
import { prescriptionDataMapper, prescriptionFormInitialValues } from "utilities/prescription";
import { makePatientAddress, patientStatusMapper } from "utilities/patientDetail";
import { createConfirmConfig } from "components/shared/ConfirmationModal/config";
import dayjs from "dayjs";
import DoctorAppModel from "components/shared/DoctorAppModel/DoctorAppModel";
import "../../../assets/styles/routes/patientDetail.scss";
import { changeCommentBoxState, showIntercomInviteApp, updateClinicFirstTimeStatusUpdate } from "services/redux/actions/clinicActions";
import { manageStaffApiTasks } from "models/mangeStaff";
import { AssignClinician } from "components/private/assign-clinician/AssignClinician";
import { getPatientAlignerTimelineAPI } from "api/aligner";
import { socket } from "api/chat";
import {
  deletePrescriptionDraftAPI,
  getPreliminaryImagesApi,
  getPrescriptionAPI,
  getPrescriptionDraftAPI,
  savePrescriptionDraftAPI,
} from "api/prescription";
import { getDoctorAppInfoSelector } from "services/redux/selectors/clinicSelectors";
import {
  getPatientResourceUploadStageAPI,
  getTreatmentOldAPI,
  createStripePaymentIntentAPI,
  getTreatmentPlanPrice,
  createClinicLabOrderAPI,
  getPatientProgress,
  resendRegistrationEmail,
  createClinicPatientOrderAPI,
  updatePaymentStatusApi,
  checkPatientEmail,
  getPatientDetailsApi,
  getRespForQuestionnaireApi,
} from "api/patient";
import {
  getClinicLabOrdersList,
  getClinicLabs,
  getClinicPatientOrdersList,
  patientClinicUpdateStatus,
  updateClinicIntercomJourneyStatus,
} from "api/clinic";
import { clinicIdSelector } from "services/redux/selectors/clinicSelectors";
import { companyType, primaryColor } from "colors-config";
import AddPatientAddress from "components/shared/modals/AddPatientAddress";
import { patientbreadcrumbRoutes } from "utilities/patientDetail";
import ChatWindow from "components/private/ChatWindow/ChatWindow";
import { APPLIANCES_ORDER_KEY, TREATMENT_ORDER_KEY } from "utilities/constants";
import mixpanel from 'mixpanel-browser';
import AppSettingsContext from "Context/useAppContext";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;

// const { Title } = Typography;
const { Option } = Select;
const formDataObject = {
  aligner_type: "Light",
  duration: "1 day",
  isConsentDataChecked: false,
  isTermsAndConditionsChecked: false,
  dataAcquisitionURL: "",
  termsAndConditionsURL: "",
  scanUrls: [],
};

function PatientDetail(props) {
  let { computedMatch, clinic, location } = props;
  const [messageApi, messageContextHolder] = message.useMessage();
  const [modal, modalContextHolder] = Modal.useModal();
  const [labForm] = Form.useForm();
  const clinicId = useSelector(clinicIdSelector);
  const [prescriptionForm] = Form.useForm();
  const patientAddressRef = useRef(null);
  const formDataObjectRef = useRef(null);
  const teethChartDraftRef = useRef(null);
  const formDraftRef = useRef(null);
  const params = useParams();
  const { patientId } = params;
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const impressionCheck = searchParams.get("impression");
  const redirectCheck = searchParams.get("redirect");
  const dispatch = useDispatch();
  const [patientName, setPatientName] = useState("");
  const [clinicianDetail, setClinicianDetail] = useState({});
  const [cliniciansList, setCliniciansList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [imageStageTypes, setImageStageTypes] = useState({});
  const [patientQuestionnaireResponses, setPatientQuestionnaireResponses] = useState([]);
  const [currentSelection, setCurrentSelection] = useState(0);
  const [currentOrdersSelection, setCurrentOrdersSelection] = useState(
    redirectCheck === "lab" || companyType === 5 ? 1 : 0
  );

  const [currenPrescriptionSelection, setCurrenPrescriptionSelection] = useState(0);
  const [currentMenuSelected, setCurrentMenuSelected] = useState(
    computedMatch.params.patientJourney ? computedMatch.params.patientJourney : "patientJourney"
  );
  const [patientAppointments, setPatientAppointments] = useState([]);
  const [patientOrders, setPatientOrders] = useState([]);
  const [labOrders, setLabOrders] = useState([]);
  const [clinicProducts, setClinicProducts] = useState([]);
  const [partneredLabs, setPartneredLabs] = useState([]);

  const [scanUrls, setScanUrls] = useState([]);
  const [clinicalFiles, setClinicalFiles] = useState([]);

  const [products, setProducts] = useState([]);
  const [durations, setDurations] = useState([]);
  const [labList, setLabList] = useState([]);

  const [showLabOrderModal, setShowLabOrderModal] = useState(false);
  const [showPatientOrderModal, setShowPatientOrderModal] = useState(false);
  const [showOlderPlansModal, setShowOlderPlansModal] = useState(false);
  const [showTreatmentCancelModal, setShowTreatmentCancelModal] = useState(false);

  const [showTreatmentReviseModal, setShowTreatmentReviseModal] = useState(false);

  const [patientInfoState, setPatientInfoState] = useState();
  const [statusesState, setStatusesState] = useState();
  const [dropdownValuesState, setDropdownValuesState] = useState();
  const [prescriptionFormData, setPrescriptionFormData] = useState(null);
  const [treatmentPlansState, setTreatmentPlansState] = useState();
  const [treatmentProgressDet, setTreatmentProgressDet] = useState();
  const [aligners, setAligners] = useState();
  const [treatmentId, setTreatmentId] = useState();
  const [sendAppStage, setSendAppStage] = useState(0);
  const [status] = useState(computedMatch.params.patientJourney);
  const [editMode, setEditMode] = useState(PRESCRIPTION_FORM_STATUS.INITIAL);

  const [treatmentOriginId, setTreatmentOriginId] = useState(null);
  const [isPrescriptionAllowedFromDB, setIsPrescriptionAllowedFromDB] = useState(false);
  const [isPrescriptionLabEditAllowed, setIsPrescriptionLabEditAllowed] = useState(false);

  const [isPrescriptionEditAllowed, setIsPrescriptionEditAllowed] = useState(false);

  // const [isEditButtonDisable, setIsEditButtonDisable] = useState(true)
  const [isNotAddedClinic, setIsNotAddedClinic] = useState(true);
  const [selectedCaseId, setSelectedCaseId] = useState(null);
  const [activeCaseId, setActiveCaseId] = useState(null);

  const [cases, setCases] = useState([]);

  const [isPatientDetailComponentSpinning, setIsPatientDetailComponentSpinning] = useState(true);
  const [isPatientQuestionnaireSpinning, setIsPatientQuestionnaireSpinning] = useState(true);
  const [isAlignerTimelineSpinning, setIsAlignerTimelineSpinning] = useState(true);
  const [isTreatmentSetupSpinning, setIsTreatmentSetupSpinning] = useState(true);
  const [isScanUrlsSpinning, setIsScanUrlsSpinning] = useState(true);
  const [isPrescriptionSpinning, setIsPrescriptionSpinning] = useState(true);
  const [isAppointmentsSpinning, setIsAppointmentsSpinning] = useState(true);
  const [isOrdersSpinning, setIsOrdersSpinning] = useState(true);
  const [wholeTreatmentPlan, setWholeTreatmentPlan] = useState([]);
  const myRef = useRef(null);
  const prescriptionFormRef = useRef(null);
  const history = useHistory();
  // const prevCountRef = useRef();

  const [paymentStatus, setPaymentStatus] = useState("-");
  const [paidStatus, setPaidStatus] = useState("-");
  const [payCheckOrder, setPayCheckOrder] = useState(false);
  const [sendOrderWithoutPatientApproval, setSendOrderWithoutPatientApproval] = useState(false);

  const [dropdownLabTreatmentId, setDropdownLabTreatmentId] = useState("");
  const [orderRetainerClick, setOrderRetainerClick] = useState(false);
  const [orderRefinementClick, setOrderRefinementClick] = useState(false);

  const [isModalUpdateOrderVisible, setIsModalUpdateOrderVisible] = useState(false);
  const [updateOrderLoading, setUpdateOrderLoading] = useState(false);
  const [fromPatientJourneyButton, setFromPatientJourneyButton] = useState(false);
  const [showAddPatientAddress, setShowAddPatientAddress] = useState(false);
  const [sharePatientMessage, setSharePatientMessage] = useState(false);
  const [orderType, setOrderType] = useState(TREATMENT_ORDER_KEY)

  // const [fro, setFromPatientJourneyButton] = useState(false);

  const myDivRef = useRef(null);

  const [sendAppForm] = Form.useForm();

  const [isModalSendVisible, setIsModalSendVisible] = useState(false);
  const [sendAppLoading, setSendAppLoading] = useState(false);
  const [fromSendToPatientAppRegistration, setFromSendToPatientAppRegistration] = useState(false);

  const [fromShareWithPatient, setFromShareWithPatient] = useState(false);

  const [isModalApproveSendPlanVisible, setIsModalApproveSendPlanVisible] = useState(false);

  const { clinicConfig } = useContext(AppSettingsContext);

  const [phaseOrderData, setPhaseOrderData] = useState(null);
  const [showScanModel, setShowScanModel] =useState(false); 
  const clinicDoctorAppInfoSelector = useSelector(getDoctorAppInfoSelector);


  const loc = useLocation();
  // Parse the query string to extract the parameter
  const queryParams = new URLSearchParams(loc.search);
  const patientCreationParam = queryParams.get("patientCreation")


  useEffect(() => {
    socket.connect();

    socket.on("connect_error", (err) => {
      console.error("Connection error:", err.message);
    });
    return () => {
      socket.disconnect();
      console.log("socket disconnect", socket);
    };
  }, []);

  useEffect(() => {
    setIsPageLoading(true);
    if (clinic.clinicId) {
      _getCases();
    }
  }, [clinic.clinicId]);

  useEffect(() => {
    if (selectedCaseId) {
      _getData();
    }
  }, [selectedCaseId, patientId]);

  useEffect(() => {
    if (clinic.clinicId) {
      _getOrderDetails();
      checkStatus();
    }
  }, [clinic.clinicId]);

  useEffect(() => {
    if (clinic?.firstTimeOnboard && clinic?.intercomOpenSendInvite) {

      setTimeout(()=>{
        onSendAppClick();
        dispatch(showIntercomInviteApp(false));
      },3000)
    }
  }, [clinic])


  useEffect(() => {
    if (showOlderPlansModal) {
      treatmentPlans();
    }
  }, [showOlderPlansModal]);

  useEffect(() => {
    if (
      patientCreationParam === "true" &&
      clinicDoctorAppInfoSelector &&
      !clinicDoctorAppInfoSelector.appInstalled && // Modal opens only if app is NOT installed
      !clinicDoctorAppInfoSelector.skipApp // Modal opens only if skipApp is NOT true
    ) {
      setShowScanModel(true);
    }
  }, [patientCreationParam, clinicDoctorAppInfoSelector]);
  
  
  // useEffect(() => {
  //   if (patientOrders.length && labOrders.length)
  //     if (currentOrdersSelection === 0) {
  //       setSelectedOrders(patientOrders);
  //     } else if (currentOrdersSelection === 1) {
  //       setSelectedOrders(labOrders);
  //     }
  // }, [currentOrdersSelection, patientOrders, labOrders]);

  useEffect(() => {
    if (currenPrescriptionSelection === 1) {
      prescriptionFormRef.current.scrollIntoView();
    }
  }, [currenPrescriptionSelection]);

  useEffect(() => {
    if (currentSelection > 1) {
      myRef.current.scrollIntoView();
      // console.log(currentSelection);
    }
  }, [currentSelection]);

  const _getData = async () => {
    await _getPatientInformation();

    const rejectHandler = (reason) => ({ status: "rejected", reason });

    const resolveHandler = (value) => ({ status: "fulfilled", value });

    const allSettled = function (promises) {
      const convertedPromises = promises.map((p) =>
        Promise.resolve(p).then(resolveHandler, rejectHandler)
      );
      return Promise.all(convertedPromises);
    };

    allSettled([
      _getImagesAndQuestionnaire(),
      _getPatientAppointments(),
      _getAlignerDetails(),
      // _getAlignerList(),
      // _getPatientInformation(),

      // _getAlignerList(),

      _getOrders(),
      treatmentPlans(),
      // _getAlignerDetails(),
      _getPrescriptionData(),
    ])
      .then((res) => setIsPageLoading(false))
      .catch((error) => {
        setIsPageLoading(false);
        console.log(error);
      });
  };

  const checkStatus = () => {
    if (!status) {
      setCurrentMenuSelected("patientJourney");
    } else if (status === "prescription") {
      setCurrentMenuSelected("prescription");
    } else if (status === "appointments") {
      setCurrentMenuSelected("appointments");
    } else if (status === "orders") {
      setCurrentMenuSelected("orders");
    }
  };
  const _getCurrentTreatmentStatus = async () => {
    try {
      const statusRes = await dentistClinicApiTasks.getAll("clinic/patientlist/status", {
        patientId,
      });

      if (statusRes?.body.data?.length) {
        const statusDetails = statusRes.body.data[0];
        const finalStatus = patientStatusMapper(statusDetails);
        setStatusesState(finalStatus);
        setTreatmentOriginId(statusDetails.patient_treatment_origin_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setCaseOnLoad = (cases) => {
    let currentCaseId = 0;
    cases.forEach((caseObj) => {
      if (caseObj.case_id > currentCaseId) currentCaseId = caseObj.case_id;
    });

    setSelectedCaseId(currentCaseId);
    setActiveCaseId(currentCaseId);
  };

  const _getCases = async () => {
    try {
      const casesRes = await dentistClinicApiTasks.getAll("clinic/patientlist/getCases", {
        patientId,
        clinicId: clinic.clinicId,
      });
      const casesArr = casesRes.body.data;

      setCases(casesArr);
      setCaseOnLoad(casesArr);
      setIsPageLoading(false);
    } catch (error) {
      setIsPageLoading(false);

      console.log(error);
    }
  };

  const callPatientDetails = async () => {
    const patientInfo = await dentistClinicApiTasks.getAll("clinic/patientlist/patientinfo", {
      patientId,
    });
    setPatientInfoState(patientInfo.body[0]);
    patientAddressRef.current = makePatientAddress(patientInfo.body[0]);
    return patientInfo;
  };

  const _getPatientInformation = async () => {
    setIsPatientDetailComponentSpinning(true);
    try {
      const patientInfo = await callPatientDetails();
      setClinicianDetail(patientInfo.body[0].clinicianDetails);
      setPatientName(
        `${patientInfo.body[0]?.patient_first_name ? patientInfo.body[0].patient_first_name : ""} ${
          patientInfo.body[0]?.patient_last_name ? patientInfo.body[0].patient_last_name : ""
        }`
      );

      manageStaffApiTasks
        .getAll("clinic/clinician/getByClinic?clinicId=" + clinic.clinicId)
        .then((res) => {
          const data = res.body.data.map((el) => {
            return {
              ...el,
              value: el.clinician_id,
              label: el.clinician_name,
            };
          });
          setCliniciansList(data);
        });

      const dropdownValues = await dentistClinicApiTasks.getAll(
        "clinic/patientlist/status/getList"
      );
      setDropdownValuesState(dropdownValues.body);

      await _getCurrentTreatmentStatus();
      const model = {
        patientId,
        clinicId: clinic.clinicId,
      };
      if (selectedCaseId) model.caseId = selectedCaseId;

      // const treatmentProgressInfo = await dentistClinicApiTasks.getAll(
      //   "clinic/patientlist/treatmentProgress",
      //   model
      // );
      const treatmentProgressInfo = await getPatientProgress(model);
      setTreatmentProgressDet({
        ...treatmentProgressInfo.body,
        treatment_price: treatmentProgressInfo.body.free
          ? 0
          : treatmentProgressInfo.body.treatment_price,
        payment_status: treatmentProgressInfo.body.free
          ? "Free"
          : treatmentProgressInfo.body.payment_status,
      });
      setIsPatientDetailComponentSpinning(false);

      return "Done";
    } catch (error) {
      console.log(error);
      setIsPatientDetailComponentSpinning(false);
    }
  };

  const _getImagesAndQuestionnaire = async () => {
    setIsPatientQuestionnaireSpinning(true);
    setIsScanUrlsSpinning(true);

    try {
      const scanResModel = { patientId, patientStage: "scan" };
      if (selectedCaseId) scanResModel.caseId = selectedCaseId;

      const scanRes = await getPreliminaryImagesApi(scanResModel)
      const clinicalRes = await getPreliminaryImagesApi({
        patientId,
        patientStage: "clinical",
        caseId: selectedCaseId,
      })

      let clinicalFileList = clinicalRes?.data?.body?.results?.patient_onboarding_images;
      setClinicalFiles(clinicalFileList);
      setScanUrls(scanRes?.data?.body?.results?.patient_onboarding_images);
      formDataObject.scanUrls = scanRes?.data?.body?.results?.patient_onboarding_images;
      setIsScanUrlsSpinning(false);
      const patientQuestionnaireRes = await getRespForQuestionnaireApi({ patientId })
      setIsPatientQuestionnaireSpinning(false);

      const patientAssesmentData =
        patientQuestionnaireRes?.data?.body.map((el) => {
          const obj = el[0];
          if (obj?.patient_answer && Array.isArray(obj.patient_answer)) {
            return {
              ...obj,
              patient_answer: obj.patient_answer[0] || "",
            };
          }
          return obj;
        }) || [];

      setPatientQuestionnaireResponses(patientAssesmentData);
    } catch (error) {
      console.log("error", error);

      setIsScanUrlsSpinning(false);
      setIsPatientQuestionnaireSpinning(false);
    }
  };


  const _getPatientAppointments = async () => {
    setIsAppointmentsSpinning(true);
    try {
      const res = await dentistClinicApiTasks.getAll("clinic/patientlist/appointments", {
        patientId,
      });
      // console.log(res,'checkingFs'.repeat(20))
      setPatientAppointments(res.body);
      setIsAppointmentsSpinning(false);
    } catch (error) {
      console.log(error);
      setIsAppointmentsSpinning(false);
    }
  };



  // const _getPatientTreatmentSetup = async () => {
  //   setIsTreatmentSetupSpinning(true);

  //   const model = { patientId };
  //   if (selectedCaseId) model.caseId = selectedCaseId;
  //   try {
  //     const res = await dentistClinicApiTasks.getAll(
  //       "clinic/patientlist/treatmentSetup/get",
  //       model
  //     );

  //     setTreatmentSetup(res?.body?.treatment_setup || []);
  //     res.body.treatment_setup[0] && setTreatmentId(res.body.treatment_setup[0].lab_treatment_id);
  //     setIsTreatmentSetupSpinning(false);
  //   } catch (error) {
  //     console.log(error);
  //     setIsTreatmentSetupSpinning(false);
  //   }
  // };
  const _getAlignerDetails = async () => {
    setIsAlignerTimelineSpinning(true);

    const params = {
      patientId: patientId,
      clinicId: clinic.clinicId,
    };
    if (selectedCaseId) params.caseId = selectedCaseId;
    try {
      const alignerTimelineData = await getPatientAlignerTimelineAPI(params);
      setAligners(alignerTimelineData);
      setIsAlignerTimelineSpinning(false);

      // setPatientAppointments(res.body.data);
    } catch (error) {
      console.log(error);
      setIsAlignerTimelineSpinning(false);
    }
  };

  //  console.log("outerrrrrrrrrrrr", clinicId);
  localStorage.setItem("clinicId", clinicId);
  const saveToDraftService = (data) => {
    // console.log("here clinicId",clinicId,patientId, data);
    const clinic_id = localStorage.getItem("clinicId");
    savePrescriptionDraftAPI(clinicId ? clinicId : clinic_id, patientId, data)
      .then(() => setEditMode(PRESCRIPTION_FORM_STATUS.PARTIAL))
      .catch((err) => console.log(err));
  };

  const mapPrescriptionData = async (prescriptionData, labId, productName) => {
    let formData = prescriptionFormInitialValues(labId, productName);

    let isDraft = false;

    const dataState = prescriptionData && Object.keys(prescriptionData).length;

    if (dataState) {
      formData = prescriptionData;
    } else {
      const prescriptionDraft = await getPrescriptionDraftAPI(clinicId, patientId);

      const draftData = prescriptionDraft?.data?.draft?.prescription || null;
      if (draftData) {
        // console.log("draftDatadraftDatadraftData", draftData)
        isDraft = true;
        setEditMode(PRESCRIPTION_FORM_STATUS.PARTIAL);

        formData = draftData;
        setPrescriptionFormData(draftData);
      }
    }

    if (dataState || isDraft) {
      const finalObj = prescriptionDataMapper(formData, isDraft);
      if (isDraft) {
        formDataObjectRef.current = finalObj;
      }

      prescriptionForm.setFieldsValue(finalObj);
      // setPrescriptionFormData(finalObj);
    } else {
      // first time form run case
      formDataObjectRef.current = formData;
      prescriptionForm.setFieldsValue(formData);
    }
  };

  const _getPrescriptionData = async () => {
    setIsPrescriptionSpinning(true);
    try {
      const responseModel = {
        patientId,
      };
      if (selectedCaseId) responseModel.caseId = selectedCaseId;

      const apiArray = [
        dentistClinicApiTasks.getAll("clinic/prescription/duration/get", {
          clinicId: clinic.clinicId,
        }),
        dentistClinicApiTasks.getAll("clinic/prescription/product/get", {
          clinicId: clinic.clinicId,
        }),
        dentistClinicApiTasks.getAll("clinic/prescription/labs/get", {
          clinicId: clinic.clinicId,
        }),
        // dentistClinicApiTasks.getAll(
        //   "clinic/prescription/patient/get",
        //   responseModel
        // ),
        getPrescriptionAPI(responseModel.patientId, responseModel.caseId),
      ];

      const [durationRes, productsList, labsRes, getResponse] = await Promise.all(apiArray);
      // const durationRes = await dentistClinicApiTasks.getAll(
      //   "clinic/prescription/duration/get",
      //   { clinicId: clinic.clinicId }
      // );

      setDurations(durationRes);

      // const productsList = await
      if (productsList?.body?.data) {
        setProducts(productsList.body.data);
      }

      // const labsRes = await

      // console.log(durationRes, labsRes, productsList, "===Sim");
      setLabList(labsRes);

      // const getResponse = await
      setIsPrescriptionSpinning(false);

      // const prescriptionFormValues = await initialValueSetter(
      //   getResponse?.body.data[0],
      //   labsRes?.body?.data[0]?.lab_id,
      //   productsList?.body?.data?.length ? productsList?.body?.data[0].product_name : ""
      // );
      // setPrescriptionFormData(prescriptionFormValues);
      if (getResponse?.data?.prescription) {
        setEditMode(
          Object.keys(getResponse?.data?.prescription).length > 0
            ? PRESCRIPTION_FORM_STATUS.SUBMITTED
            : PRESCRIPTION_FORM_STATUS.INITIAL
        );
      }
      // if (getResponse?.body.data.length) {
      //   setEditMode(
      //     getResponse?.body.data.length
      //       ? PRESCRIPTION_FORM_STATUS.SUBMITTED
      //       : PRESCRIPTION_FORM_STATUS.INITIAL
      //   );
      // }
      setPrescriptionFormData(getResponse?.data?.prescription || {});

      mapPrescriptionData(
        getResponse?.data?.prescription,
        labsRes?.body?.data[0]?.lab_id,
        productsList?.body?.data?.length ? productsList?.body?.data[0].product_name : ""
      );

      // console.log(getResponse.body, "get response");
      // setIsPrescriptionAllowedFromDB(getResponse?.body.editAllowed);

      // if (getResponse?.body.editLabAllowed === undefined) {
      //   setIsPrescriptionLabEditAllowed(true);
      //   formDataObject.isPrescriptionLabEditAllowed = true;
      // } else if (getResponse?.body.editLabAllowed === false) {
      //   setIsPrescriptionLabEditAllowed(false);
      //   formDataObject.isPrescriptionLabEditAllowed = false;
      // } else if (getResponse?.body.editLabAllowed === true) {
      //   setIsPrescriptionLabEditAllowed(true);
      //   formDataObject.isPrescriptionLabEditAllowed = true;
      // }
    } catch (error) {
      console.log(error);
      setIsPrescriptionSpinning(false);
    }
  };
  const _getOrders = async () => {
    setIsOrdersSpinning(true);
    try {
      // const patientOrdersRes = await dentistClinicApiTasks.getAll(
      //   "clinic/patientlist/patient/orders/get",
      //   { patientId }
      // );
      const patientOrdersRes = await getClinicPatientOrdersList({ patientId });

      const labOrdersRes = await getClinicLabOrdersList({ patientId });

      // dentistClinicApiTasks.getAll(
      //   "clinic/patientlist/lab/orders/get",
      //   { patientId }
      // );
     const mappedOrders = [];
     labOrdersRes.body.data?.forEach(order => {
       if (order?.phasing.length) {
         let phasingOrders = order?.phasing.sort(
           (a, b) => b.phaseNo - a.phaseNo
         );

         phasingOrders = phasingOrders.map(phOrder => ({
           ...order,
           ...phOrder,
           refOrderId:order.patient_order_id
         }));
         mappedOrders.push(...phasingOrders);
       } else {
         mappedOrders.push(order);
       }
     });

      setPatientOrders(patientOrdersRes.body.data);
      setLabOrders(mappedOrders);
      setIsOrdersSpinning(false);
      if(!clinicConfig.isProductAvailable){
        setCurrentOrdersSelection(1)
      }
    } catch (error) {
      console.log(error);
      setIsOrdersSpinning(false);
    }
  };

  const _getOrderDetails = async () => {
    try {
      const detailsRes = await dentistClinicApiTasks.getAll(
        "clinic/getServicesAndResources",
        { clinicId: clinic.clinicId }
        //{clinicId:"CLINIC79689706"}
      );
      setClinicProducts(detailsRes.body.clinic_products);

      const partneredLabsRes = await getClinicLabs({
        clinicId: clinic.clinicId,
      });

      setPartneredLabs(partneredLabsRes.body.partnerLabDetails);
    } catch (error) {
      console.log(error);
    }
  };

  const onMenuChanged = (event) => {
    const selectedMenu = event.target.value;
    setCurrentMenuSelected(selectedMenu);
    history.push(`/clinic/patient/${patientId}/${event.target.value}`);
    console.log("selectedMenu", selectedMenu);
    if (selectedMenu == "prescription" && process.env.REACT_APP_PRESCRIPTION_TOUR_ID) {
      window.Intercom("startTour", process.env.REACT_APP_PRESCRIPTION_TOUR_ID);
    }
  };
  const getLabTreatmentId = () => {
    if (orderType === APPLIANCES_ORDER_KEY || (statusesState?.patient_journey_status_id ?? 0) === 9) {
      return "";
    }
    return dropdownLabTreatmentId ?? "";
  };

  const confirmLabOrder = async (orderValues, lab_treatment_id) => {
    // const treatmentPlanPriceData = await getTreatmentPlanPrice(patientId);


    // if (payCheckOrder) {
    //   if (!patientOrders?.length) {
    //     await payAtClinicApi(treatmentPlanPriceData, orderValues.productPrice);
    //   }
    // }
    // if (
    //   treatmentPlanPriceData?.body?.length &&
    //   treatmentPlanPriceData.body[0].product_type_id === 6 &&
    //   !treatmentPlanPriceData.body[0].free
    // ) {
    //   const findRefinementOrder = patientOrders.find(
    //     (obj) => obj.product_type_id == 6 && obj.payment_status == 2
    //   );
    //   if (!findRefinementOrder) {
    //     await payAtClinicApi(treatmentPlanPriceData, orderValues.productPrice);
    //   }
    // }
    
    const othersOrder = (orderType === APPLIANCES_ORDER_KEY || (statusesState?.patient_journey_status_id ?? 0) === 9);
    // if (sendOrderWithoutPatientApproval || (orderValues.version!=='other' && !fromPatientJourneyButton)) {
    if (!othersOrder && !orderValues.sendToPatient) {

      await confirmTreatmentStatus(
        dropdownLabTreatmentId,
        "approved",
        "",
        "",
        orderValues?.refinement ? orderValues.refinement : false,
        orderValues.isProductFree,
        false,
        undefined,
        false,
        false
      );
    }

    const orderModel = {
      clinic_id: clinic.clinicId,
      patient_id: patientId,
      product_type_id: +orderValues.productType,
      lab_id: orderValues.lab,
      shipping_address: orderValues.sendTo,
      lab_treatment_id: getLabTreatmentId(),
      clinicAddressId: orderValues?.clinicAddress || 0,
      labProductId: orderValues?.labProductId,
      newOrder: fromPatientJourneyButton ? false : true,
      quantity: orderValues?.labProductId ? orderValues?.quantity : 0,
      refinement: orderValues?.refinement ? orderValues.refinement : false,
      orderNote: orderValues?.orderNote,
      others: othersOrder,
      orderLabProductList: orderValues?.products?.length ? orderValues?.products.map(obj=>{
        return {
          ...obj,
          newPrice:obj.price
        }
      }):[],
      phasing: orderValues.phasing,
      phases: orderValues.phasing
        ? orderValues.phases.map((phase, index) => {
            return {
              ...phase,
              phaseNo: index + 1,
              lab_treatment_id: lab_treatment_id,
              deliveryDate:dayjs(phase.deliveryDate).format("YYYY-MM-DD")
            };
          })
        : [],
    };
    const hide = message.loading("Submitting order", 0);
    setFromPatientJourneyButton(false);
    let orderApiResponse;
    try {
      orderApiResponse = await createClinicLabOrderAPI(orderModel);
      setTimeout(hide, 0);
      // return;
      if (orderApiResponse?.err?.msg) {
        message.error("Lab order could not be created. Please contact your administrator.");
      } else {
        mixpanel.people.set({
          created_first_order: true
        });
        message.success(orderApiResponse.body?.msg);
        _getOrders();
        treatmentPlans();
        _getPatientInformation();
        if (orderRetainerClick) {
          await _getCurrentTreatmentStatus();
        }
        if (
          clinicDoctorAppInfoSelector &&
          !clinicDoctorAppInfoSelector.appInstalled && // Modal opens only if app is NOT installed
          !clinicDoctorAppInfoSelector.skipApp // Modal opens only if skipApp is NOT true
        ) {
          setShowScanModel(true);
        }
      }
    } catch (error) {
      console.log("error", error);
      setTimeout(hide, 0);

      if (error?.response.data?.err?.msg) {
        message.error(error.response.data.err?.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    } finally {
      setOrderRetainerClick(false);
      setOrderRefinementClick(false);
    }
    return orderApiResponse;
  };

  const confirmPatientOrder = async (orderValues) => {
    const orderModel = {
      clinic_id: clinic.clinicId,
      patient_id: patientId,
      product_id: orderValues.productId,
      product_type_id: +orderValues.productType,
      product_name_id: +orderValues.productName,
      product_price: orderValues.productPrice,
      product_currency_id: orderValues.productCurrency,
    };

    // console.log(orderModel, orderValues, "values");
    const hide = message.loading("Sending Request", 0);

    try {
      // const res = await dentistClinicApiTasks.post(
      //   "clinic/patientlist/requestOrder",
      //   orderModel
      // );
      const res = await createClinicPatientOrderAPI(orderModel);
      // console.log(res);
      message.success(res.body?.msg);
      setTimeout(hide, 0);

      _getOrders();
    } catch (error) {
      console.log(error);
      setTimeout(hide, 0);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
  };
  const updateTreatmentOrigin = async (treatmentId) => {
    const treatment = {
      patient_id: patientId,
      treatment_origin_id: treatmentId,
    };
    const hide = message.loading("Updating Treatment Origin", 0);

    try {
      const res = await dentistClinicApiTasks.post(
        "clinic/patientlist/updateTreatmentOrigin",
        treatment
      );
      // console.log(res);
      await _getCurrentTreatmentStatus();
      setTimeout(hide, 0);
      message.success(res.body?.msg);
    } catch (error) {
      console.log(error);
      setTimeout(hide, 0);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }
    }
  };
  const confirmTreatmentStatus = async (
    labOrderId,
    status,
    modifyComment = "",
    approvedComment = "",
    isRefinement = false,
    free = false,
    sendToPatient = true,
    hideTreatmentInfo,
    afterApproval = false,
    sendEmailToLab =true,
  ) => {
    const hide = message.loading("Updating treatment Status", 0);

    const model = {
      lab_treatment_id: labOrderId,
      approval_status: status,
      modifyComment,
      approvedComment,
      isRefinement,
      free,
      sendToPatient,
      hideTreatmentInfo,
      afterApproval,
      sendEmailToLab
    };

    try {
      await patientClinicUpdateStatus(model);
      setTimeout(hide, 0);
      treatmentPlans();
    } catch (error) {
      console.log(error);
      setTimeout(hide, 0);

      message.error("Something didn't go right. Please try again!");
    }
  };

  const submitPrescriptionForm = async (prescriptionData) => {
    const model = {
      ...prescriptionData,
      clinic_id: clinic.clinicId,
      patient_id: patientId,
    };
    const hide = message.loading("Submitting", 0);

    try {
      await dentistClinicApiTasks.post("prescription/add", model);
      // console.log(res, "post res");
      setTimeout(hide, 0);

      message.success("Form submitted successfully");
      await _getPrescriptionData();

      localStorage.removeItem(`prescription-${patientId}`);
      setIsPrescriptionEditAllowed(false);
      await deletePrescriptionDraftAPI(clinic.clinicId, patientId);
    } catch (error) {
      console.log(error);
      setTimeout(hide, 0);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Something went wrong. Please try again.");
      }

      console.log(error);
    }
  };

  const treatmentPlans = async () => {
    const model = { patientId: patientId };
    if (selectedCaseId) model.caseId = selectedCaseId;
    setIsTreatmentSetupSpinning(true);

    try {
      const treatmentResponse = await getTreatmentOldAPI(model);

      // res?.body?.treatment_setup?.map((i) => i[0]).reverse()
      setWholeTreatmentPlan(treatmentResponse?.body?.treatment_setup);
      setTreatmentPlansState(treatmentResponse);
    } catch (error) {
      console.log(error);
    }
    finally{
      setIsTreatmentSetupSpinning(false);
    }
  };
  const cancelTreatment = async () => {
    const model = { patientId, clinicId: clinic.clinicId };
    const hide = message.loading("Canceling Treatment", 0);
    try {
      await dentistClinicApiTasks.post("patient/appointment/cancel", model);
      await _getCurrentTreatmentStatus();
      setTimeout(hide, 0);
      message.success("Treatment Successfully Cancelled!");
    } catch (error) {
      setTimeout(hide, 0);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Failed to cancel the treatment. Please try again later");
      }
    }
  };

  const reviseTreatment = async () => {
    const hide = message.loading("Revising Treatment", 0);
    try {
      const model = { patient_id: patientId, clinic_id: clinic.clinicId };
      await dentistClinicApiTasks.post("clinic/patientlist/reviseTreatment", model);
      await _getCases();
      setTimeout(hide, 0);
      message.success("Treatment Successfully Revised!");
      setIsPrescriptionAllowedFromDB(true);
    } catch (error) {
      setTimeout(hide, 0);
      if (error.response.data?.err?.msg) {
        message.error(error.response.data.err.msg);
      } else {
        message.error("Failed to revise the treatment. Please try again later");
      }
    }
  };

  const handleTreatmentStatusChange = ({ key }) => {
    if (key === "cancel") {
      setShowTreatmentCancelModal(true);
    }
    if (key === "revise") {
      setShowTreatmentReviseModal(true);
    }
  };

  const changeTreatmentCase = (val) => {
    setSelectedCaseId(val);
    // _getData();
  };

  function itemRender(route, params, routes, paths) {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={"/clinic/patients"}>{route.breadcrumbName}</Link>
    );
  }

  const checkTreatmentStatus = () => {
    if (statusesState) {
      const treatmentStatus = statusesState.patient_treatment_plan_status;
      if (
        !treatmentStatus ||
        treatmentStatus === "Case Submitted" ||
        treatmentStatus === "TP Ready for review" ||
        treatmentStatus === "TP Approved and Sent" ||
        treatmentStatus === "Awaiting lab reply"
      ) {
        return false;
      }
    }
    return true;
  };
  const treatmentEditMenu = (
    <Menu onClick={handleTreatmentStatusChange}>
      {/* {checkTreatmentStatus() ? (
        <Menu.Item key={"revise"}>Revise Treatment</Menu.Item>
      ) : null} */}

      <Menu.Item key={"cancel"}>Cancel Treatment</Menu.Item>
    </Menu>
  );
  // const updateShowCommentBoxFlag = () => {
  //   // console.log("called");
  //   location.state.showCommentBox = false;
  // };


  const updateClinicianApi = (data) => {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/clinic/patient/updateclinician`, data);
  };

  const onClinicianChange = async (e) => {
    const value = cliniciansList.filter((el) => el.clinician_id === e)[0];
    if (clinicianDetail?.clinician_id !== e) {
      const data = {
        patientId: patientId,
        clinicianId: e,
        clinicId: clinic.clinicId,
      };
      try {
        await updateClinicianApi(data).then((res) => {
          setClinicianDetail(value);
        });
        setShowDropdown(false);
        setShowEditIcon(false);
      } catch (e) {
        console.log("error ", e);
      }
    } else {
      setShowDropdown(false);
      setShowEditIcon(false);
    }
  };

  useEffect(() => {
    getPatientDetails();
  }, [patientId]);

  const getPatientDetails = async () => {
    try {
      const details = await getPatientDetailsApi(patientId);
      if (details?.data?.body?.length) {
        setSendAppStage(details?.data?.body[0]?.patient_registered);
      } else {
        setSendAppStage(0);
        history.push('/clinic/patients')
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAppLinkOk = () => {
    onSendAppConfirm();
  };
  const handleAppLinkCancel = () => {
    if (sendAppForm.isFieldsTouched()) {
      showConfirm();
    } else {
      setIsModalSendVisible(false);
      setSharePatientMessage(false);
      setFromSendToPatientAppRegistration(false);
      setFromShareWithPatient(false);
    }
  };


  const showConfirm = () => {
    confirm({
      title: "Close send app form",
      icon: <ExclamationCircleFilled />,
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      okButtonProps: {
        style: {
          width: "7rem",
          background: primaryColor,
          borderColor: primaryColor,
          borderRadius: "30px",
          color: "#fff",
        },
      },
      cancelButtonProps: {
        style: {
          width: "7rem",
          borderRadius: "30px",
        },
      },
      onOk() {
        setIsModalSendVisible(false);
        setSharePatientMessage(false);
        setFromSendToPatientAppRegistration(false);
        setFromShareWithPatient(false);
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };

  const onSendAppConfirm = async () => {
    const obj = {
      patient_id: patientId,
    };
    try {
      let email;
      setSendAppLoading(true);
      const sendAppFormData = await sendAppForm.validateFields();
      email = sendAppFormData?.email || "";
      if (email) {
        obj.email = email;
        const checkPatientDetails = {
          email: email,
        };
        if (patientInfoState.patient_email !== email) {
          const checkPatientResponse = await checkPatientEmail(checkPatientDetails);
          if (checkPatientResponse?.data?.data?.emailExists) {
            throw new Error("Email already Exists. Assign new Email");
          }
        }
      }

      await resendRegistrationEmail(obj);
      message.success("Email has been sent successfully.");

      setIsModalSendVisible(false);
      if (email) {
        setPatientInfoState({
          ...patientInfoState,
          patient_email: email,
        });
      }
      setSendAppLoading(false);
      if (fromSendToPatientAppRegistration) {
        setIsModalApproveSendPlanVisible(true);
      }
      setFromSendToPatientAppRegistration(false);
      setSharePatientMessage(false);
      
      if (clinic?.firstTimeOnboard) {
        updateClinicIntercomJourneyStatus(clinic?.clinicId, {
          firstTimeOnboardStep: "APPROVE_PLAN_PENDING"
        })
        dispatch(updateClinicFirstTimeStatusUpdate("APPROVE_PLAN_PENDING"))
      }
    } catch (error) {
      message.error(error?.values ? "Please enter patient email" : error.message);
      setSendAppLoading(false);
      // setFromSendToPatientAppRegistration(false)
      // setSharePatientMessage(false)
    }
  };

  const payAtClinicApi = async (treatmentPlanPriceData, productPrice) => {
    try {
      if (treatmentPlanPriceData?.body?.length) {
        const payObj = { ...treatmentPlanPriceData.body[0] };
        const insertData = {
          clinic_id: wholeTreatmentPlan[0]?.clinic_id,
          patient_id: patientId,
          product_id: payObj.product_id,
          product_type_id: payObj.product_type_id,
          product_name: payObj.product_name,
          product_name_id: payObj.product_name_id,
          product_price: productPrice?.toFixed(2),
          product_currency_id: payObj.product_currency_id,
          currency_name: payObj.currency_name,
          payment_method: "payatclinic",
          paymentType: "offline",
          notify: false,
          lab_treatment_id: dropdownLabTreatmentId,
          paidStatus: paymentStatus,
          client: "clinic",
        };
        await createStripePaymentIntentAPI(insertData);

        _getOrders();
        treatmentPlans();
        _getPatientInformation();
      } else {
        // console.log("error 1");
        // message.error("Some Error Occured!");
      }
    } catch (error) {
      console.log("error", error);
      // message.error("Some Error Occured!");
    }
  };

  const updateOrderOk = async () => {
    updatePaymentStatus();
  };

  const updatePaymentStatus = async () => {
    try {
      setUpdateOrderLoading(true);

      let value = paymentStatus;

      const data = {
        patientId: patientId,
        status: value,
      };

      await updatePaymentStatusApi(data);
      if (value) {
        _getOrders();
      }

      if (value == "") {
        setPaidStatus("-");
        setPaymentStatus("-");
      }
      console.log('value123',value);
      setPaidStatus(value);
      if (value == "Paid" || value == "Free" || value == "Partially Paid") {
        message.success("Payment status updated");
        setPayCheckOrder(true);
        setShowLabOrderModal(true);
        setIsModalUpdateOrderVisible(false);
      } else {
        message.error("You did not change the status");
      }
    } catch (err) {
      console.log("error", err);
      message.error("Some error occured!");
    } finally {
      setUpdateOrderLoading(false);
    }
  };

  const handleOrderCancel = () => {
    setIsModalUpdateOrderVisible(false);
    setPayCheckOrder(true);
    setPaymentStatus(paidStatus);
    setSendOrderWithoutPatientApproval(false);
  };

  const handlePaymentStatusChange = (value) => {
    if (value == "-" || value == "Refunded") {
      value = "";
    }
    setPaymentStatus(value);
  };


  const onSendAppClick = () => {
    setIsModalSendVisible(true);
  };

  const confirmPatientAddressModal = () => {
    const confirmPatientAddress = createConfirmConfig({
      title: "Confirm Patient Address",
      width: 520,
      icon: null,
      centered: true,
      content: (
        <div style={{ margin: "20px 0" }}>
          <div>
            <b>Please verify the patient's adddress before placing the order:</b>
          </div>
          <div style={{ textDecoration: "underline" }}>{patientAddressRef.current}</div>
        </div>
      ),
      onOk: () => labForm.submit(),
      onCancel: () => {},
      okText: "Confirm & Order",
    });
    modal.confirm(confirmPatientAddress);
  };

  const handleScanOk= () =>{
   setShowScanModel(false)
  }
  const onScanCancel= () =>{
    setShowScanModel(false)
  }

  return (
    <Spin spinning={isPageLoading}>
      {messageContextHolder}
      <div>
        <div>
          {showLabOrderModal && (
            <LabOrderModal
              isPhaseOrder={phaseOrderData}
              labOrders={labOrders}
              modal={modal}
              title="Place Order"
              showModal={showLabOrderModal}
              patientAddressRef={patientAddressRef}
              onConfirmation={confirmLabOrder}
              setShow={setShowLabOrderModal}
              clinicProducts={clinicProducts}
              partneredLabs={partneredLabs}
              patientId={patientId}
              treatmentProgressDet={treatmentProgressDet}
              orderRetainerClick={orderRetainerClick}
              orderRefinementClick={orderRefinementClick}
              wholeTreatmentPlan={wholeTreatmentPlan}
              prescriptionForm={prescriptionForm}
              fromPatientJourneyButton={fromPatientJourneyButton}
              setFromPatientJourneyButton={setFromPatientJourneyButton}
              setSendOrderWithoutPatientApproval={setSendOrderWithoutPatientApproval}
              setShowAddPatientAddress={setShowAddPatientAddress}
              patientInfoState={patientInfoState}
              form={labForm}
              confirmPatientAddressModal={confirmPatientAddressModal}
              sendOrderWithoutPatientApproval={sendOrderWithoutPatientApproval}
              treatmentPlans={treatmentPlans}
              dropdownLabTreatmentId={dropdownLabTreatmentId}
              paymentStatus={paymentStatus}
              setIsModalUpdateOrderVisible={setIsModalUpdateOrderVisible}
              setDropdownLabTreatmentId={setDropdownLabTreatmentId}
              status={statusesState}
              orderType={orderType}
              setOrderType={setOrderType}
              getTreatmentPlans={treatmentPlans}
              getOrders={_getOrders}
            />
          )}
          {modalContextHolder}
          {showPatientOrderModal && (
            <PatientOrderModal
              title="Request Order"
              showModal={showPatientOrderModal}
              onConfirmation={confirmPatientOrder}
              setShow={setShowPatientOrderModal}
              clinicProducts={clinicProducts}
              patientId={patientId}
            ></PatientOrderModal>
          )}
          {/* {showOlderPlansModal && (
            <OlderPlansModal
              title="View Older Plans"
              treatmentPlans={treatmentPlans}
              setShow={setShowOlderPlansModal}
              showModal={showOlderPlansModal}
              treatmentPlansState={treatmentPlansState}
            ></OlderPlansModal>
          )} */}
          {showTreatmentCancelModal && (
            <DeleteConfirmationModal
              title="Cancel Treatment"
              showModal={showTreatmentCancelModal}
              onConfirmation={cancelTreatment}
              setShow={setShowTreatmentCancelModal}
              mode={"treatmentCancel"}
            ></DeleteConfirmationModal>
          )}

          {isModalSendVisible && (
            <div className="send-app-confirmation-modal">
              <SendAppLinkModal
                title="Send App Registration Email"
                isModalVisible={isModalSendVisible}
                setIsModalSendVisible={setIsModalSendVisible}
                handleOk={handleAppLinkOk}
                loading={sendAppLoading}
                handleCancel={handleAppLinkCancel}
                content={
                  !patientInfoState.patient_registered ? (
                    <>
                      {sharePatientMessage ? (
                        <p style={{ marginTop: 0 }}>
                          The patient is not available on the app. If you haven't sent the patient
                          registration link, please enter the email to send it.
                        </p>
                      ) : null}
                      <Form
                        form={sendAppForm}
                        name="sendPatientEmail"
                        initialValues={{
                          email: patientInfoState.patient_email,
                        }}
                        layout="vertical"
                      >
                        <Form.Item
                          rules={[
                            {
                              type: "email",
                              message: "Invalid email address",
                            },
                            {
                              required: true,
                              message: "Please enter patient email",
                            },
                          ]}
                          name="email"
                          label="Email"
                        >
                          <Input placeholder="Enter email" />
                        </Form.Item>
                      </Form>
                    </>
                  ) : (
                    "Would you like to send the app registration link to the patient again?"
                  )
                }
              />
            </div>
          )}

          {showTreatmentReviseModal && (
            <ConfirmationModal
              title="Revise Treatment"
              content={"Do you confirm revising the treatment"}
              showModal={showTreatmentReviseModal}
              onConfirmation={reviseTreatment}
              setShow={setShowTreatmentReviseModal}
            ></ConfirmationModal>
          )}
          <PageHeader
            className="site-page-header"
            onBack={() => history.push("/clinic/patients")}
            title="Patient Detail"
            breadcrumb={<Breadcrumb routes={patientbreadcrumbRoutes} />}
            extra={[
              <AssignClinician
                name={clinicianDetail?.clinician_name}
                options={cliniciansList}
                value={clinicianDetail?.clinician_id}
                onChange={onClinicianChange}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                showEditIcon={showEditIcon}
                setShowEditIcon={setShowEditIcon}
                onSendAppClick={onSendAppClick}
                sendAppStage={sendAppStage}
                patientEmail={patientInfoState?.patient_email}
                messageApi={messageApi}
                modal={modal}
              />,
            ]}
          />

          {
            <div className="patient-details-container">
              <Spin spinning={isPatientDetailComponentSpinning}>
                <Row
                  style={{
                    // width: "calc(95% - 1rem) ",
                    marginLeft: "1rem",
                    marginBottom: "2rem",
                    // width: "140%",
                  }}
                >
                  <PatientDetailsComponent
                    patientInfo={patientInfoState}
                    getPatientInfo={_getPatientInformation}
                    status={statusesState}
                    getOrders={_getOrders}
                    dropdownValues={dropdownValuesState}
                    updateTreatmentOrigin={updateTreatmentOrigin}
                    treatmentProgressDet={treatmentProgressDet}
                    clinic={clinic}
                    setPaymentStatus={setPaymentStatus}
                    paymentStatus={paymentStatus}
                    paidStatus={paidStatus}
                    setPaidStatus={setPaidStatus}
                    productList={products}
                    patientId={patientId}
                    patientOrders={patientOrders}
                    wholeTreatmentPlan={wholeTreatmentPlan}
                    sendAppStage={sendAppStage}
                    setPatientInfoState={setPatientInfoState}
                    labOrders={labOrders}
                    onSendAppClick={onSendAppClick}
                  />
                </Row>
              </Spin>
              <div>
              <Row
                justify="space-between"
                style={{
                  // minHeight: "2.5rem",
                  // // width: "calc(95% - 1rem) ",
                  // marginLeft: "1rem",
                  // marginBottom:"1rem",
                }}
                gutter={[16,8]}
                className="patient-header-row"
              >
                <Col>
                  <Radio.Group
                    defaultValue={currentMenuSelected}
                    buttonStyle="solid"
                   style={{ display: "flex" }}
                    onChange={onMenuChanged}
                  >
                    <Radio.Button className="patient-details-radio-buttons" value="patientJourney">
                      Patient Journey
                    </Radio.Button>
                    <Radio.Button className="patient-details-radio-buttons" value="prescription">
                    Prescription & Files
                    </Radio.Button>
                    {clinicConfig?.appointment &&<Radio.Button className="patient-details-radio-buttons" value="appointments">
                      Appointments
                    </Radio.Button>}
                    <Radio.Button className="patient-details-radio-buttons" value="orders">
                      Orders
                    </Radio.Button>
                  </Radio.Group>
                </Col>
                <Col >
                  <Row justify="space-between">
                    <Col>
                      {selectedCaseId ? (
                        <Select
                          size="large"
                          style={{ width: 150 }}
                          defaultValue={selectedCaseId}
                          value={selectedCaseId}
                          onChange={changeTreatmentCase}
                        >
                          {cases.map((caseObj) => {
                            return (
                              <Option key={caseObj.case_id} value={caseObj.case_id}>
                                {caseObj.case_name}
                              </Option>
                            );
                          })}
                        </Select>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col style={{ marginLeft: "0.5rem" }}>
                      <Dropdown style={{ height: "100%" }} overlay={treatmentEditMenu}>
                        <MoreOutlined
                          style={{
                            fontSize: "32px",
                            height: "100%",
                            backgroundColor: "#fff",
                            color: "#c4c4c4",
                            borderRadius: "5px",
                            border: "1px solid #ECECEC",
                          }}
                        />
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              </Row>
              </div>
          
              <div>
                {currentMenuSelected === "patientJourney" && (
                  <div>
                    <Row
                      style={{
                        // width: "calc(95% - 1rem) ",
                        marginLeft: "1rem",
                        marginBottom: "1rem",
                       backgroundColor:"yellow"
                      }}
                    >
                      <PatientJourneyMenubarComponent
                        setCurrentSelection={setCurrentSelection}
                      ></PatientJourneyMenubarComponent>
                    </Row>
                    <Row
                      style={{
                        // width: "calc(95% - 1rem) ",
                        marginLeft: "0.2rem",
                        marginBottom: "1rem",
                      }}
                      gutter={[20, 20]}
                    >
                      <Col lg={12} md={24} sm={24} flex="1">
                        {/* <Spin spinning={isProgressImagesSpinning}> */}
                          {/* <ProgressImageComponent
                            ref={progressImageRef}
                            imageStageTypes={imageStageTypes}
                            fullData={fullProgressImageData}
                            patientId={patientId}
                            listOfAligners={alignerList}
                            caseId={activeCaseId}
                            selectedCaseId={selectedCaseId}
                            getAlignerUrls={_getAlignerImages}
                            alignerObject={alignerObject}
                            setAlignerObject={updateAlignerState}
                            getTreatmentId={_getPatientTreatmentSetup}
                            treatmentId={treatmentId}
                            deleteProgressImage={deleteProgressImage}
                            currentAlignerStage={currentAlignerStage}
                            setIsProgressImagesSpinning={setIsProgressImagesSpinning}
                            clinicId={clinic?.clinicId}
                            companyType={clinic?.companyType}
                            impressionCheck={impressionCheck}
                            myDivRef={myDivRef}
                          ></ProgressImageComponent> */}
                          <ProgressImageComponentNew
                            imageStageTypes={imageStageTypes}
                            patientId={patientId}
                            caseId={activeCaseId}
                            selectedCaseId={selectedCaseId}
                            treatmentId={treatmentId}
                            clinicId={clinic?.clinicId}
                            companyType={clinic?.companyType}
                            impressionCheck={impressionCheck}
                            myDivRef={myDivRef}
                          />
                        {/* </Spin> */}
                      </Col>
                      <Col lg={12} md={24} sm={24}>
                        <Spin spinning={isTreatmentSetupSpinning}>
                          <div ref={myDivRef}>
                            <PatientTreatmentDetailsComponent
                              setPhaseOrderData={setPhaseOrderData}
                              // setShowLabOrderModal={setShowLabOrderModal}
                              setIsModalSendVisible={setIsModalSendVisible}
                              sendAppStage={sendAppStage}
                              treatmentId={treatmentId}
                              productList={products}
                              wholeTreatmentPlan={wholeTreatmentPlan}
                              treatmentPlansState={treatmentPlansState}
                              confirmTreatmentStatus={confirmTreatmentStatus}
                              treatmentPlans={treatmentPlans}
                              setShowOlderPlansModal={setShowOlderPlansModal}
                              labOrders={labOrders}
                              getOrders={_getOrders}
                              patientOrders={patientOrders}
                              setShowLabOrderModal={setShowLabOrderModal}
                              patientInfo={patientInfoState}
                              setPatientInfoState={setPatientInfoState}
                              patientId={patientId}
                              getPatientInformation={_getPatientInformation}
                              paymentStatus={paymentStatus}
                              setPaymentStatus={setPaymentStatus}
                              setPayCheckOrder={setPayCheckOrder}
                              paidStatus={paidStatus}
                              setPaidStatus={setPaidStatus}
                              setDropdownLabTreatmentId={setDropdownLabTreatmentId}
                              status={statusesState}
                              setOrderRetainerClick={setOrderRetainerClick}
                              getCurrentTreatmentStatus={_getCurrentTreatmentStatus}
                              alignerList={aligners}
                              setOrderRefinementClick={setOrderRefinementClick}
                              isModalUpdateOrderVisible={isModalUpdateOrderVisible}
                              setIsModalUpdateOrderVisible={setIsModalUpdateOrderVisible}
                              setFromPatientJourneyButton={setFromPatientJourneyButton}
                              clinic={clinic}
                              treatmentProgressDet={treatmentProgressDet}
                              getData={_getData}
                              setSendOrderWithoutPatientApproval={
                                setSendOrderWithoutPatientApproval
                              }
                              setSharePatientMessage={setSharePatientMessage}
                              dropdownLabTreatmentId={dropdownLabTreatmentId}
                              setFromSendToPatientAppRegistration={
                                setFromSendToPatientAppRegistration
                              }
                              isModalApproveSendPlanVisible={isModalApproveSendPlanVisible}
                              setIsModalApproveSendPlanVisible={setIsModalApproveSendPlanVisible}
                              setFromShareWithPatient={setFromShareWithPatient}
                              fromShareWithPatient={fromShareWithPatient}
                              setOrderType={setOrderType}
                            />
                          </div>
                        </Spin>
                      </Col>
                    </Row>
                    <Row
                      ref={myRef}
                      style={{
                        // width: "calc(95% - 1rem) ",
                        marginLeft: "0.2rem",
                        marginBottom: "1rem",
                   
                      }}
                      gutter={[20, 20]}
                      
                    >
                      <Col lg={12} md={24} sm={24}>
                        <Spin spinning={isPatientQuestionnaireSpinning}>
                          <PatientQuestionnaireComponent
                            clinic={clinic}
                            patientInfoState={patientInfoState}
                            patientResponses={patientQuestionnaireResponses}
                            imageStageTypes={imageStageTypes}
                          />
                        </Spin>
                      </Col>
                      <Col lg={12} md={24} sm={24}>
                        <Spin spinning={isAlignerTimelineSpinning}>
                          <PatientTreatmentProgressComponent
                            _getAlignerDetails={_getAlignerDetails}
                            clinic={clinic}
                            aligners={aligners}
                            treatmentId={treatmentId}
                            patientInfoState={patientInfoState}
                            status={statusesState}
                            wholeTreatmentPlan={wholeTreatmentPlan}
                            dropdownLabTreatmentId={dropdownLabTreatmentId}
                          />
                        </Spin>
                      </Col>
                    </Row>
                  </div>
                )}
                {currentMenuSelected === "appointments" && (
                  <div style={{ marginLeft: "1rem" }}>
                    <Spin spinning={isAppointmentsSpinning}>
                      <PatientDetailTableComponent
                        data={patientAppointments}
                        mode={"appointments"}
                      ></PatientDetailTableComponent>
                    </Spin>
                  </div>
                )}
                {currentMenuSelected === "orders" && (
                  <div>
                    <Row
                      style={{
                        // width: "calc(95% - 1rem) ",
                        marginLeft: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <PatientOrdersMenubarComponent
                        setShowLabOrderModal={setShowLabOrderModal}
                        setCurrentSelection={setCurrentOrdersSelection}
                        setShowPatientOrderModal={setShowPatientOrderModal}
                        selectedCaseId={selectedCaseId}
                        activeCaseId={activeCaseId}
                        paymentStatus={paymentStatus}
                        setIsModalUpdateOrderVisible={setIsModalUpdateOrderVisible}
                        patientOrders={patientOrders}
                        currentOrdersSelection={currentOrdersSelection}
                        setOrderType={setOrderType}
                        wholeTreatmentPlan={wholeTreatmentPlan}
                      />
                    </Row>
                    <div style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                      {currentOrdersSelection === 0 && (
                        <Spin spinning={isOrdersSpinning}>
                          {" "}
                          <PatientDetailTableComponent
                            data={patientOrders}
                            mode={"patientOrders"}
                            getOrders={_getOrders}
                          ></PatientDetailTableComponent>
                        </Spin>
                      )}
                    </div>
                    <div style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                      {currentOrdersSelection === 1 && (
                        <Spin spinning={isOrdersSpinning}>
                          {" "}
                          <PatientDetailTableComponent
                            data={labOrders}
                            mode={"labOrders"}
                            modal={modal}
                            setPhaseOrderData={setPhaseOrderData}
                            setShowLabOrderModal={setShowLabOrderModal}
                          ></PatientDetailTableComponent>
                        </Spin>
                      )}
                    </div>
                  </div>
                )}

                {/* dsfDF */}

                {currentMenuSelected === "prescription" && (
                  <div>
                    {/* <Row
                      style={{
                        width: "calc(100% - 1rem) ",
                        marginLeft: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <PatientPrescriptionMenubarComponent
                        status={statusesState}
                        setCurrentSelection={setCurrenPrescriptionSelection}
                      ></PatientPrescriptionMenubarComponent>
                    </Row> */}
                    <Spin style={{ width: "100%" }} spinning={isScanUrlsSpinning}>
                      {" "}
                      <Row
                        style={{
                          width: "calc(100% - 1rem) ",
                          marginLeft: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        {/* <ViewScanFilesComponent
                          clinicalFiles={clinicalFiles}
                          scanUrls={scanUrls}
                          patientId={patientId}
                          treatmentOriginId={treatmentOriginId}
                          activeCaseId={activeCaseId}
                          getImg={_getImagesAndQuestionnaire}
                          status={statusesState}
                          dropdownValues={dropdownValuesState}
                          setStatusesState={setStatusesState}
                          updateTreatmentOrigin={updateTreatmentOrigin}
                        ></ViewScanFilesComponent> */}
                      </Row>
                    </Spin>
                    <Spin spinning={isPrescriptionSpinning}>
                      {" "}
                      <Row
                        ref={prescriptionFormRef}
                        style={{
                          width: "calc(100% - 1rem) ",
                          marginLeft: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <PatientPrescriptionFormComponent
                          clinicalFiles={clinicalFiles}
                          activeCaseId={activeCaseId}
                          getImg={_getImagesAndQuestionnaire}
                          dropdownValues={dropdownValuesState}
                          setStatusesState={setStatusesState}
                          updateTreatmentOrigin={updateTreatmentOrigin}
                          patientInfo={patientInfoState}
                          formDraftRef={formDraftRef}
                          teethChartDraftRef={teethChartDraftRef}
                          saveToDraftService={saveToDraftService}
                          setIsPageLoading={setIsPageLoading}
                          mapPrescriptionData={mapPrescriptionData}
                          prescriptionForm={prescriptionForm}
                          status={statusesState}
                          setPrescriptionFormData={setPrescriptionFormData}
                          clinicId={clinic.clinicId}
                          PRESCRIPTION_FORM_STATUS={PRESCRIPTION_FORM_STATUS}
                          setEditMode={setEditMode}
                          editMode={editMode}
                          isLoading={setIsPrescriptionSpinning}
                          productList={products}
                          durationList={durations}
                          labList={labList}
                          formData={prescriptionFormData}
                          formDataObjectRef={formDataObjectRef}
                          submitForm={submitPrescriptionForm}
                          isEditableFromDB={isPrescriptionAllowedFromDB}
                          isPrescriptionLabEditAllowed={isPrescriptionLabEditAllowed}
                          canBeEdited={isPrescriptionEditAllowed}
                          setCanBeEdited={setIsPrescriptionEditAllowed}
                          // isEditButtonDisable={isEditButtonDisable}
                          setIsNotAddedClinic={isNotAddedClinic}
                          selectedCaseId={selectedCaseId}
                          scanUrls={scanUrls}
                          treatmentOrigin={treatmentOriginId}
                          isAllowedToSubmit={selectedCaseId === activeCaseId}
                          formDataObject={formDataObject}
                          patientId={patientId}
                          setScanUrls={setScanUrls}
                        ></PatientPrescriptionFormComponent>
                      </Row>
                    </Spin>
                  </div>
                )}
              </div>
              <ChatWindow 
              patientId={patientId}
              clinicId={clinic.clinicId}
              partneredLabs={partneredLabs}
              patientName={patientName}
              showCommentBox={clinic.openCommentBox}
              openPatientChatTab={clinic.openPatientChatTab}
              disableCommentBox={() => dispatch(changeCommentBoxState(false))}
              senderId={location?.state?.senderId}
              clinicInfo={clinic}
              />

            </div>
          }
        </div>
 
        <FloatingButtons
          patientId={patientId}
          clinicId={clinic.clinicId}
          partneredLabs={partneredLabs}
          patientName={patientName}
          showCommentBox={clinic.openCommentBox}
          openPatientChatTab={clinic.openPatientChatTab}
          disableCommentBox={() => dispatch(changeCommentBoxState(false))}
          senderId={location?.state?.senderId}
        />

        <AddPatientAddress
          content={patientInfoState}
          showModal={showAddPatientAddress}
          onConfirmation={() => {
            console.log("hello");
          }}
          setShow={setShowAddPatientAddress}
          _getPatientInformation={_getPatientInformation}
          confirmPatientAddressModal={confirmPatientAddressModal}
        />

        <Modal
          title="Update & Order"
          confirmLoading={updateOrderLoading}
          okText="Update & Order"
          cancelText="Cancel"
          visible={isModalUpdateOrderVisible}
          onOk={updateOrderOk}
          onCancel={handleOrderCancel}
          okButtonProps={{
            style: { width: "9rem", borderRadius: "30px" },
          }}
          cancelButtonProps={{ style: { width: "7rem", borderRadius: "30px" } }}
          zIndex={1001}
        >
          <p>
            What's the payment status for this patient?
            <Select
              size="small"
              defaultValue="-"
              value={paymentStatus}
              className="patient-payment-status-select modal-select-payment"
              onChange={handlePaymentStatusChange}
              options={[
                { value: "Paid", label: "Paid" },
                { value: "Partially Paid", label: "Partially Paid" },
                { value: "Free", label: "Free" },
              ]}
            />
          </p>
        </Modal>

        {showScanModel && (
          <DoctorAppModel
            isModalVisible={showScanModel}
            handleOk={handleScanOk}
            handleCancel={onScanCancel}
            doNotShow
          />
        )}
      </div>
    </Spin>
  );
}

const mapStateToProps = (state) => ({
  clinic: state.clinic,
});

export default connect(mapStateToProps)(PatientDetail);
