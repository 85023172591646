import { useEffect, useState, useContext } from "react";
import { Modal, Button,message } from "antd";
import AppointmentHeader from "./Components/Header/Header";
import AppointmentContent from "./AppointmentContent";
import "../../../../assets/styles/modals/appointmentModal.scss";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { saveAppointmentPayload } from "./services";
import { newAppointmentCreated } from "../../../../services/redux/actions/clinicActions";
import { confirmAppointmentApi } from "api/patient";
import { getClinicAvailability } from "services/redux/selectors/clinicSelectors";
import OffAvailability from "components/shared/OffAvailability/OffAvailability";
import AppSettingsContext from "Context/useAppContext";
import { createConfirmConfig } from "components/shared/ConfirmationModal/config";


const Appointment = ({ setShowAppointment, showAppointment,setShowBookingIframe,patientInfoState,iprSelected,setIprSelected }) => {
  const clinicAvailabilityTimings = useSelector(getClinicAvailability);

  const history = useHistory();
  const dispatch = useDispatch();
  const { clinicId, multipleAddresses } = useSelector((state) => state.clinic);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [selectedSlot, setSelectedSlot] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [clinicianNotAvailable,setClinicianNotAvailable] = useState(false)

  const [clinicAppointmentTypes, setClinicAppointmentTypes] = useState([]);
  const [clinicAppointmentNames, setClinicAppointmentNames] = useState([]);
  const [clinicAppointmentServices, setClinicAppointmentServices] = useState([]);
  const [modal, contextHolder] = Modal.useModal();


  const { clinicConfig } = useContext(AppSettingsContext);

  const primaryAddress = multipleAddresses.find(cl => Boolean(cl.default) === true);

  const [dropDown, setDropDown] = useState({
    clinicianId: 0,
    appointmentNameId: 0,
    appointmentTypeId: 0,
    clinicId: primaryAddress ? primaryAddress.id : ''
  });
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  useEffect(()=>{
    if(patientInfoState){
      setSelectedPatient(patientInfoState);
      setIprSelected(true);
    }
  },[patientInfoState])


  const reRoute = () => {
    if(iprSelected){
      setSelectedPatient({});
      setIprSelected(false);
    }
    else{
      history.push("/clinic/scheduling/approved");
    }
    
  };

  const handleCancel = () =>{
    setShowAppointment(false);
    setSelectedPatient({});
    if (setIprSelected) {
      setIprSelected(false);
    }
  }

  const handleConfirmCancel = () => {
    const cancelConfig = createConfirmConfig({
      title: "Close Appointment form",
      content: "Any unsaved changes will be discarded. Do you want to proceed?",
      onOk: () => handleCancel(),
      onCancel: () => {},
      okText: "Confirm",
    });
    modal.confirm(cancelConfig);
  };

  const onCancel = () => {
    if ((selectedPatient && Object.keys(selectedPatient).length > 0) || selectedSlot) {
      handleConfirmCancel()
    }else{
      handleCancel()
    }
  };

  const onNewPatientClick = () => {
    if (clinicAvailabilityTimings?.profile?.clinic_availability) {
      setShowBookingIframe(true); 
    }
    else{
      message.error("Your clinic availability is Off.")
    }
    
    handleCancel()
  };

  const saveAppointment = async () => {
    
    if(!selectedPatient?.patient_id){
      message.error("Patient is not selected")
      return;
    }
    if(clinicianNotAvailable && !selectedSlot){
      message.error("Clinician not available. Please select next available slot")
      return;
    }
    if(!selectedSlot){
      message.error("Time slot is not selected")
      return;
    }
    setIsLoading(true);

    try {
      const apiData = saveAppointmentPayload({
        timeSlots,
        dropDown,
        selectedDate,
        clinicAppointmentServices,
        selectedPatient,
        selectedSlot,
        clinicId,
        iprSelected
      });

      apiData.patientId=selectedPatient.patient_id;
     
      await confirmAppointmentApi(apiData);
      dispatch(newAppointmentCreated(true));
      setIsLoading(false);
      handleCancel()
      reRoute();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const footer = () => {
    return (
      <div>
        <div>
          <Button
            onClick={onCancel}
            style={{ width: "120px", marginRight:"10px" }}
            shape="round"
          >
            Cancel
          </Button>
          <Button
            loading={isLoading}
            style={{ width: "120px", }}
            shape="round"
            type="primary"
            onClick={saveAppointment}
            disabled={!clinicConfig?.appointment}
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      width={850}
      title={
        <AppointmentHeader
          setSelectedPatient={setSelectedPatient}
          clinicId={clinicId}
          onNewPatientClick={onNewPatientClick}
        />
      }
      onCancel={onCancel}
      visible={showAppointment}
      footer={footer()}
    >
       {contextHolder}
    {clinicConfig?.appointment ?
      <AppointmentContent
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        selectedPatient={selectedPatient}
        timeSlots={timeSlots}
        setTimeSlots={setTimeSlots}
        setDropDown={setDropDown}
        dropDown={dropDown}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        clinicAppointmentTypes={clinicAppointmentTypes}
        setClinicAppointmentTypes={setClinicAppointmentTypes}
        clinicAppointmentNames={clinicAppointmentNames}
        setClinicAppointmentNames={setClinicAppointmentNames}
        setClinicAppointmentServices={setClinicAppointmentServices}
        iprSelected={iprSelected}
        setClinicianNotAvailable={setClinicianNotAvailable}
        closeModal={onCancel}
      /> : 
        <OffAvailability showCallback callback={()=>{
          onCancel();
        }} info="Your clinic availability is Off. "/>
    }
      
    </Modal>
  );
};

export default Appointment;
